import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNewPromoContext } from './NewPromoContextProvider';
import { Body, Footer, Header, PromoLayout } from './PromoLayout';
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, Radio, RadioGroup } from '@mui/material';
import { ApiPaths } from '../../../../core/PathConstants';
import { requests } from '../../../../core/ApiService';
import ConfirmationPopup from '../../../../core/ConfirmPopup';

// TODO: Uncomment Code and add logic for Manually Set Aging Buckets
const AgingBucketModal = ({ country, open, onClose }) => {
    const [agingBuckets, setAgingBuckets] = useState([]);
    const [bucketSelectionType, setBucketSelectionType] = useState('standard');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { setLoading, showAlert, promotion, setPromotion, staticData } = useNewPromoContext();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data } = await requests.get(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/aging-buckets`);

                const initializedAgingBuckets = staticData.agingBuckets?.map(bucket => ({
                    ...bucket,
                    ...data?.find(d => d.standardAgingBucketId === bucket.standardAgingBucketId),
                    isChecked: data?.some(ab => ab.standardAgingBucketId === bucket.standardAgingBucketId) ?? false
                }));

                setAgingBuckets(initializedAgingBuckets);
            } catch (err) {
                showAlert(`Process failed while retrieving aging buckets for promotion ${promotion.promotionKey}!`, 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [country, promotion.promotionId, promotion.promotionKey, setLoading, showAlert, staticData.agingBuckets]);

    const handleSave = async (saveForLater) => {
        setLoading(true);

        try {

            const selectedAgingBuckets = agingBuckets.filter(ab => ab.isChecked);
            await requests.post(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/aging-buckets`, selectedAgingBuckets);

            // Update the promotion object based on the changed exclusions
            const updatedPromotion = {
                ...promotion,
                agingBuckets: selectedAgingBuckets.map(ab => ({ minDays: ab.minDays, maxDays: ab.maxDays })),
            };

            setPromotion(updatedPromotion);

            showAlert('Aging Buckets saved successfully!', 'success');
            saveForLater ? onClose('cancel') : onClose();
        } catch ({ data }) {
            showAlert(data?.description || 'Process failed while creating Promotion!', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleCbChange = useCallback((event, bucket) => {
        const { checked } = event.target;

        setAgingBuckets(prevBuckets => {
            const index = prevBuckets.findIndex(ab => ab.standardAgingBucketId === bucket.standardAgingBucketId);
            return index > -1
                ? [...prevBuckets.slice(0, index), { ...prevBuckets[index], isChecked: checked }, ...prevBuckets.slice(index + 1)]
                : [...prevBuckets, { ...bucket, isChecked: checked, promotionId: promotion.promotionId }];
        });
    }, [promotion.promotionId]);

    const handleCancel = () => setConfirmOpen(true);

    const handleConfirm = (isCancel) => {
        setConfirmOpen(false);
        if (isCancel) {
            onClose('cancel');
        }
    };

    const handleBucketTypeChange = (event) => {
        setBucketSelectionType(event.target.value);
    };

    // const addBucket = () => {
    //     setAgingBuckets(prevBuckets => [
    //         ...prevBuckets,
    //         { minDays: 0, maxDays: 0, promotionId: promotion.promotionId }
    //     ]);
    // };

    // const deleteBucket = (bucketIndex) => {
    //     const updatedBuckets = [...agingBuckets];
    //     updatedBuckets.splice(bucketIndex, 1);
    //     setAgingBuckets(updatedBuckets);
    // };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <PromoLayout>
                <Header>
                    <DialogTitle style={{ textAlign: 'center' }}>Choose Aging Buckets for <b>{promotion.promotionKey}</b></DialogTitle>
                </Header>
                <Body>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className="centered-grid">
                                <RadioGroup row value={bucketSelectionType} onChange={handleBucketTypeChange}>
                                    <FormControlLabel value="standard" control={<Radio />} label={country.startsWith('ca') ? "Standard CAN Aging Buckets" : "Standard US Aging Buckets"} />
                                    <FormControlLabel value="custom" control={<Radio />} label="Manually Set Aging Buckets (Pending Feature)" disabled />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    {bucketSelectionType === 'standard' && (
                        <DialogContent>
                            <Grid container spacing={2}>
                                {[0, 1].map((index) => (
                                    <Grid item xs={6} className="centered-grid" key={index}>
                                        <FormGroup className="left-aligned-group">
                                            {agingBuckets
                                                .slice(index * Math.ceil(agingBuckets.length / 2), (index + 1) * Math.ceil(agingBuckets.length / 2))
                                                .map(ab => (
                                                    <FormControlLabel
                                                        key={ab.standardAgingBucketId}
                                                        control={
                                                            <Checkbox
                                                                checked={ab.isChecked}
                                                                onChange={(event) => handleCbChange(event, ab)}
                                                                name={`ab-${ab.standardAgingBucketId}`}
                                                            />
                                                        }
                                                        label={`${ab.minDays} - ${ab.maxDays} days`}
                                                    />
                                                ))}
                                        </FormGroup>
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                    )}
                    {/* {!loading && bucketSelectionType === 'custom' && (
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={addBucket}>
                                        Add a Bucket
                                    </Button>
                                </Grid>
                                {agingBuckets.select(ab => !ab.standardAgingBucketId).map((bucket, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index + 1}>
                                        <div className="custom-bucket">
                                            <span>Bucket {index + 1}</span>
                                            <Button onClick={() => deleteBucket(index)}>X</Button>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                    )} */}
                    <ConfirmationPopup
                        open={confirmOpen}
                        onClose={() => handleConfirm(false)}
                        onConfirm={() => handleConfirm(true)}
                        message="Are you sure you want to cancel all modifications? Data will not be saved!"
                    />
                </Body>
                <Footer>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={onClose}>Back</Button>
                            <Button variant='contained' color='inherit' onClick={handleCancel}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={() => handleSave(true)}>Save for later</Button>
                            <Button variant='contained' color='inherit' onClick={() => handleSave()}>Save and Continue</Button>
                        </Grid>
                    </Grid>
                </Footer>
            </PromoLayout >
        </Dialog >
    );
}

export default AgingBucketModal;
