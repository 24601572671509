import React, { useEffect, useState } from 'react'
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants'
import { fetchApiData } from '../../../../core/FetchData'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress'
import { format } from 'fecha';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { red, grey } from '@mui/material/colors'
import { StyledTable } from './components/StyledDefaults'

const SingleVehicleSpecs = ({country, vehicleId}) => {
    
    const [loading, setLoading] = useState(false);
    const [error, setError]     = useState('');
    const [data, setData]       = useState(null);

    const onDataReady   = (dat) => setData(dat)
    const onError       = (err) => setError(err)

    useEffect(() => { 
        setLoading(true);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}`, onDataReady, setLoading, onError)
    }, [vehicleId, country]);

    const toPairsArray = (arr) => {
        const cnt = (arr || []).length;
        let byPairs = [];
        for (let i = 0; i < cnt; i += 2) 
            byPairs.push([arr[i], arr[i+1]])
        return byPairs
    }

    const createCell = (elm, nextEmpty = false) => (elm) && (<>
        <TableCell><b>{elm.specName}</b></TableCell>                
        <TableCell colSpan={nextEmpty ? 3 : 1} color={ elm.specID !== 201 ? red[900] : grey[900]}>
            { elm.specVehicleValue }
        </TableCell></>)    

    return (
        <>
            {   loading ? <CircularProgress color="inherit" size={40} /> :
                error   ? <ErrorOutlineIcon color="red" /> : 
                data && (
                    <Stack sx={{ width: '100%' }} spacing={1}>
                        {data.messages.map((x,i) => <Alert key={i} severity="warning">{x}</Alert>)}

                        <TableContainer>
                            <StyledTable size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell key="acceptedAt"><b>Accepted at</b></TableCell>
                                        <TableCell key="acceptedAtVal">{data?.locationName ?? ''}</TableCell>
                                        <TableCell key="acceptedBy"><b>Accepted by</b></TableCell>
                                        <TableCell key="acceptedByVal">
                                            {data?.userName ?? ''}
                                            {data.acceptedDate && format(new Date(data.acceptedDate), ' [on] M/D/YYYY [at] HH:mm A')}
                                        </TableCell>
                                    </TableRow>
                                    {toPairsArray(data.vehicleSpecs || []).map((x,i) =>  (
                                        <TableRow key={x[0].specID}>
                                            {createCell(x[0], x[1] === undefined )}
                                            {createCell(x[1])}
                                        </TableRow>)
                                    )}
                                    <TableRow>
                                        <TableCell key="rideGoNoGo"><b>Ride Go | No Go | Maybe</b></TableCell>
                                        <TableCell key="rideGoNoGoVal">{data?.rideGoNoGo ?? ''}</TableCell>
                                        <TableCell key="engineLife"><b>Engine Repair Costs</b></TableCell>
                                        <TableCell key="engineLifeVal">{data?.engineCosts ?? ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key="condition"><b>Condition</b></TableCell>
                                        <TableCell key="conditionVal" colSpan={3}>{data?.vehicleCondition ?? ''}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </StyledTable>
                        </TableContainer>

                        {/* <pre>{JSON.stringify(data, null, 2)}</pre>  */}
                    </Stack>
                )
            }
        </>
    )
}

const MemSingleVehicleSpecs = React.memo(SingleVehicleSpecs)
export default MemSingleVehicleSpecs;