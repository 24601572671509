import React, { useEffect, useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography, Paper, Select, MenuItem, Button, LinearProgress, Grid, TextField, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, TableFooter } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../core/FetchData';
import AlertMessage from '../../../core/AlertMessage';
import { TableGrid } from '../../../core/TableGrid';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import MemSingleVehicleSearch from '../main/specs/SingleVehicleSearch';

export const PricingHistory = function ({country}) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [pricing, setPricing] = useState(null);
    const [isPLoading, setIsPLoading] = useState(false);
    const [history, setHistory] = useState(null);
    const [isHLoading, setIsHLoading] = useState(false);
  
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');

    const [vehicleId, setVehicleId] = useState(null);

       

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const onError = (errMsg) => {
        showAlert(errMsg, 'error');
    };

 

    const onData = (d) => {

        if (d) {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceHistory}/${vehicleId}`, setPricing, setIsPLoading, onError);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceHistory}/${vehicleId}/history`, setHistory, setIsHLoading, onError);

        }
        setData(d);
    }

    //fetch data
    const fetchData = () => {

        if (vehicleId) {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.AcceptedVehicle}/${vehicleId}`, onData, setIsLoading, onError);
           
        }
        else
            onError('Please enter a vehicle #');

    }


    const handleChange = (e) => {
        const value = e.target.value;
        setVehicleId(value);
    }

    function getHistoryTables() {
        if (!history) return null;
        let historyTables = [];
        let htable = [];
        let dt = null;
        for (let i = 0; i < history.length; i++) {

            if (dt !== history[i].pricedate)
            {
                if (htable.length > 0)
                    historyTables.push(htable);

                htable = [];
            }

            dt = history[i].pricedate;
            htable.push(history[i]);
        }

        if (htable.length > 0)
            historyTables.push(htable);

       
        return historyTables;
    }

    let totalAsk = 0;
    let totalTake = 0;

    function setBasePriceTake(amount, take) {
        totalAsk = amount;
        totalTake = take;
        return take;
    }

    function setOtherTake(amount, take) {
        totalAsk = totalAsk + amount;
        if (take)
            totalTake = totalTake + take;
        else
            totalTake = totalTake + amount;

        return '';
    }

    function getFormattedDate(d) {

        if (!d) return '';
        let date = new Date(d);

        //let year = date.getFullYear();

        //let month = (1 + date.getMonth()).toString();
        //month = month.length > 1 ? month : '0' + month;

        //let day = date.getDate().toString();
        //day = day.length > 1 ? day : '0' + day;

        //let hour = date.getHours().toString();

        //let min = date.getMinutes().toString();
        //min = min.length > 1 ? min : '0' + min;

        //let sec = date.getSeconds().toString();
        //sec = sec.length > 1 ? sec : '0' + sec;

        //let dd = month + '/' + day + '/' + year + ' ' +  hour + ':' + min + ':' + sec;

        return date.toLocaleString(); 
    }

    const onVehicleSelection = useCallback((prm) => {
        if (!isNaN(prm)) setVehicleId(+prm);

    }, []);

    return (
        <div>
            <Typography variant="h5" sx={{ m: 1 }}>View Vehicle Specs/Pricing</Typography>
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={4}>
                    <Grid item xs={3} align="right">
                        <Typography variant="h6">Vehicle #:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <MemSingleVehicleSearch country={country} onChange={onVehicleSelection} label="" />

                        {/*<TextField*/}
                        {/*    hiddenLabel*/}
                        {/*    variant="outlined"*/}
                        {/*    size="small"*/}
                        {/*    name="vehicleId"*/}
                        {/*    fullWidth*/}
                        {/*    value={vehicleId || ''}*/}
                        {/*    onChange={handleChange}*/}
                        {/*/>*/}
                    </Grid>
                    <Grid item>
                        <Button onClick={fetchData} color="primary" variant="contained" endIcon={<HistoryIcon />}>Price History</Button>
                    </Grid>
                </Grid>
            </Paper>

            {data &&                                 
                <TableContainer component={Paper} style={{ marginTop: 10 }} square>
                    <Table size="small" >
                        <TableBody>
                            <TableRow>
                                <TableCell key="AcceptedAt">
                                    <b>Accepted at</b>
                                </TableCell>
                                <TableCell key="AcceptedAtVal">
                                    {data?.loc_name}
                                </TableCell>
                                <TableCell key="AcceptedBy">
                                    <b>Accepted by</b>
                                </TableCell>
                                <TableCell key="AcceptedByVal">
                                    {data?.first + ' ' + data?.last + ' on ' + getFormattedDate(data?.acceptedDate)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>  
            }
            
            {pricing &&                
                <TableContainer component={Paper} style={{ marginTop: 10 }} square>
                    <Table size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} key="PricingOn">
                                <b>Pricing on: {getFormattedDate(pricing?.[0]?.pricedate)} by: {pricing?.[0]?.first} {pricing?.[0]?.last}</b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Pricing Type
                                </TableCell>
                                <TableCell>
                                    Ask
                                </TableCell>
                                <TableCell>
                                   Take
                                </TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {pricing.map((pt, index) => { 
                            return (<TableRow>
                                <TableCell key={'typedesc-' + index} >
                                    {pt?.typeDesc}<br />{pt?.name}
                                </TableCell>
                                <TableCell key={'ask-' + index}>
                                    {pt?.amount}
                                </TableCell>
                                <TableCell key={'take-' + index}>
                                    {pt?.priceType === 1 ? setBasePriceTake(pt?.amount, pt?.take) : setOtherTake(pt?.amount, pt?.take)}
                                </TableCell>
                            </TableRow>);                          
                            })
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                Totals:
                                </TableCell>
                            <TableCell>
                                {totalAsk}
                                </TableCell>
                            <TableCell>
                                {totalTake}
                                </TableCell>
                        </TableRow>
                    </TableFooter>
                    </Table>
                </TableContainer>           
            }

            {history &&
                <Fragment>
                {getHistoryTables()?.map((historyTable, index) => {
                    return (
                        <TableContainer key={'table-' + index} component={Paper} style={{ marginTop: 10 }} square>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>
                                            <b>Pricing on: {getFormattedDate(historyTable?.[0]?.pricedate)} by: {historyTable?.[0]?.first} {historyTable?.[0]?.last}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Pricing Type
                                </TableCell>
                                    <TableCell>
                                        Ask
                                </TableCell>
                                    <TableCell>
                                        Take
                                </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    {historyTable?.map((pt, index) => {
                                    return (<TableRow>
                                        <TableCell key={'typedesc-' + index} >
                                            {pt?.typeDesc}<br />{pt?.name}
                                        </TableCell>
                                        <TableCell key={'ask-' + index}>
                                            {pt?.amount}
                                        </TableCell>
                                        <TableCell key={'take-' + index}>
                                            {pt?.priceType === 1 ? setBasePriceTake(pt?.amount, pt?.take) : setOtherTake(pt?.amount, pt?.take)}
                                        </TableCell>
                                    </TableRow>);
                                })
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>
                                        Totals:
                                </TableCell>
                                    <TableCell>
                                        {totalAsk}
                                    </TableCell>
                                    <TableCell>
                                        {totalTake}
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                        </TableContainer>
                        )
                   } )}
            </Fragment>
            }                        
            
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div>
    );

}
