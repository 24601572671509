import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SettingsIcon from '@mui/icons-material/Settings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CategoryIcon from '@mui/icons-material/Category';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HistoryIcon from '@mui/icons-material/History';
import UndoIcon from '@mui/icons-material/Undo';
import ClearIcon from '@mui/icons-material/Clear';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ApprovalIcon from '@mui/icons-material/Approval';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { ApplicationPaths } from './core/PathConstants';
import { PriceChange } from '@mui/icons-material';


//Menu Config
export const adminGroups = ['Administrators', 'GG_UVSPricing_Admin_Test', 'GG_UVSPricing_Admin'];
export const userGroups = ['Everyone', 'GG_UVSPricing_User_Test', 'GG_UVSPricing_User'];
export const dosRoles = ['Director Used Vehicle Sales', '7930-Dir Used Vehicles Sales I', '8001-Dir Used Vehicle Sales', 'Director Used Vehicle Sales- Customer Care',];
export const vpRoles = ['VP Global Used Vehicles Sales', '9548-VP Global Used Vehicle Sales', 'Group Director of Sales'];



export const ApprovalMenuItems = [
    { icon: <DoneIcon />, text: 'Authorize Price Changes', link: `${ApplicationPaths.PendingApprovals}`, groups: adminGroups, roles: [...dosRoles, ...vpRoles] },
    { icon: <DoneAllIcon />, text: 'Price Changes - Final Authorization', link: `${ApplicationPaths.PendingFinalApprovals}`, groups: adminGroups, roles: vpRoles },   
];

export const ReportsMenuItems = [
    { icon: <AssessmentIcon />, text: 'Auto and Manually Priced Vehicles', link: `${ApplicationPaths.ReportsAutoManual}` },
    { icon: <AssessmentIcon />, text: 'Unadvertised Vehicles', link: `${ApplicationPaths.ReportsUnadvertised}` },
];

export const MainMenuItems = [
    { icon: <PageviewOutlinedIcon />, text: 'View Vehicle Specs/Pricing', link: `${ApplicationPaths.SpecsPricing}` },
    { icon: <FactCheckOutlinedIcon />, text: 'Accept Vehicle at UTC', link: `${ApplicationPaths.AcceptVehicle}`, groups: adminGroups },
    { icon: <FactCheckOutlinedIcon />, text: 'Vehicle Temporary Pricing', link: `${ApplicationPaths.AcceptVehicle}`, groups: userGroups },
    { icon: <ContentCopyIcon />, text: 'Dup Vehicle Specs', link: `${ApplicationPaths.DupVehicle}`, groups: adminGroups },
    { icon: <EditIcon />, text: 'Edit Vehicle Specs', link: `${ApplicationPaths.SpecsEdit}`, groups: adminGroups },
    //{ icon: <ListAltIcon />, text: 'Check-in Sheet', link: '#' },
    //{ icon: <InsertDriveFileIcon />, text: 'Generic Check-in Sheet', link: '#' },
    { icon: <AutoAwesomeMotionIcon />, text: 'Bulk Price', link: `${ApplicationPaths.BulkPricing}`, groups: adminGroups },
    { icon: <ApprovalIcon />, text: 'Approvals', link: `${ApplicationPaths.ApprovalHome}`, groups: adminGroups, roles: [...dosRoles, ...vpRoles] },
    { icon: <AssessmentIcon />, text: 'Reports', link: `${ApplicationPaths.ReportsHome}`, groups: adminGroups },
    { icon: null, text: 'Divider1', link: '#' },
    { icon: <AdminPanelSettingsOutlinedIcon />, text: 'Administration', link: '/admin', groups: adminGroups},

];

export const AdminMenuItems = [
    { icon: <MonetizationOnIcon />, text: 'Pricing Tables', link: `${ApplicationPaths.PricingHome}`, groups: adminGroups},
    { icon: <SettingsIcon />, text: 'Spec Tables', link: `${ApplicationPaths.Specs}`, groups: adminGroups },
    { icon: <NewReleasesIcon />, text: 'New Pricing Tables', link: `${ApplicationPaths.PricingHomeTemp}`, groups: adminGroups },
    { icon: <AutorenewIcon />, text: 'Auto-Pricing Exceptions', link: `${ApplicationPaths.AutoPricingExceptions}`, groups: adminGroups },
    { icon: <LocalOfferIcon />, text: 'Condition Adjustments', link: `${ApplicationPaths.Discounts}`, groups: adminGroups },
    { icon: <PriceChange />, text: 'Promo Tool', link: `${ApplicationPaths.Promo}`, groups: adminGroups },
    { icon: <PriceChange />, text: 'New Promo Tool', link: `${ApplicationPaths.NewPromo}`, groups: adminGroups },
    { icon: null, text: 'Maintenance', link: '#' },
    { icon: <CategoryIcon />, text: 'Maint Categories', link: `${ApplicationPaths.Categories}`, groups: adminGroups},
    { icon: <LocationOnIcon />, text: 'Maint Locations', link: `${ApplicationPaths.Locations}`, groups: adminGroups},
    { icon: null, text: 'Other', link: '#' },
    { icon: <UndoIcon />, text: 'Clear Vehicle', link: `${ApplicationPaths.ClearVehicle}`, groups: adminGroups },
    { icon: <ClearIcon />, text: 'Clear Authorization', link: `${ApplicationPaths.ClearAuthorization}`, groups: adminGroups },
    { icon: <HistoryIcon />, text: 'Pricing History', link: `${ApplicationPaths.PricingHistory}`, groups: adminGroups },
    { icon: null, text: 'Jobs', link: '#' },
    //{ icon: <PriceChangeOutlinedIcon />, text: 'Re-Price Presale vehicles', link: '#', groups: adminGroups },
    { icon: <CalendarMonthIcon />, text: 'Quarterly Pricing', link: `${ApplicationPaths.QuarterlyPricing}`, groups: adminGroups },

    { icon: null, text: '', link: '#' },
    { icon: <DashboardIcon />, text: 'Main Menu', link: '/' },

];

export const containsRole = (itemroles, role) => {

    if (!Array.isArray(itemroles))
        return false;

    //console.log(itemroles, role);

    if (role && itemroles.includes(role))
      return true; 

    return false;
}


export const containsGroup = (itemgroups, groups) => {

    
    if (!Array.isArray(itemgroups))
        return true;

    
    if (Array.isArray(groups)) {
        for (let i = 0; i < itemgroups.length; i++) {
            if (groups.includes(itemgroups[i]))
                return true;
        }
    }

    return false;
}