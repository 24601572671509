import React from "react";
import { Paper } from '@mui/material';

const PromoLayout = ({ children, ...props }) => {
    return (
        <Paper elevation={1} style={{ padding: '16px' }} {...props}>
            {children}
        </Paper>
    );
};

const Header = ({ children, ...props }) => (
    <div style={{ marginBottom: '1.5rem' }} {...props}>
        {children}
    </div>
);

const Body = ({ children, ...props }) => (
    <div style={{ marginBottom: '1.5rem', minHeight: '400px' }} {...props}>
        {children}
    </div>
);

const Footer = ({ children, ...props }) => (
    <div {...props}>
        {children}
    </div>
);

export { PromoLayout, Header, Body, Footer };
