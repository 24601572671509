import React, { useState, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MemSingleVehicleSpecs from './SingleVehicleSpecs';
import MemSingleVehiclePricing from './SingleVehiclePricing';
import MemSingleVehicleSearch from './SingleVehicleSearch';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ApiPaths, ApiRoot, ApplicationPaths } from '../../../../core/PathConstants'
import { fetchApiPdf } from '../../../../core/FetchData'
import { useParams, useHistory  } from "react-router-dom"

export const SpecsPricing = ({country}) => {
    const [ini, setIni]             = useState(true);

    const {vehicleId: prmVehicleId = "0", tab = "specs"} = useParams()
    const history                   = useHistory()
    const [vehicleId, setVehicleId] = useState(-1)
    const [loading, setLoading]     = useState(false)

    const handleChange = (_, newValue) => 
        (["specs", "pricing"].includes(newValue)) && history.push(`/${country}${ApplicationPaths.SpecsPricing}/${prmVehicleId}/${newValue}`);
        
    const onVechicleSelection = useCallback( (prm) => 
        (!ini) &&  history.push((prm && !isNaN(prm)) 
                ? `/${country}${ApplicationPaths.SpecsPricing}/${prm}/specs`
                : `/${country}${ApplicationPaths.SpecsPricing}`)
    ,[ini,country])

    const createPdf = () => {
        setLoading(true);
        fetchApiPdf(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}/pdf`, setLoading, (e) => console.log(e));
    }

    useEffect(() => { 
        if (!isNaN(prmVehicleId) && +prmVehicleId > 0) {
            if (+prmVehicleId !=  vehicleId)
                setVehicleId(+prmVehicleId);            //-> else : nothing should happen
        } else 
            setVehicleId(-1)
    }, [prmVehicleId, tab, country]);   

    useEffect(() => { setIni(false) }, []);    

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <AppBar position="static" sx={{ paddingTop: '16px', paddingRight: "8px", paddingLeft: "8px", backgroundColor: "white"}}>
                <Grid container>
                    <Grid item xs={9} lg={4}>
                        <MemSingleVehicleSearch country={country} onChange={onVechicleSelection}/>
                    </Grid>
                    <Grid item xs={1} lg={2}></Grid>
                    <Grid item xs={2} lg={1}>
                    {(vehicleId > 0) &&  (
                        <Tooltip title="Export Vehicle Specs to PDF">
                            <IconButton aria-label="Export Vehicle Specs to PDF" onClick={createPdf} disabled={loading && !(vehicleId > 0)}>
                                <PictureAsPdfIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    </Grid>
                    <Grid item xs={12} lg={5} >
                        <Tabs value={tab} onChange={handleChange} centered variant="fullWidth">
                            <Tab label="Vehicle Specs" value="specs"  {...a11yProps('specs')} disabled={!(vehicleId > 0)}/>
                            <Tab label="Vehicle Pricing" value="pricing"  {...a11yProps('pricing')} disabled={!(vehicleId > 0)}/>
                        </Tabs>
                    </Grid>
                </Grid>
            </AppBar>
            {(vehicleId > 0) ?  (
                <>
                    <Box sx={{textTransform:'uppercase', color:'slategray', mt: 3, ml: 4}}>
                        <Typography variant="subtitle1" sx={{lineHeight: '1.2em'}}>vehicle number <strong>{vehicleId}</strong> Specs and Princing info</Typography>
                    </Box> 
                    <TabPanel value={tab} name="specs">
                        <MemSingleVehicleSpecs country={country} vehicleId={vehicleId}/>
                    </TabPanel>
                    <TabPanel value={tab} name="pricing">
                        <MemSingleVehiclePricing country={country} vehicleId={vehicleId}/>
                    </TabPanel>
                </>
            ):<TabPanel><p><i>Provide a valid unit number to continue...</i></p></TabPanel> }
        </Box>
    )
}

const a11yProps = (nm) => ({ id: `simple-tab-${nm}`, 'aria-controls': `simple-tabpanel-${nm}`})
const TabPanel  = ({ children, value, name, ...other }) => (
    <div role="tabpanel" hidden={value !== name} id={`simple-tabpanel-${name}`} aria-labelledby={`simple-tab-${name}`} {...other}>
        {<Box sx={{ p: 3 }}>{children}</Box>}
    </div>
);
