import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography, Paper, Button, LinearProgress, TextField, TablePagination } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../../core/FetchData';
import AlertMessage from '../../../../core/AlertMessage';
import { TableGrid } from '../../../../core/TableGrid';
import { FixedPricingForm } from './FixedPricingForm';
import { Fragment } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
   
    tableHeader: {
      //  fontWeight: 'bold !important', backgroundColor: theme.palette.secondary.light, color: theme.palette.secondary.contrastText
    },
}));


export const FixPricing = ({country, isTemp}) => {
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setConfirmOpen] = React.useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    const [pageIndex, setPageIndex] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [searchFilter, setSearchFilter] = useState('');
    const classes = useStyles();
    const [saved, setSaved] = useState(false);


    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const handleClose = () => {
        setOpen(false);
        if (saved) {
            setSaved(false);
            fetchData();
        }
    };

    const onError = (msg) => {
        showAlert('Error: ' + msg);
    };
 

    const onDelComplete = () => {
        showAlert('Fixed Price deleted', 'success');
        fetchData();
    };

    //DEL functions
    //on click yes to delete
    const handleYes = () => {
        setConfirmOpen(false);
        if (selectedId) {
            if(isTemp)
                postApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricingTemp}/${selectedId}`, 'DELETE', null, onDelComplete, onError);
            else
                postApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricing}/${selectedId}`, 'DELETE', null, onDelComplete, onError);
        

        }
    };


    const handleSave = () => {
        //setOpen(false);
        //refresh
        setSaved(true);

        showAlert('Fixed Price successfully saved', 'success')
        //fetchData();
    };


    const onDel = (id) => {
        setSelectedId(id);
        //
        setConfirmOpen(true);
    }


    const onEdit = (id) => {
        setSelectedId(id);
        setOpen(true);

    }
    const onAdd = () => {
        setSelectedId(0);
        setOpen(true);
    }

    const PAGE_SIZE = 1000;

    const onData = (d) => {
       // setTotalPages(d.totalPages);
       // setTotalCount(d.totalCount);
        setData(d);
       // setData(d.items);
    }

    //fetch data
    const fetchData = () => {
        setIsLoading(true);
        // fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricing}/pages/?page=${pageIndex+1}&size=${PAGE_SIZE}&filter=${searchFilter}`, onData, setIsLoading, onError);
        if (isTemp)
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricingTemp}`, onData, setIsLoading, onError);
        else
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricing}`, onData, setIsLoading, onError);
        

 }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [searchFilter]);

    useEffect(() => {
        fetchData();
    }, [pageIndex]);

    
    const handleChangePage = (event, newPage) => {
        console.log(newPage);
        if (newPage <= totalPages) {

            setPageIndex(newPage);
        }
    }

    const PagingFooter = () => {
        return (
            <TablePagination                
                component="div"
                rowsPerPageOptions={[]}
                count={totalCount}
                rowsPerPage={PAGE_SIZE}
                page={pageIndex} 
                onPageChange={handleChangePage}
                
            />
            
            );

    }


    //column definition 
    const columns = [
        {
            field: 'id',
            sortable: false,
            headerName: ' ',
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderHeader: (params) => (
                <div sx={{ height: '100%' }}>
                    <IconButton aria-label="Add" 
                    onClick={(event) => {
                        event.ignore = true;
                        onAdd();
                    }
                    } >
                        <AddCircleIcon  />
                </IconButton>
                   
                    </div>

            ),
            renderCell: (params) => (
                <IconButton aria-label="Edit"
                    onClick={(event) => {
                        event.ignore = true;
                        onEdit(params.value);
                    }
                    } >
                    <EditIcon />
                </IconButton>

            ),
        },
        {
            field: 'vehicleId', headerName: 'Vehicle', width: 200,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },
        {
            field: 'desc', headerName: 'Name', flex:1,
            disableColumnMenu: true,
            filterable: false,
            //hideSortIcons: true,
        },
        {
            field: 'price', headerName: 'Price', width: 120,
            align: 'right',
            type: 'number',
            filterable: false,
            disableColumnMenu: true, 
            //hideSortIcons: true,
        },      
        {
            field: 'del', sortable: false,
            headerName: 'Del', width: 60,           
            filterable: false,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton aria-label="Del"
                    onClick={(event) => {
                        event.ignore = true;
                        onDel(params.id);
                    }
                    } >
                    <DeleteIcon style={{ color: 'salmon' }} />
                </IconButton>

            ),
        },

    ];

    const onChangeFilter =(event)=> {
        let v = event.target.value;
        if (isNaN(v)) {
            if (searchFilter !== '') {
                setPageIndex(0);
                setSearchFilter('');                
            }
        }
        else {
            setPageIndex(0);
            setSearchFilter(v);
        }

    }


    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Maintain {isTemp ? 'NEW' : ''} Fixed Prices</Typography>

            <Paper>
                {/*<TextField label="Filter Vehicle" value={searchFilter} style={{ marginLeft: 5, marginBottom: 2, marginTop: 2 }} onChange={onChangeFilter} />*/}
                {(isLoading) ? <LinearProgress /> :
                    <Fragment>
                        
                        <div style={{ height: 640, width: '100%' }}>
                            <DataGrid 
                                initialState={{
                                    filter: {
                                        filterModel: {
                                            items: [{ columnField: 'vehicleId', operatorValue: 'startsWith', value: '' }],
                                        },
                                    },
                                }}

                                rows={data} columns={columns} components={{ Toolbar: GridToolbar }} />
                            {/*<TableGrid rows={data} columns={columns} keycol="id" serverPaging="true" footer={<PagingFooter />} />*/}
                        </div>
                    </Fragment>
                }
            </Paper>
            {/*Open dialog*/}
            <Dialog open={open} aria-labelledby="form-dialog-id" fullWidth maxWidth="md">
                <FixedPricingForm id={selectedId} onCancel={handleClose} onError={onError} onSave={handleSave} country={country} isTemp={isTemp} />
            </Dialog>
            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Fixed Price"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this Fixed Price?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleConfirmClose} color="primary" autoFocus>
                        No
                     </Button>
                </DialogActions>
            </Dialog>
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div>
    );

}
