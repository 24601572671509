import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';


export default function LoginMenu({ user }) {   
   
    const [anchorEl, setAnchorEl] = useState(null);
    const { oktaAuth, authState } = useOktaAuth();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        oktaAuth.signOut();

    }

     const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
        <Button color="inherit" aria-controls="account-menu" aria-haspopup="true" onClick={handleClick}>
            <Avatar> {((user?.user?.given_name || '').substring(0, 1) + (user?.user?.family_name || '').substring(0, 1)).toUpperCase()}</Avatar>
        </Button>
            {user &&
                <Menu
                    id="account-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={0}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            }
    </Fragment>);

};


