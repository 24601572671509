import React, { useEffect, useState, Fragment } from 'react';
import { DialogTitle, DialogContent, DialogActions, TextField, Button, LinearProgress, Grid, Dialog, Divider, IconButton } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { useForm, CheckboxControl, Form, SelectControl } from '../../../../core/useForm';
import DoneIcon from '@mui/icons-material/Done';
import { TableGrid } from '../../../../core/TableGrid';
import { AttachMatrixForm } from './AttachMatrixForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddSpecsControl } from './AddSpecsControl';

const initialValues = { id: 0, name: '', sam: '', beginyear: 0, endYear: 0, mileage: 0, tier2Mileage: 0, amtPerMileage: 0, tier2AmtPerMileage: 0, maxDeduct: 0 };

const fieldsValidation = {
    name: {
        error: "Name is required",
        validate: "",
        required: true,
    },
    sam: {
        error: "SAM is required",
        validate: "",
        required: true,

    },
    beginyear: {
        error: "Value has to be a number",
        validate: "integer",

    },
    endYear: {
        error: "Value has to be a number",
        validate: "integer",
    },
    mileage: {
        error: "Value has to be a number",
        validate: "number",
    },
    tier2Mileage: {
        error: "Value has to be a number",
        validate: "number",
    },
    amtPerMileage: {
        error: "Value has to be a number",
        validate: "number",
    },
    tier2AmtPerMileage: {
        error: "Value has to be a number",
        validate: "number",
    },
    maxDeduct: {
        error: "Value has to be a number",
        validate: "number",
    },

}

export const MileageMatrixForm = ({ id, onSave, onCancel, onError , country, isTemp }) => {

    const [formErrors, setFormErrors] = React.useState({});

    const [data, setData, handleChange, validateForm] = useForm(initialValues, fieldsValidation, setFormErrors);

    const [isLoading, setIsLoading] = useState(true);
    const [isPSLoading, setIsPSLoading] = useState(true);

    const [priceSpecs, setPriceSpecs] = useState(null);



    //save the data
    const saveData = () => {

        //add
        if (validateForm()) {

            let newData = { ...data };

            newData.beginyear = Number(data.beginyear);
            newData.endYear = Number(data.endYear);
            newData.mileage = Number(data.mileage);
            newData.tier2Mileage = Number(data.tier2Mileage);
            newData.amtPerMileage = Number(data.amtPerMileage);
            newData.tier2AmtPerMileage = Number(data.tier2AmtPerMileage);           
            newData.maxDeduct = Number(data.maxDeduct);
            if (isTemp)
                postApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrixTemp}`, 'POST', newData, onSave, onError);
            else
                postApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrix}`, 'POST', newData, onSave, onError);
            

        }

    }


    const onDataError = (msg) => {
        onError(msg);
    }


    const onDelComplete = () => {
        if (isTemp)
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrixTemp}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
        else
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrix}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
        

    }

    const onSaveComplete = () => {
        //after saving load the latest specs
        if(isTemp)
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrixTemp}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
        else
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrix}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
        
    }

    const onDel = (specId, criteria, specValue) => {
        if (isTemp)
            postApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrixTemp}/specs`, 'DELETE', { id: id, specId: Number(specId), criteria, specValue }, onDelComplete, onError);
        else
            postApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrix}/specs`, 'DELETE', { id: id, specId: Number(specId), criteria, specValue }, onDelComplete, onError);
        


    }

    const onSaveSpec = (specId, criteria, specValue) => {
        if (isTemp)
            postApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrixTemp}/specs`, 'POST', { id: id, specId: Number(specId), criteria, specValue }, onSaveComplete, onError);
        else
            postApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrix}/specs`, 'POST', { id: id, specId: Number(specId), criteria, specValue }, onSaveComplete, onError);
        

    }


    useEffect(() => {

        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
            setIsPSLoading(false);

        }
        else {

            if (isTemp) {
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrixTemp}/${id}`, setData, setIsLoading, onDataError);
                //specs
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrixTemp}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
            } else {
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrix}/${id}`, setData, setIsLoading, onDataError);
                //specs
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MileageMatrix}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
            

            }
        }

    }, [id]);



    return (
        <Fragment>
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} {isTemp ? 'NEW' : ''} Mileage Matrix</DialogTitle>
            <DialogContent>
                {isLoading || isPSLoading ? <LinearProgress /> :
                    <Form>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    label="Name"
                                    size="small"
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.name || ''}
                                    required
                                    error={!!formErrors.name}
                                    helperText={formErrors.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="SAM "
                                    size="small"
                                    name="sam"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    value={data?.sam || ''}

                                    error={!!formErrors.sam}
                                    helperText={formErrors.sam}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Begin Year"
                                    size="small"
                                    name="beginyear"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.beginyear || ''}
                                    error={!!formErrors.beginyear}
                                    helperText={formErrors.beginyear}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="End Year"
                                    size="small"
                                    name="endYear"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.endYear || ''}
                                    error={!!formErrors.endYear}
                                    helperText={formErrors.endYear}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Tier 1 Mileage"
                                    size="small"
                                    name="mileage"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.mileage || ''}
                                    error={!!formErrors.mileage}
                                    helperText={formErrors.mileage}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Tier 1 Amt/Mile"
                                    size="small"
                                    name="amtPerMileage"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.amtPerMileage || ''}
                                    error={!!formErrors.amtPerMileage}
                                    helperText={formErrors.amtPerMileage}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Tier 2 Mileage"
                                    size="small"
                                    name="tier2Mileage"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.tier2Mileage || ''}
                                    error={!!formErrors.tier2Mileage}
                                    helperText={formErrors.tier2Mileage}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label=" Tier 2 Amt/Mile"
                                    size="small"
                                    name="tier2AmtPerMileage"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.tier2AmtPerMileage || ''}
                                    error={!!formErrors.tier2AmtPerMileage}
                                    helperText={formErrors.tier2AmtPerMileage}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Max $"
                                    size="small"
                                    name="maxDeduct"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.maxDeduct || ''}
                                    error={!!formErrors.maxDeduct}
                                    helperText={formErrors.maxDeduct}
                                    onChange={handleChange}
                                />
                            </Grid>                            
                            <Grid item xs={12} style={{ marginBottom: 5 }}>
                                <Divider />
                            </Grid>
                            <AddSpecsControl id={id} data={priceSpecs} onError={onError} onSave={onSaveSpec} onDelete={onDel} country={country} />
                        </Grid>
                    </Form>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={saveData} color="primary">Save</Button>
                <Button onClick={onCancel} color="primary">Close</Button>
                
            </DialogActions>
        </Fragment>
    );

}
