import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from "react-router-dom"
import { UserContext } from '../../../../core/UserContext'
import { ApplicationPaths, ApiPaths, ApiRoot } from '../../../../core/PathConstants'
import { fetchApiData, postApiData } from '../../../../core/FetchData'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import SnackAlert from './components/SnackAlert'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors'
import { StyledTable } from './components/StyledDefaults'
import { useForm } from "react-hook-form";
import { FieldLocationLookup } from './components/FieldForm'
import { adminGroups, containsGroup, userGroups } from '../../../../MenuConfig'

const AcceptVehiclePricing = ({country}) => {
  const { vehicleId = "0" } = useParams()
  const history             = useHistory()
  const userContext         = useContext(UserContext)
  const userEmail           = (userContext?.user?.upn ?? userContext?.user?.preferred_username ?? '').split('@')[0]

  const [loading, setLoading]   = useState(false);
  const [error, setError]       = useState('');
  const [data, setData]         = useState(null);
  const [okStatus, setOkStatus] = useState("");

  useEffect(() => {
    if (+vehicleId > 0) {
      setLoading(true);
      fetchApiData(
        `${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}/pricing-acceptance-info/${userEmail}`,
        (dat) => setData(dat),
        setLoading,
        (err) => { setError(err || "Error while getting the data."); setData(null); } 
      );
    }
  }, [vehicleId, userEmail, country]);

  const { handleSubmit, setValue, control } = useForm();

  useEffect(() => {
    if (data)
      setValue('fld_locationId', data.userUTCLocCode === 0 ? "" : `${data.userUTCLocCode}`)
  }, [data])
  
  const onSubmit = formData => { 
    postApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs_Complete_Accept}`,
      'POST',
      { vehicleId, userEmail, locationCode: formData.fld_locationId },
      (resp) => {
        setOkStatus(`Vehicle Accepted at ${resp.acceptedAt}`);
        setTimeout(() => {
          history.push(`/${country}${ApplicationPaths.SpecsPricing}/${vehicleId}/pricing`) //=> e.g. https://.../us/specs-pricing/123123/pricing
        },3000);
      },
      (err) => setError(err ?? "Error While Updating the Specs"));
  }

  const labelStyle = {padding: "9px 0", display: "block", lineHeight: "1rem"}
  const validateFieldList = (_, field, val) => ((val ?? "").trim() === "") ?  `${field.specName} required.`: true;

  return (
    <>
      <Box sx={{ width: '100%', px: 3 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={8} sx={{ my: 2 }}>
          <Box sx={{ textTransform: 'uppercase', color: 'slategray' }}>
                      <Typography variant="subtitle1" sx={{ lineHeight: '1.2em' }}>vehicle number <strong>{vehicleId}</strong></Typography>
                      {(containsGroup(adminGroups, userContext?.groups)) ?

                          <Typography variant="h6" sx={{ lineHeight: '1em' }}>accept vehicle for sale</Typography>
                          :
                          <Typography variant="h6" sx={{ lineHeight: '1em' }}>Vehicle Temporary Pricing</Typography>

                      }
          </Box>
        </Stack>
        { loading ? <Alert severity="info" icon={<CircularProgress color="inherit" size={40} fontSize="inherit" />}><AlertTitle><strong>searching</strong></AlertTitle> ... vehicle number {vehicleId}</Alert> :
          error   ? <Alert severity="error" icon={<ErrorOutlineIcon sx={{ color: 'primary.main' }} />}> {error}</Alert> :
          data && data.vehicleId === +vehicleId && (
            <Paper sx={{ width: '100%', px: 4, py: 2 }} spacing={1} component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column">
                {(containsGroup(adminGroups, userContext?.groups)) &&
                  <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ pr: 1 }}>
                          Acceptance Location
                      </Box>
                      <Box sx={{ minWidth: '400px' }}>
                          {(data.groupIdRequestor >= 2)
                              ? <FieldLocationLookup control={control} validate={validateFieldList} country={country} />
                              : <b style={labelStyle}>{data.userUTCLocName || <i>...no location specified for the current user</i>}</b>
                          }
                      </Box>
                  </Box>
                }
                
                <TableContainer component={Paper}>
                  <StyledTable size="small">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: grey[100] }}>
                        <TableCell><b>Pricing Type</b></TableCell>
                        <TableCell align="right"><b>Ask</b></TableCell>
                        <TableCell align="right"><b>Take</b></TableCell>
                      </TableRow>
                    </TableHead>
                    {(data?.vehiclePricingDetailList?.length ?? 0) > 0 
                    ? (
                    <TableBody>
                      {data.vehiclePricingDetailList.map(x => (
                        <TableRow key={x.vehiclePricingId}>
                          <TableCell>
                            <b style={{ color: 'grey', display: 'block' }}>{x.typeDescription}</b>
                            {x.detailName}
                          </TableCell>
                          <TableCell align="right">{x.amount}</TableCell>
                          <TableCell align="right">{x.take}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow key="totals" sx={{ backgroundColor: grey[100] }}>
                        <TableCell><b>Totals</b></TableCell>
                        <TableCell align="right"><b>{data.totalAsk}</b></TableCell>
                        <TableCell align="right"><b>{data.totalTake}</b></TableCell>
                      </TableRow>
                    </TableBody>)
                    :(
                    <TableBody>
                      <TableRow key="totals" sx={{ backgroundColor: grey[100] }}>
                        <TableCell><b>Totals</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>)}
                  </StyledTable>
                </TableContainer>

                {(containsGroup(adminGroups, userContext?.groups)) &&
                  <Stack alignItems="center" justifyContent="center" padding={2}>
                    <Button variant="contained" type='submit' size='small'>accept vehicle</Button>
                  </Stack>
                }
              </Stack>
            </Paper>
          )
        }
      </Box>
      <SnackAlert errorMsg={error} onCloseError={() => { setError('') }} okMsg={okStatus} onCloseOk={() => setOkStatus('')} />
    </>
  )
}

export default AcceptVehiclePricing