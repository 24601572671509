import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions,  TextField, Button, LinearProgress, Grid, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { TableGrid } from '../../../../core/TableGrid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme =>({
    
    nameHeader: {
        margin: theme.spacing(1)

    }
}));



const initialValues = [
    { priceId: 0, name: '', year: null, askprice: null, takeprice: null },
    { priceId: 0, name: '', year: null, askprice: null, takeprice: null },
    { priceId: 0, name: '', year: null, askprice: null, takeprice: null },
    { priceId: 0, name: '', year: null, askprice: null, takeprice: null },
    { priceId: 0, name: '', year: null, askprice: null, takeprice: null },
    { priceId: 0, name: '', year: null, askprice: null, takeprice: null },
    { priceId: 0, name: '', year: null, askprice: null, takeprice: null },
    { priceId: 0, name: '', year: null, askprice: null, takeprice: null }
];


export const PriceMatrixForm = ({ id, onSave, onCancel, onError , country, isTemp }) => {
    const classes = useStyles();
    const [nameValue, setNameValue] = useState('');
    const [formError, setFormError] = useState('');

    const [baseData, setBaseData] = useState([]);
    const [isBaseLoading, setIsBaseLoading] = useState(true);

    const [data, setData] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(true);
    const [addTakePrice, setAddTakePrice] = useState(0);
    const [addAskPrice, setAddAskPrice] = useState(0);

   
    const columns = [

        {
            field: 'year',
            headerName: 'Year', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <TextField aria-label="Year"
                    size="small"
                    variant="outlined"
                    name={params.field}
                    value={params.value || ''}
                    onChange={(e) => handleChange(e, params)}
                    inputProps={{ maxLength: 4 }}
                />

            ),
        },
        {
            field: 'askprice',
            headerName: 'Ask Price', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,          
            renderCell: (params) => (
                <TextField aria-label="AskPrice"
                    size="small"
                    variant="outlined"
                    name={params.field}
                    value={params.value || ''}
                    onChange={(e) => handleChange(e, params)}
                />

            ),
        },
        {
            field: 'takeprice',
            headerName: 'Take Price', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <TextField aria-label="TakePrice"
                    size="small"
                    variant="outlined"
                    name={params.field}
                    value={params.value || ''}
                    onChange={(e) => handleChange(e, params)}
                />

            ),
        },
    ];

   


    const baseCols = [

        {
            field: 'sam',
            headerName: 'SAM', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,           
        },
        {
            field: 'make',
            headerName: 'Make', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'model',
            headerName: 'Model', 
            disableColumnMenu: true,
            hideSortIcons: true,
        },
    ]

    //save the data
    const saveData = () => {      

        if (nameValue !== '') {
            let newData = [];

            for (let i = 0; i < data.length; i++) {
                //put name in all rows 
                //put id in all fields
                //remove the ones with no year
                if (data[i].year)
                    newData.push({ ...data[i], name: nameValue, priceId: id });               
               
            } 

            if(isTemp)
                postApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrixTemp}`, 'POST', newData, onSave, onError);
            else
                postApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrix}`, 'POST', newData, onSave, onError);
           
        }       
       
      
    }

     
    const onDataError = (msg) => {

    }

    const onData = (d) => {
        // limit to 8 rows
        if (Array.isArray(d)) {

            setNameValue(d[0]?.name);

            let l = 8 - d.length;
            if (l > 0) {
                for (let i = 0; i < l; i++) {
                    d.push({ priceId: 0, name: '', year: null, askprice: null, takeprice: null });
                }
            }
            else if (l < 0) {
               d = d.slice(0, 7);
            }
        }
        setData(d);
    }

     
    useEffect(() => {
         
        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
           
        }
        else {
            if (isTemp) {
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrixTemp}/${id}`, onData, setIsLoading, onDataError);
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrixTemp}/${id}/base`, setBaseData, setIsBaseLoading, onDataError);
            }
            else {
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrix}/${id}`, onData, setIsLoading, onDataError);
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrix}/${id}/base`, setBaseData, setIsBaseLoading, onDataError);           

            }

        }

    }, []);

    const handleNameChange = (e) => {
        if (e.target.value === "")
            setFormError("Name is required");
        else
            setFormError("");

        setNameValue(e.target.value);
    }

    const handleChange = (e, { id, field , country }) => {
        const value = e.target.value
        
         let val = Number(value);

            if (isNaN(val)) {
                val = 0;
            }

        const newData = [...data];
        newData[id][field] = val;
        
        setData(newData); 


    }

    //on Edit: Can add take and ask price

    const onAddTakePriceChange = (e) => {
        let val = e.target.value;
        //if (isNaN(val))
        //    val = 0;

        setAddTakePrice(val);
    }

    const onAddAskPriceChange = (e) => {
        let val = e.target.value;
        

        setAddAskPrice(val);
    }

    const doAddAskPrice = (e) => {
         if (isNaN(addAskPrice)) {
            setAddAskPrice(0);
        }
         else {

            let newData = [];
       
            for (let i = 0; i < data.length; i++) {
                //add value if it has year
                if (data[i].year)
                    newData.push({ ...data[i], askprice: (Number(data[i].askprice) + Number(addAskPrice)) });
                else
                    newData.push({ priceId: 0, name: '', year: null, askprice: null, takeprice: null });
            }

            setData(newData);
            setAddAskPrice(0);
        }
    }

    const doAddTakePrice = (e) => {
        if (isNaN(addTakePrice)) {
            setAddTakePrice(0);
        }
        else {
            let newData = [];
            for (let i = 0; i < data.length; i++) {
                //add value if it has year
                if (data[i].year)
                    newData.push({ ...data[i], takeprice: (Number(data[i].takeprice) + Number(addTakePrice)) });
                else
                    newData.push({ priceId: 0, name: '', year: null, askprice: null, takeprice: null });
            }

            setData(newData);
            setAddTakePrice(0);
        }
    }

    return (
        <Fragment>
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'New' : 'Existing')} {isTemp ? 'NEW' : ''} Price Matrix</DialogTitle>
            <DialogContent>
                {isLoading ? <LinearProgress /> :
                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                size="small"
                                name="name"
                                variant="outlined"
                                className={classes.nameHeader}
                                fullWidth
                                error={!!formError}
                                helperText={formError || ''}
                                value={nameValue || ''}
                                required
                                onChange={handleNameChange}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TableGrid rows={data} columns={columns} footer={
                                id === 0 ? <div></div> :
                                    <Table size="small" >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{width:150}}>
                                                </TableCell>
                                                <TableCell style={{ width: 150 }}>
                                                    <TextField aria-label="AskPrice"
                                                        size="small"
                                                        name="addAskPrice"
                                                        variant="outlined"
                                                        value={addAskPrice }
                                                        onChange={onAddAskPriceChange}
                                                    />
                                                    <Button variant="contained" onClick={doAddAskPrice}>Add</Button>
                                                </TableCell>
                                                <TableCell style={{ width: 150 }}>

                                                    <TextField aria-label="TakePrice"
                                                        size="small"
                                                        name="addTakePrice"
                                                        variant="outlined"
                                                        value={addTakePrice}
                                                        onChange={onAddTakePriceChange}
                                                    />
                                                    <Button variant="contained" onClick={doAddTakePrice}>Add</Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                            } />
                        </Grid>
                        {isBaseLoading || <Grid item xs={12}>
                            <Typography variant="subtitle1" style={{marginTop:5}}><strong>Attached Base Price combinations</strong></Typography>
                            <TableGrid rows={baseData} columns={baseCols} keycol="id" footer={<div></div>} />
                        </Grid>
                        }
                    </Grid>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={saveData} color="primary">
                    Save
                </Button>
                <Button onClick={onCancel} color="primary">
                    Close
                </Button>
                
            </DialogActions>
        </Fragment> 
    );
 
}
