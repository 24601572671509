import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions,  TextField,  Button,  LinearProgress, Grid} from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { useForm, Form, SelectControl } from '../../../../core/useForm';



const initialValues = { id:0, name:'', sam:10, minimum: 0, mfg:'', year: null};

const fieldsValidation = {
    name: {
        error: "Name is required",
        validate: "",
        minLength: 1,
        maxLength: 50,
        required: true
    },
    sam: {
        error: "",
        validate: "number",
        minLength: 0,
        maxLength: 10
    },
    minimum: {
        error: "Value required",
        validate: "number",
        required: true
        
    }  

}

export const MinimumPricingForm = ({ id, onSave, onCancel, onError , country, isTemp }) => {
    const [formErrors, setFormErrors] = React.useState({});

    const [data, setData, handleChange, validateForm] = useForm(initialValues, fieldsValidation, setFormErrors);

    const [isLoading, setIsLoading] = useState(true);
    const [isSamLoading, setIsSamLoading] = useState(true);
    const [isMakeLoading, setIsMakeLoading] = useState(true);
   
    const [samClasses, setSamClasses] = useState(true);
    const [makes, setMakes] = useState(true);
   

    //save the data
    const saveData = () => {      
        
        //add
        if (validateForm()) {

            let newData = { ...data };

            newData.sam = Number(data.sam);
            newData.minimum = Number(data.minimum);
            newData.year = Number(data.year);

            if(isTemp)
                postApiData(`${ApiRoot}/${country}/${ApiPaths.MinPricingTemp}`, 'POST', newData, onSave, onError);
            else
                postApiData(`${ApiRoot}/${country}/${ApiPaths.MinPricing}`, 'POST', newData, onSave, onError);
            

        }
      
    }

   

    const onMakeError = (msg) => {
        onError(msg);
    }

    const onSamError = (msg) => {
        onError(msg);
    }

    const onDataError = (msg) => {
        onError(msg);
    }


    

    const onData = (bpData) => {
  
        setData(bpData);
    }

   

    //sam classes
    const onSamClasses = (samData) => {
       
        setSamClasses(samData);
    }

    //makes
    const onMakes = (mkData) => {
        if (Array.isArray(mkData)) {
            mkData.unshift({ make: '' });
        }
      
        setMakes(mkData);
    }

   

    useEffect(() => {

        //fetch dropdowns
        //makes
        //fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FilterMakes}`, onMakes, setIsMakeLoading, onMakeError);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/MakeList`, onMakes, setIsMakeLoading, onMakeError);

        //sam classes
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FilterSamClasses}`, onSamClasses, setIsSamLoading, onSamError);
               
        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
           
        }
        else {
            if(isTemp)
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MinPricingTemp}/${id}`, onData, setIsLoading, onDataError);
            else
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.MinPricing}/${id}`, onData, setIsLoading, onDataError);
            


        }

    }, []);



    return (
        <Fragment>            
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} {isTemp ? 'NEW' : ''} Minimum Prices</DialogTitle>
            <DialogContent>
                {isLoading || isMakeLoading || isSamLoading ? <LinearProgress /> :
                    <Form>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Name"
                                    size="small"
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.name || ''}
                                    required
                                    error={!!formErrors.name}
                                    helperText={formErrors.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectControl
                                    label="SAM Class"
                                    id="code"
                                    options={samClasses}
                                    textValue="name"
                                    value={data?.sam || 10}
                                    name="sam"
                                    valueType="number"
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Minimum Price"
                                    size="small"
                                    name="minimum"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.minimum || ''}
                                    required
                                    error={!!formErrors.minimum}
                                    helperText={formErrors.minimum}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectControl
                                    label="Mfg"
                                    id="make"
                                    options={makes}
                                    textValue="make"
                                    value={data?.mfg || ''}
                                    name="mfg"
                                    handleChange={handleChange}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Year"
                                    size="small"
                                    name="year"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.year || ''}                                   
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>                                                  
                        </Grid>
                    </Form>
                }
                </DialogContent>
            <DialogActions>
                <Button onClick={saveData} color="primary">Save</Button>
                <Button onClick={onCancel} color="primary">Close</Button>               
            </DialogActions>            
        </Fragment> 
    );
 
}
