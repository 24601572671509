import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions, TextField, Button, LinearProgress, Grid } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { useForm, Form, SelectControl } from '../../../../core/useForm';


const initialValues = { id:0, name:'', sam:10};

const fieldsValidation = {
    name: {
        error: "Name is required",
        validate: "",
        required: true       
    },
    sam: {
        error: "Sam is required",
        validate: "number",
       
    },
   

}

export const OlderVehiclePricingForm = ({ id, onSave, onCancel, onError , country, isTemp }) => {
    const [formErrors, setFormErrors] = React.useState({});

    const [data, setData, handleChange, validateForm] = useForm(initialValues, fieldsValidation, setFormErrors);

    const [isLoading, setIsLoading] = useState(true);
    const [isSamLoading, setIsSamLoading] = useState(true);
    
    const [samClasses, setSamClasses] = useState(true);
    

    //save the data
    const saveData = () => {      
        
        //add
        if (validateForm()) {

            let newData = { ...data };

            newData.sam = Number(data.sam);

            newData.year1 =(data.year1)?  Number(data.year1):0;
            newData.year2 =(data.year2)?  Number(data.year2):0;
            newData.year3 =(data.year3)? Number(data.year3):0;
            newData.year4 = (data.year4)?  Number(data.year4):0;
            newData.year5 = (data.year5)? Number(data.year5): 0;
            newData.year6 = (data.year6)? Number(data.year6):0;

            newData.amount1 = (data.amount1)? Number(data.amount1):0;
            newData.amount2 =  (data.amount2)? Number(data.amount2):0;
            newData.amount3 =  (data.amount3)? Number(data.amount3):0;
            newData.amount4 =  (data.amount4)? Number(data.amount4):0;
            newData.amount5 = (data.amount5)?  Number(data.amount5):0;
            newData.amount6 =  (data.amount6)? Number(data.amount6):0;
            console.log(newData);
            if(isTemp)
                postApiData(`${ApiRoot}/${country}/${ApiPaths.OlderVehicleTemp}`, 'POST', newData, onSave, onError);
            else
                postApiData(`${ApiRoot}/${country}/${ApiPaths.OlderVehicle}`, 'POST', newData, onSave, onError);
            

        }
      
    }



    const onSamError = (msg) => {
        onError(msg);
    }

    const onDataError = (msg) => {
        onError(msg);
    }


    const onData = (bpData) => {

        setData(bpData);
    }

   

    //sam classes
    const onSamClasses = (samData) => {        
        
        setSamClasses(samData);
    }

    

   

    useEffect(() => {

        //fetch dropdowns
        //sam classes
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FilterSamClasses}`, onSamClasses, setIsSamLoading, onSamError);
               
        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
           
        }
        else {
            if(isTemp)
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.OlderVehicleTemp}/${id}`, onData, setIsLoading, onDataError);
            else
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.OlderVehicle}/${id}`, onData, setIsLoading, onDataError);
            


        }

    }, []);

    

    return (
        <Fragment>            
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} {isTemp ? 'NEW' : ''} Older Vehicle Pricing</DialogTitle>
            <DialogContent>
                {isLoading || isSamLoading ? <LinearProgress /> :
                    <Form>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Name"
                                    size="small"
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.name || ''}
                                    required
                                    error={!!formErrors.name}
                                    helperText={formErrors.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectControl
                                    label="SAM Class"
                                    id="code"
                                    options={samClasses}
                                    textValue="name"
                                    value={data?.sam || 0}
                                    name="sam"
                                    valueType="number"
                                    handleChange={handleChange}
                                />
                            </Grid>                           
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Year 1"
                                    size="small"
                                    name="year1"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.year1 || ''}
                                    inputProps={{ maxLength: 4 }}
                                    error={!!formErrors.year1}
                                    helperText={formErrors.year1}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Amount 1"
                                    size="small"
                                    name="amount1"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.amount1 || ''}
                                    
                                    error={!!formErrors.amount1}
                                    helperText={formErrors.amount1}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Year 2"
                                    size="small"
                                    name="year2"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.year2 || ''}
                                    inputProps={{ maxLength: 4 }}
                                    error={!!formErrors.year2}
                                    helperText={formErrors.year2}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Amount 2"
                                    size="small"
                                    name="amount2"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.amount2 || ''}
                                    
                                    error={!!formErrors.amount2}
                                    helperText={formErrors.amount2}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Year 3"
                                    size="small"
                                    name="year3"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.year3 || ''}
                                    inputProps={{ maxLength: 4 }}
                                    error={!!formErrors.year3}
                                    helperText={formErrors.year3}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Amount 3"
                                    size="small"
                                    name="amount3"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.amount3 || ''}
                                    
                                    error={!!formErrors.amount3}
                                    helperText={formErrors.amount3}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Year 4"
                                    size="small"
                                    name="year4"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.year4 || ''}
                                    inputProps={{ maxLength: 4 }}
                                    error={!!formErrors.year4}
                                    helperText={formErrors.year4}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Amount 4"
                                    size="small"
                                    name="amount4"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.amount4 || ''}
                                    
                                    error={!!formErrors.amount4}
                                    helperText={formErrors.amount4}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Year 5"
                                    size="small"
                                    name="year5"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.year5 || ''}
                                    inputProps={{ maxLength: 4 }}
                                    error={!!formErrors.year5}
                                    helperText={formErrors.year5}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Amount 5"
                                    size="small"
                                    name="amount5"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.amount5 || ''}
                                    
                                    error={!!formErrors.amount5}
                                    helperText={formErrors.amount5}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Year 6"
                                    size="small"
                                    name="year6"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.year6 || ''}
                                    inputProps={{ maxLength: 4 }}
                                    error={!!formErrors.year6}
                                    helperText={formErrors.year6}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Amount 6"
                                    size="small"
                                    name="amount6"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.amount6 || ''}
                                    
                                    error={!!formErrors.amount6}
                                    helperText={formErrors.amount6}
                                    onChange={handleChange}
                                />
                            </Grid>

                        </Grid>
                    </Form>
                }
                </DialogContent>
            <DialogActions>
                <Button onClick={saveData} color="primary">Save</Button>
                <Button onClick={onCancel} color="primary">Close</Button>                
            </DialogActions>            
        </Fragment> 
    );
 
}
