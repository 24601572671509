import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import React from 'react'

const SearchUnaccepted = ({search}) => {
    const [value, setValue] = React.useState("")
    const handleChange = (e) => setValue(e.target.value);
    return (
        <Paper sx={{ p: '1px', display: 'flex', alignItems: 'center', width: 350, border: '10px' }}>
            <IconButton type="button" sx={{ p: '10px', color:'primary.gray' }} aria-label="search" onClick={() => search(value)}>
                <LocalShippingOutlinedIcon />
            </IconButton>
            <InputBase placeholder="Type a Vehicle Number" type="number" 
                value={value}
                onChange={handleChange}
                sx={{
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                        padding: 0,
                    },
                    ml: 1,
                    flex: 1
                }}
                onKeyDown={e => {
                    if (e.code?.toLocaleLowerCase() === 'enter' && e.target.value) 
                      search(e.target.value);
                }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px', color: 'primary.main' }} aria-label="search" onClick={() => search(value)}>
                <SearchIcon />
            </IconButton>
        </Paper>
    )
}

export default SearchUnaccepted