import React, {useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import { UserContext } from '../../../../../core/UserContext'

import { fetchApiData , postApiData} from '../../../../../core/FetchData'
import { ApiPaths, ApiRoot, ApplicationPaths } from '../../../../../core/PathConstants'
import { useHistory } from 'react-router-dom';
import SnackAlert from './SnackAlert'

const FieldForm = ({
    catId,
    groupings,

    vehicleId,
    country,
    requestorGroupId,
    locationId,
    locationName,
    acceptedDateStr,
    acceptedBy,
    rideGoNoGo,
    engineCosts,
    vehicleCondition,
    onSubmitSpecs,
    allFields
}) => {

    const [sam, setSam] = useState(0);
    const [make, setMake] = useState('');                            //selected make 
    const [model, setModel] = useState('');                          //selected make
    const [makeListId, setMakeListId] = useState([]);                //list of make ids (for a category)
    const [makeListIdForSam, setMakeListIdForSam] = useState([]);    //list of spec ids for a sam class
    const [makeList, setMakeList] = useState([]);                    //list of Makes
    const [modelListId, setModelListId] = useState([]);              //list of model ids (for a category)
    const [modelList, setModelList] = useState([]);
    const [makeLoaded, setMakeLoaded] = useState(0);
    const [specId, setSpecId] = useState(0);


    const userContext = useContext(UserContext)
    const userEmail = (userContext?.user?.upn ?? userContext?.user?.preferred_username ?? '').split('@')[0]

    //console.log(groupings);

    if (sam === 0) {
        //console.log(allFields[0].groupings[0].fields.find(o => o.specID === 71)?.specVehicleValue)
        setSam(allFields[0].groupings[0].fields.find(o => o.specID === 71)?.specVehicleValue);
    }

    if (makeLoaded === 0) {
        for (let i = 0; i < groupings.length; i++) {
            let spec = groupings[i].fields.filter(x => x.standardSpecCode?.includes("MAKE"));
            let specModel = groupings[i].fields.filter(x => x.standardSpecCode?.includes("MODEL"));
            for (let j = 0; j < spec.length; j++) {
                makeListId.push(spec[j].specID);
                makeListIdForSam.push(spec[j].specID);
                makeList.push(spec[j]?.selectList);
            }
            for (let j = 0; j < specModel.length; j++) {
                modelListId.push(specModel[j]?.specID);
            }
        }
        setMakeLoaded(1);

    }

    const defaultValues = {
        ...groupings.map(x => x.fields).flat()
            .map(x =>
                x.specTypeDesc !== "Date"
                    ? ({ [`fld_${x.specID}`]: x.specVehicleValue })
                    : ({ [`fld_${x.specID}`]: (x.specVehicleValue === "") ? null : x.specVehicleValue })
            )
            .reduce((a, b) => Object.assign(a, b), {}),
        ...(catId === 2 && requestorGroupId >= 2) && { 'fld_locationId': ((locationId <= 0) ? '' : `${locationId}`) }
        //, makeListId: makeListId
        //, makeListIdForSam: makeListIdForSam
        //, makeList: makeList
        //, modelList: modelList 
        //, specId: 0
    }


    const canBeRepriced = () => {
        try {
            const [month, day, year] = acceptedDateStr.split('/');
            let _acceptedDate = new Date(+year, +month - 1, +day);
            _acceptedDate.setDate(_acceptedDate.getDate() + 3);
            return _acceptedDate > Date.now() || requestorGroupId > 2;
        } catch (error) {
            return false;
        }
    }

    const { handleSubmit, getValues, setValue, control, watch } = useForm({ defaultValues });

    const onSubmit = data => onSubmitSpecs(catId, Object.keys(data).map((k) => ({ key: k, text: data[k] })) || [])

    //console.log("default values, modelListId, modelList", getValues(), modelListId, modelList)

    const getOthersGroupSpec = (group, currentSpecID) => groupings.find(x => x.grouping === group)?.fields
        .filter(x => x.specID !== currentSpecID)
        .map(x => `fld_${x.specID}`) ?? [];

    const notRequiredSpecsIds = [253, 167]
    const validateFieldList = (group, field, val) => {
        const { specID, specName, specTypeDesc, specLength } = field;
        //--> Odometer Validation for Spec fields combination 117 & 189
        if (specID === 117) {
            const _refVal = field.specReferenceValue;
            if (val === "" && _refVal !== "")
                setValue('fld_117', _refVal, { shouldValidate: true })

            if (val !== "" && /^-?\d+(\.\d{1,2})?$/.test(val))
                return true
        }
        if (specID === 189) {
            const _refVal = field.specReferenceValue; //=> from 117
            const _117Val = getValues('fld_117')

            if (val === "" && (+_refVal) !== (+_117Val))
                return "Provide the Odometer Override Reason";

            if (val !== "" && _refVal === _117Val)
                return "Please clear the Odom Override Reason";

            return true
        }
        //--> Odometer Validation ends here

        //--> Reefer Validation for Spec fields combination 116 & 121 & 191 & 108
        if (specID === 116) {
            if (val === "") {
                const _121Val = getValues('fld_121') //=> 121 is not always present .. ask Yamile
                if (_121Val === "Yes")
                    return true;
            }
            else {
                const _refVal = field.specReferenceValue;
                if (/^-?\d+(\.\d{1,2})?$/.test(val)
                    && /^-?\d+(\.\d{1,2})?$/.test(_refVal)) {
                    const reefdiff = Math.abs((+_refVal) - (+val))
                    if (reefdiff > 0 && reefdiff < 250) {
                        setValue('fld_116', _refVal, { shouldValidate: true })
                        return ("No Reefer Engine adjustments of less than 250 hours allowed.")
                    }
                }
            }
        }
        if (specID === 191) {
            const _refVal = field.specReferenceValue; //=> from 116
            const _116Val = getValues('fld_116')
            if (val === "" && _refVal !== _116Val) {
                const _108Val = getValues('fld_108')
                if (_108Val === "Yes")
                    return "Please enter Reefer Engine Hours Override Reason"
            }
            if (val !== "" && _refVal === _116Val)
                return "Please clear Reefer Engine Hours Override Reason"

            return true
        }
        //--> Reefer Validation ends here   

        if (notRequiredSpecsIds.includes(specID) && (val ?? "").trim() === "")
            return true

        if (specTypeDesc !== "Date") {
            val = (val ?? "").trim();
            if (val === "") {
                if (group !== "") {
                    const others = getOthersGroupSpec(group, specID)
                    if (others.length === 0)
                        return true;

                    const anotherValueAlready = getValues(others).some(x => (x ?? '') !== '');
                    if (!anotherValueAlready)
                        return true;
                }

                return `${specName} required.`
            }
            if (specTypeDesc === "Text") {
                const strSize = specLength > 25 ? 25 : (specLength + 2)
                if (val.length > strSize)
                    return `${specName} invalid length value. No more than ${strSize} characters.`
            }
            if (specTypeDesc === "Value") {
                if (val.length > 14)
                    return `${specName} invalid length value. No more than 14 characters.`
                if (specID === 19 || specID === 78) {
                    if (!(/^-?\d+?$/.test(val)))
                        return `${specName} invalid value`
                }
                else                 
                    if (!(/^-?\d+(\.\d{1,2})?$/.test(val)))
                        return `${specName} invalid value`
                if (field.specFrom !== "" && /^-?\d+(\.\d{1,2})?$/.test(field.specFrom) && +val < +(field.specFrom))
                    return `${specName} can't be lower than ${field.specFrom}`
                if (field.specTo !== "" && /^-?\d+(\.\d{1,2})?$/.test(field.specTo) && +val > +(field.specTo))
                    return `${specName} can't be higher than ${field.specTo}`
            }
            if (specTypeDesc === "Memo") {
                if (val.length > 1000)
                    return `${specName} invalid length value. No more than 1000 characters.`
            }
        } else {
            if (val instanceof Date) {
                if (!isNaN(val) && val > 0)
                    return true; //=> date format has to be check

                return `${specName} invalid date.`
            } else {
                if (!val) {
                    if (group !== "") {
                        const others = getOthersGroupSpec(group, specID)
                        if (others.length === 0)
                            return true;

                        const anotherValueAlready = getValues(others).some(x => (x || '') !== '');
                        if (!anotherValueAlready)
                            return true;
                    }
                    return `${specName} required.`
                }
            }
        }
        //-> rest of the validations (custom specs)
        return true
    }

    const onGroupingClear = (grp) =>
        groupings.find(x => x.grouping === grp)?.fields.map(x =>
            x.specTypeDesc === "Date"
                ? setValue(`fld_${x.specID}`, null, { shouldValidate: false })
                : setValue(`fld_${x.specID}`, '', { shouldValidate: false }));

    const labelStyle = { padding: "9px 0", display: "block", lineHeight: "1rem" }

    const history = useHistory();
    const [error, setError] = useState('');
    const [okStatus, setOkStatus] = useState("");

    const rePrice = () => {

        if ((country || "").toLowerCase() === 'ca') {
            postApiData(
                `${ApiRoot}/ca/${ApiPaths.VehicleSpecs_Update_Pricing}`,
                'POST',
                { vehicleId, acceptedLocationId: locationId, RequestorEmail: userEmail },
                () => {
                    setOkStatus("Information Saved!")
                    setTimeout(() => { history.push(`/ca${ApplicationPaths.SpecsPricing}/${vehicleId}/pricing`); }, 500)
                },
                (err) => setError(err || "Error while Saving the Price")
            )
        } else
            history.push(`/us${ApplicationPaths.RePricing}/${vehicleId}`);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            let idx = name.indexOf('_');
            setSpecId(name.substring(idx + 1));                 
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const customOnChange = (data) => {

        if (data.target.name === 'Sam') {
            setSam(data.target.value)                             
            //LoadMakeList();
        }        
        if (data.target.name === 'Make') {
            setMake(data.target.value)                            
            //setModel('');
            //LoadModelList();
        }     
    }

    useEffect(() => {
        LoadMakeList();
    }, [sam]);

    const LoadMakeList = () => {
        if (sam > 0) {
            let spec = (specId === 0) ? 7 : specId;
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getMakes/${sam}/${vehicleId}/${spec}/${catId}`,
                onMakeReady
                //(err) => onError(err ?? "Error while getting the data.")
            );
        }
    }

    useEffect(() => {
        console.log('Make..Models Loaded', sam, specId, make);
        LoadModelList();
    }, [make]);

    const LoadModelList = () => {

        //console.log("LoadModelList loaded...", make, specId);

        if (make !== "" && specId > 0 ) {

            //console.log(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make}/${vehicleId}/${LoadModelSpecId(specId)}}`);
            //fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make}/${vehicleId}/${LoadModelSpecId(specId)}`,
            //    onModelReady,
            //    // (err) => onError(err ?? "Error while getting the data.")

            //);

            var tArrayList = [];
            var values = getValues();

            for (let i = 0; i < makeListId.length; i++) {
                let mlistKey = makeListId[i];
                for (let j = 0; j < Object.keys(values).length; j++) {
                    let idx = Object.keys(values)[j].indexOf('_');
                    let specid = parseInt(Object.keys(values)[j].substring(idx + 1));
                    if (mlistKey === specid) {

                        //single...
                        //tArrayList.push(Object.values(values)[j]);
                        //LoadModelsForMake(Object.values(values)[j], specid);

                        //multiple...
                        tArrayList.push({ "specId": LoadModelSpecId(specid), "Make": Object.values(values)[j] });

                        //console.log("loading models,specid for:", Object.values(values)[j], specid)

                    }
                }
            }

            ////var m = modelList;
            ////while (modelList.length > 0) {
            ////    modelList.pop();
            ////}
            ////setModelList(oldArray => [...oldArray, m]);


            //multiple
            let encodedObject = JSON.stringify(tArrayList);
            //console.log(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make}/${vehicleId}/${LoadModelSpecId(specId)}}/${encodedObject.toString()}`);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make}/${vehicleId}/${LoadModelSpecId(specId)}/${encodedObject}`,
                onModelReady
               // (err) => onError(err ?? "Error while getting the data."),
            );

            //console.log("Final: ArrayMakes, modelList:", tArrayList, modelList);
        }

    }

    const LoadModelsForMake = (make1, specId1) => {

        if (make1 !== "" && specId1 > 0) {

            //console.log(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make1}/${vehicleId}/${LoadModelSpecId(specId1)}}`);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make1}/${vehicleId}/${LoadModelSpecId(specId1)}`,
                onModelReady,
                // (err) => onError(err ?? "Error while getting the data.")

            );
        }

    }


    const onMakeReady = (make) => {

        //console.log(make);
        setMakeList(make);

    }

    const onModelReady = (model) => {
        //console.log("Model update--", model, Object.keys(model), Object.values(model));
        setModelList(model);                                                         
        //setModelList(oldList => [...modelList, model]);

        //for (let i = 0; i < Object.keys(model).length; i++) {
        //    let key = parseInt(Object.keys(model)[i]);
        //    let value = Object.values(model)[i];
            
        //    //modelList.push([{ "key": key, "value": value }]);
        //    setModelList(model);
        //}

        //console.log("---", modelList)
        
    }

    const LoadModelSpecId = (SpecId) => {
        var makeModelDt = {
            86: 87, 7: 8, 88: 79, 44: 45, 98: 99, 84: 85, 10: 101, 81: 95
        };
        return makeModelDt[SpecId];
    }

    const onError = () => console.log("error while loading select list");


    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ p: 2 }}>
            { (catId === 2) && 
                <Grid key={'location'} container spacing={1} sx={{ mb:"8px"}}>
                    <FieldWrapper id={2000} name={"Accepted At"}>
                        {(requestorGroupId >= 2)
                            ? <FieldLocationLookup control={control} validate={validateFieldList} country={country}/>
                            : <b style={labelStyle}>{locationName}</b>
                        }
                    </FieldWrapper>
                    <FieldWrapper id={2001} name={"Accepted By"}>
                        <b style={labelStyle}>{acceptedBy}</b>
                    </FieldWrapper>
                </Grid>
            }
            { groupings.map(x => (
                <Grid key={x.grouping} container spacing={1}>
                    { (x.grouping !== "" ) && 
                        <Grid item key={x.grouping} xs={12} sx={{mt:"12px", mb:"6px"}}>
                            <Divider textAlign="left">
                                <label style={{ fontSize: '.9em', color: "gray", margin:"auto", textTransform:"uppercase"}}>{x.grouping}</label>
                                <Button variant="outlined" type='button' size='small' sx={{ml: 2, p: 0}} onClick={() => {onGroupingClear(x.grouping);}}>clear</Button>
                            </Divider>
                        </Grid> }
                    {x.fields.map(f => { //-> ex 331701
                        switch (f.specTypeDesc) {

                            case "List": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldMakeList group={x.grouping} field={f} control={control} validate={validateFieldList} Sam={sam} MakeListId={makeListId} MakeList={makeList} ModelListId={modelListId} ModelList={modelList} customOnChange={customOnChange} /></FieldWrapper>

                            //case "List": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldList group={x.grouping} field={f} control={control} validate={validateFieldList} customOnChange={customOnChange} /></FieldWrapper>

                            case "Text": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldText group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                            case "Value": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldValue group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                            case "Date": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldDate group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                            case "Memo": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName} col={12}  ><FieldMemo group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                            default: return null
                        }
                    })}            
                </Grid>
            ))}
                <Grid item key={'footer-div'} xs={12} sx={{mt:"12px", mb:"6px"}}>
                    <Divider textAlign="left"></Divider>
                </Grid>
                <Grid key={'footer'} container spacing={1} sx={{ mb:"8px"}}>
                    <FieldWrapper id={2003} name={"Ride Go | No Go | Maybe"}><b style={labelStyle}>{rideGoNoGo}</b></FieldWrapper>
                    <FieldWrapper id={2004} name={"Engine Repair Costs"}    ><b style={labelStyle}>{engineCosts}</b></FieldWrapper>
                    <FieldWrapper id={2005} col={12} name={"Condition"}     ><b style={labelStyle}>{vehicleCondition}</b></FieldWrapper>
                </Grid>
            </Box>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" padding={2}>
                { canBeRepriced() &&
                    <Button onClick={rePrice} variant="contained" size='small'>reprice</Button>}
                    {/* <Button component={Link} to={(country == 'us')?`/${country}${ApplicationPaths.RePricing}/${vehicleId}`:`/${country}${ApplicationPaths.SpecsPricing}/${vehicleId}/pricing`} variant="contained" size='small'>reprice</Button>} */}
                <Button variant="contained" type='submit' size='small'  >save</Button>
            </Stack>
        </form>
        <SnackAlert errorMsg={error} onCloseError={()=> {setError('')}} okMsg={okStatus} onCloseOk= {()=> setOkStatus('')}/>
        </>
    )
}

export default FieldForm


export const FieldLocationLookup = ({country, control, validate}) => {
    const [selectList, setSelectList] = useState([])
    const onDataReady = (data) => setSelectList((data ?? []))    
    const onError = () => console.log("error while loading select list");
    useEffect(() => { fetchApiData(`${ApiRoot}/${country}/${ApiPaths.LocationsLookup}`, onDataReady, ()=>{}, onError) }, [country])
    return (
        <Controller
            name={`fld_locationId`}
            control={control}
            rules={{ validate: (val) => validate('', {specID: "locationId", specName: "UTC Location", specTypeDesc: "custom"}, val) }}
            render={({
                field: { onChange, value },
                fieldState: { error },
            }) => ( (selectList || []).length > 0 
            ? (<>
                    <Select 
                        value={value} onChange={onChange} error={!!error} defaultValue=""
                        size={'small'} sx={{minWidth: "80%"}}>
                        <MenuItem value={""}><em>- Select -</em></MenuItem>
                        { (selectList || []).map(x => <MenuItem key={x.key} value={`${x.key}`}>{x.text}</MenuItem>)}
                    </Select>
                    {error ? <FormHelperText htmlFor='render-select' error>{error.message}</FormHelperText> : null}
                </>)
            : <></>)
            }
        />
    );
}


export const FieldMakeList = ({ group, field, control, validate, Sam, MakeListId, MakeList, ModelListId, ModelList, customOnChange }) => {

    //const { specID, selectList } = field
    let { specID } = field;

    let selectList = []; 
    selectList = field.selectList;

    //console.log("Inside loop", specID, ModelList);

    //Assign Makes
    for (let i = 0; i < Object.keys(MakeList).length; i++) {
        let k = parseInt(Object.keys(MakeList)[i]);
        let v = Object.values(MakeList)[i];

        if (k === specID) {
            selectList = v;
        }
    }

    //Assign Models
    for (let i = 0; i < Object.keys(ModelList).length; i++) { 
        let k = parseInt(Object.keys(ModelList)[i]);
        let v = Object.values(ModelList)[i];
        //console.log("Test for Model...", k, v)
        if (k === specID) {
            //console.log("Test for Model", specID, ModelList, v);
            selectList = v;
        }
    }


    let name = "";
    if (specID === 71) { name = "Sam" }

    for (let i = 0; i < MakeListId.length; i++) {
        let specId = parseInt(MakeListId[i]);
        if (specId === specID) {
            name = "Make";
        }
        
    }



    //console.log('Inside List...', specID, Sam, selectList);

        //let SpecSeven = Object.values(MakeList).includes(7);
    //    console.log("Specid-:", specID);
    //for (let i = 0; i < Object.keys(MakeListId).length; i++) {
    //    let specId = parseInt(Object.keys(MakeListId)[i]);
    //        if (specId === specID) {
    //            let values = Object.values(MakeListId)[i];
    //            selectList.value = values;
    //            console.log(values);
    //            console.log(selectList);     //old
    //           // setSelectList(values);
    //        }
    //    }
    //}

    return (
        <Controller
            name={`fld_${specID}`}
            control={control}
            rules={{ validate: (val) => validate(group, field, val) }}
            defaultValue=""
            render={({
                field: { onChange, value },
                fieldState: { isTouched, isDirty, error },
                formState
            }) => (
                <>
                    <Select  
                        //value={value} onChange={onChange} error={!!error} defaultValue=""
                        name={name || ""}
                        value={value || ""}
                        onChange={(x) => { onChange(x); customOnChange && customOnChange(x); }} 
                        error={!!error}
                        size={'small'} sx={{ minWidth: "80%" }}>

                        <MenuItem value={""}><em>- Select -</em></MenuItem>
                        {(selectList || []).map(x =>
                            < MenuItem
                                key={x}
                                value={x}>{x}
                            </MenuItem>
                        )
                        }                        
                    </Select>
                    {error ? <FormHelperText htmlFor='render-select' error>{error.message}</FormHelperText> : null}
                </>
            )}
        />
    );
}


export const FieldList = ({ group, field, control, validate, customOnChange }) => {
    const { specID, selectList } = field
    
    return (
        <Controller
            name={`fld_${specID}`}
            control={control}
            rules={{ validate: (val) => validate(group, field, val) }}
            defaultValue=""
            render={({
                field: { onChange, value, specID },
                fieldState: { isTouched, isDirty, error },
                formState
            }) => (
                <>
                    <Select
                        //value={value} onChange={onChange} error={!!error} defaultValue=""
                        value={value || ""}
                        onChange={(x) => { onChange(x); customOnChange && customOnChange(x); }} 
                        error={!!error} 
                    size={'small'} sx={{minWidth: "80%"}}>
                        <MenuItem value={""}><em>- Select -</em></MenuItem>
                        {(selectList || []).map(x => 
                            < MenuItem
                                    key={x}
                                    value={x}>{x}
                              </MenuItem>                              
                        )
                        }

                </Select>
                {error ? <FormHelperText htmlFor='render-select' error>{error.message}</FormHelperText> : null}
                </>
            )}
        />
    );
}

export const FieldText = ({group, field, control, validate}) => {
    const {specID, specLength} = field
    const maxlength = specLength === 0 ? 25 : specLength

    return <Controller
            name={`fld_${specID}`}
            control={control}
            rules={{ validate: (val) => validate(group, field, val) }}
            defaultValue=""
            render={({
                field: { onChange, value },
                fieldState: { isTouched, isDirty, error },
                formState
            }) => (
                <>
                    <TextField
                        value={value} onChange={onChange} error={!!error} 
                        size={'small'} sx={{minWidth: "80%"}} maxLength={maxlength} />
                    {error && <FormHelperText htmlFor='render-select' error>{error.message}</FormHelperText>}
                </>
            )}
        />
}

export const FieldValue = ({group, field, control, validate}) => {
    const {specID, unitTypeDesc, specReferenceText} = field

    return <Controller
        name={`fld_${specID}`}
        control={control}
        rules={{ validate: (val) => validate(group, field, val) }}
        defaultValue=""
        render={({
            field: { onChange, value },
            fieldState: { isTouched, isDirty, error },
            formState,
        }) => (
            <>
                <TextField 
                    value={value} onChange={onChange} error={!!error}
                    size={'small'} sx={{minWidth: "80%"}} type="number" maxLength={14} />
                {(unitTypeDesc || "") !== "" && <b><em>unitTypeDesc</em></b>  }
                {specReferenceText && specReferenceText !== "" && <small style={{display: "block", fontSize: '.7em', color: "gray"}}>{specReferenceText}</small>}
                {error && <FormHelperText htmlFor='render-select' error>{error.message}</FormHelperText>} 
            </>
        )}
    />
}

export const FieldDate = ({group, field, control, validate}) => {
    const {specID} = field

    return <Controller
        name={`fld_${specID}`}
        control={control}
        rules={{ validate: (val) => validate(group, field, val) }}
        defaultValue=""
        render={({
            field: { onChange, value },
            fieldState: { isTouched, isDirty, error },
            formState,
        }) => (
            <>
                <DatePicker 
                    value={value} onChange={onChange} error={!!error} 
                    renderInput={(params) => <TextField {...params} size={'small'} sx={{ width: 150 }} />}
                />
                {error && <FormHelperText htmlFor='render-select' error>{error.message}</FormHelperText>} 
            </>
        )}
    />
}

export const FieldMemo = ({group, field, control, validate}) => {
    const {specID} = field
    
    return <Controller
        name={`fld_${specID}`}
        control={control}
        rules={{ validate: (val) => validate(group, field, val) }}
        defaultValue=""
        render={({
            field: { onChange, value },
            fieldState: { isTouched, isDirty, error },
            formState,
        }) => (
            <>
                <TextField
                    value={value} onChange={onChange} error={!!error} 
                    size={'small'} sx={{minWidth: "92%"}} multiline rows="3" maxLength={1000} />
                {error && <FormHelperText htmlFor='render-select' error>{error.message}</FormHelperText>} 
            </>
        )}
    />
}

export const FieldWrapper = ({ id, name, children, col = 6}) => (
    <Grid item xs={col} >
        <Box sx={{display: 'flex', width: '100%', alignItems: 'center'}}>
            <Box sx={{minWidth: "190px"}}>
                <label id={`label_${id}`} style={{ fontSize: '.8em', color: "gray", margin:"auto"}}>{name}</label>
            </Box>
            <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </Box>
    </Grid>
 );