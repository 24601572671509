import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,  Typography,  Paper,  Select, MenuItem, Button, LinearProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../core/FetchData';
import AlertMessage from '../../../core/AlertMessage';
import { CategoryForm } from './CategoryForm';
import { TableGrid } from '../../../core/TableGrid';



export const Categories = function ({country}) {
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setConfirmOpen] = React.useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    const [catEnabled, setCatEnabled] = useState(' ');


    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const handleClose = () => {
        setOpen(false);
    };

    const onErrorLoading = (status) => {
        showAlert('Error retrieving data', 'error');
    };

    const onError = (errMsg) => {
        showAlert("Error: " + errMsg, 'error');
    };

    const onDelComplete = () => {
        showAlert('Category deleted', 'success');
        fetchData();
    };

    //DEL functions
    //on click yes to delete
    const handleYes = () => {
        setConfirmOpen(false);
        if (selectedId) {
            postApiData(`${ApiRoot}/${country}/${ApiPaths.Categories}/${selectedId}`, 'DELETE', null, onDelComplete, onError);
        }
    };


    const handleSave = () => {
        setOpen(false);
        //refresh
        showAlert('Category successfully saved', 'success')
        fetchData();
    };


    const onDel = (id) => {
        setSelectedId(id);
        //
        setConfirmOpen(true);
    }


    const onEdit = (id) => {
        setSelectedId(id);
        setOpen(true);

    }
    const onAdd = () => {
        setSelectedId(0);
        setOpen(true);
    }



    const onData = (d) => {

        
        setData(d);
    }

    //fetch data
    const fetchData = () => {
        setIsLoading(true);
        if (catEnabled === ' ')
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Categories}`, onData, setIsLoading, onErrorLoading);
        else
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.CategoriesEnabled}/${catEnabled}`, onData, setIsLoading, onErrorLoading);

    }

    useEffect(() => {
        fetchData();
    }, [catEnabled]);

    const handleChange = (e) => {
        const value = e.target.value;
        setCatEnabled(value);
       // fetchData();
    }

    //column definition 
    const columns = [
        {
            field: 'catID',
            headerName: ' ',
            disableColumnMenu: true,
            hideSortIcons:true,
            disableClickEventBubbling: true,
            renderHeader: (params) => (
                <IconButton aria-label="Add"
                    onClick={(event) => {
                        event.ignore = true;
                        onAdd();
                    }
                    } >
                    <AddCircleIcon style={{color:'white'}} />
                </IconButton>

            ),
            renderCell: (params) => (               
                    <IconButton aria-label="Edit"
                        onClick={(event) => {
                            event.ignore = true;
                            onEdit(params.value);
                        }
                        } >
                        <EditIcon />
                    </IconButton>
                  
            ),
        },
        {
            field: 'catEnabled', headerName: 'Enabled', width: 150,
            type: 'boolean'

        },
        {
            field: 'catDesc', headerName: 'Category Name', flex: 1,

        },  
        {
            field: 'del',
            headerName: ' ', width: 60,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,            
            renderCell: (params) => (
                <IconButton aria-label="Del"
                    onClick={(event) => {
                        event.ignore = true;
                        onDel(params.id);
                    }
                    } >
                    <DeleteIcon style={{color:'salmon'}} />
                </IconButton>

            ),
        },
         
    ];

   

    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Maintain Categories</Typography>
            <Paper style={{ padding: 8 }}>
                <Select
                    labelId="cat-label"
                    id="cat-select"
                    value={catEnabled}
                    name="cat" onChange={handleChange}
                    size="small"
                    margin="dense"
                >                    
                    <MenuItem value=" ">All Categories</MenuItem>
                    <MenuItem value="true">Enabled Categories</MenuItem>
                    <MenuItem value="false">Disabled Categories</MenuItem>

                   
                </Select>
            </Paper>
            <Paper>
                {(isLoading) ? <LinearProgress /> :
                    <div style={{ height: 640, width: '100%' }}>
                        <TableGrid rows={data} columns={columns} keycol="catID" />
                    </div>
                    }
            </Paper>
            {/*Open dialog*/}
            <Dialog open={open} aria-labelledby="form-dialog-id" fullWidth maxWidth="md">
                <CategoryForm id={selectedId} onCancel={handleClose} onError={onError} onSave={handleSave} country={country} />
            </Dialog>
            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this Category?
                             </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} color="primary">
                        Yes
                             </Button>
                    <Button onClick={handleConfirmClose} color="primary" autoFocus>
                        No
                            </Button>
                </DialogActions>
            </Dialog>            
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div> 
    );
 
}
