import { CardActionArea, Grid, Card, CardContent, Typography, CardMedia, Box, Paper, Button, Container } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import React, { useEffect, useState } from 'react';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import { ApplicationPaths } from '../../../../core/PathConstants';


export const PricingHome = ({country}) => {
    

    return (
        <Container>
            <Paper
                sx={{
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    mb: 4,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center',
                    backgroundImage: `url(${'/images/RyderBackground1.webp'})`,
                }}
            >
                {/* Increase the priority of the hero background image */}
                {<img style={{ display: 'none' }} src="/images/RyderBackground1.webp" alt="Ryder" />}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.5)',
                    }}
                />
                <Grid container>
                    <Grid item md={6}>                        
                        <Box
                            sx={{
                                position: 'relative',
                                p: { xs: 3, md: 3 },
                                pr: { md: 0 },
                            }}
                        >
                            <Typography component="h3" variant="h3" color="inherit" >
                                UVS Pricing
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                Pricing Tables
                            </Typography>                           
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {/*<Typography component="h2" variant="h4" my={2}>UVS Pricing</Typography>*/}
            <Typography component="h4" variant="h4" my={1} color="text.secondary">
                Pricing Tables Menu 
            </Typography>
            <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button startIcon={<AttachMoneyIcon />} component={Link} to={`/${country}${ApplicationPaths.BasePrices}`}>
                            Base Prices
                         </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button startIcon={<PriceChangeIcon />} component={Link} to={`/${country}${ApplicationPaths.PriceAddDeduct}`}>
                            Price Adds/Deducts
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button startIcon={<Grid4x4Icon />} component={Link} to={`/${country}${ApplicationPaths.PriceMatrix}`}>
                            Price Matrix
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button startIcon={<AddRoadIcon />} component={Link} to={`/${country}${ApplicationPaths.MileageMatrix}`}>
                            Mileage Matrix
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button startIcon={<CompareArrowsIcon />} component={Link} to={`/${country}${ApplicationPaths.AreaAddDeduct}`}>
                            Area Adds/Deducts
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button startIcon={<LocalShippingIcon />} component={Link} to={`/${country}${ApplicationPaths.OlderVehicle}`}>
                            Older Vehicle Pricing
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button startIcon={<IndeterminateCheckBoxIcon />} component={Link} to={`/${country}${ApplicationPaths.MinPricing}`}>
                            Minimum Pricing
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button startIcon={<PriceCheckIcon />} component={Link} to={`/${country}${ApplicationPaths.FixedPricing}`}>
                            Fixed Pricing
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
           
      </Container>
    );
 
}
