import { TableGrid } from '../../../../core/TableGrid';
import DiscountFields from './DiscountFields';
import VehicleYearsSelector from './VehicleYearsSelector';

const AgingBucketDetailsList = ({ bucketDetails, setBucketDetails, ...props }) => {
    if (!bucketDetails.agingBucketId) {
        return null;
    }

    // Define custom renderCell function
    const renderCellBold = (params) => <b>{params.value}</b>;

    // Helper function to determine the display of years
    const formatYearRange = () => {
        if (bucketDetails.allYears) {
            return "ALL";
        } else if (bucketDetails.yearFrom && bucketDetails.yearTo) {
            return `${bucketDetails.yearFrom} - ${bucketDetails.yearTo}`;
        } else if (bucketDetails.yearFrom) {
            return `${bucketDetails.yearFrom} or newer`;
        } else if (bucketDetails.yearTo) {
            return `${bucketDetails.yearTo} or older`;
        }
        return "";
    };

    // Construct the rows for the details grid
    const detailRows = [
        {
            id: 1,
            attribute: 'Age',
            values: `${bucketDetails.minDays} - ${bucketDetails.maxDays} days`,
            discountType: bucketDetails.discountTypeDescription,
            discount: <DiscountFields bucketDetails={bucketDetails} setBucketDetails={setBucketDetails} />
        },
        {
            id: 2,
            attribute: 'Vehicle Class',
            values: bucketDetails.allClasses ? 'ALL' : bucketDetails.vehicleClasses,
            discountType: '',
            discount: ''
        },
        {
            id: 3,
            attribute: <VehicleYearsSelector bucketDetails={bucketDetails} setBucketDetails={setBucketDetails} />,
            values: formatYearRange(),
            discountType: '',
            discount: ''
        },
        {
            id: 4,
            attribute: 'Manufacturer',
            values: bucketDetails.allManufacturers ? 'ALL' : bucketDetails.manufacturers,
            discountType: '',
            discount: ''
        },
        {
            id: 5,
            attribute: 'Location',
            values: bucketDetails.allLocations ? 'ALL' : bucketDetails.locations,
            discountType: '',
            discount: ''
        }
    ];

    // Define columns for the details grid
    const columns = [
        { field: 'attribute', headerName: '', width: 100, disableColumnMenu: true, renderCell: renderCellBold },
        { field: 'values', headerName: 'Values', width: 300, disableColumnMenu: true },
        { field: 'discountType', headerName: 'Discount Type', width: 100, disableColumnMenu: true },
        { field: 'discount', headerName: 'Discount', width: 300, disableColumnMenu: true }
    ];

    // Use the TableGrid component to render the details
    return (
        <div {...props}>
            <TableGrid rows={detailRows} columns={columns} keycol='id' rowsPerPageOption={10} sx={{ minHeight: 245 }} rowStyles={{height: '3.5em'}}/>
        </div>
    );
};

export default AgingBucketDetailsList;