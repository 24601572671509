import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions,   TextField, Paper,  Button,  LinearProgress,  Grid, Typography, IconButton } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { useForm, CheckboxControl, Form, SelectControl } from '../../../../core/useForm';
import { TableGrid } from '../../../../core/TableGrid';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme =>({
    listButton: {
        margin: theme.spacing(1)
       
    },
    panel: {
        margin: theme.spacing(1)

    }
}));

const initialValues = {
    exceptionId : 0, name: '',  enabled: false
};

const fieldsValidation = {

    name: {
        error: "Exception Name is required",
        required: true,
    },
}

export const AutoPricingExceptionForm = ({ id, onSave, onCancel, onError , country }) => {
    const classes = useStyles();
    const [formError, setFormError] = React.useState({});

    const [data, setData, handleChange, validateForm] = useForm(initialValues, fieldsValidation, setFormError);
    const [isLoading, setIsLoading] = useState(true); 

    const [rules, setRules] = useState([]);
    const [isRulesLoading, setIsRulesLoading] = useState(true); 

    const [ruleSpecs, setRuleSpecs] = useState([]); 
    const [isRSLoading, setIsRSLoading] = useState(true); 
   
    const [criterias, setCriterias] = useState([]);
    const [isCriteriaLoading, setIsCriteriaLoading] = useState(true);

    const [showValue2, setShowValue2] = useState(false);


    const [ruleSpec, setRuleSpec] = useState('');
    const [criteria, setCriteria] = useState('');
    const [values1, setValues1] = useState('');
    const [values2, setValues2] = useState('');

    

    const columns = [
       
        {
            field: 'spec', headerName: 'Spec', width: 150, 
        },

        {
            field: 'criteria', headerName: 'Criteria', width: 150,
        },
        {
            field: 'values1', headerName: 'Value 1', width: 150,
        },       
        {
            field: 'values2', headerName: 'Value 2', width: 150,
        },
        {
            field: 'del',
            headerName: ' ', width: 60,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton aria-label="Del"
                    onClick={(event) => {
                        event.ignore = true;
                        onDelRule(params.id);
                    }
                    } >
                    <DeleteIcon style={{ color: 'salmon' }} />
                </IconButton>

            ),
        },
    ];

    
    ////save the data
    const saveData = () => {      
        
        //add
        if (validateForm()) {

            postApiData(`${ApiRoot}/${country}/${ApiPaths.AutoPricingExceptions}`, 'POST', data, onSave, onError);
        }
      
    }
    
    const onCriteriaError = (msg) => {

    }

    const onDataError = (msg) => {

    }

    const onRSError = (msg) => {

    }

    const onRulesError = (msg) => {

    }

    const handleChangeRules = (e, valueType) => {
        const name = e.target.name;
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

        if (valueType) {
            if (valueType === 'number') {
                value = Number(value);
            }
        }


        switch (name) {
            case "ruleSpec":
                setRuleSpec(value);
                break;
            case "criteria":
                setCriteria(value);
                break;
            case "values1":
                setValues1(value);
                break;
            case "values2":
                setValues2(value);
                break;
        }
       

    }

    //after rule saved reload rules and clear fields
    const onRuleSaved = () => {

        setRuleSpec('');
        setCriteria('');
        setValues1('');
        setValues2('');
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.AutoPricingExceptions}/${id}/rules`, setRules, setIsRulesLoading, onRulesError);
       
    }

    //on add rule
    const addRule = () => {
        //validate if required rule values are empty
        if (ruleSpec === '' || criteria === '' || values1 === '' || (criteria === 'Between' && values2 === '')) {
            onError(' Please select the values for Spec, Criteria' + ((criteria === 'Between' && values2 === '') ? ', Value 1 and Value 2' : ' and Value 1'));
        }
        else {
            postApiData(`${ApiRoot}/${country}/${ApiPaths.AutoPricingExceptions}/rule`, 'POST', { exceptionId: id, spec: ruleSpec, criteria: criteria, values1: values1, values2: values2 }, onRuleSaved, onError);
        }
    }

    //on delete rule
    const onDelRule = (rid) => {
        console.log(rid);

        postApiData(`${ApiRoot}/${country}/${ApiPaths.AutoPricingExceptions}/${id}/rule`, 'DELETE', { ruleId: Number(rid), exceptionId: id, spec: ruleSpec, criteria: criteria, values1: values1, values2: values2 }, onRuleSaved, onError);

    }

    //when criteria select changes
    const changeCriteria = (e) => {
        if (e.target.value.toUpperCase() === 'BETWEEN') {
            setShowValue2(true);
        }
        else {
            setShowValue2(false);
        }

        handleChangeRules(e);
    }


    const onRuleSpecs = (d) => {

        if (Array.isArray(d)) {
            d.unshift({specId:0, specName:''});
        }

        setRuleSpecs(d);
    }

    const onCriterias = (d) => {

        if (Array.isArray(d)) {
            d.unshift({ criteriaId: 0, criteria: '' });
        }

        setCriterias(d);
    }

    useEffect(() => {

        //fetch dropdowns
        //get rule specs
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.RuleSpecs}`, onRuleSpecs, setIsRSLoading, onRSError);
       //criteria
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.RuleCriteria}`, onCriterias, setIsCriteriaLoading, onCriteriaError);
        
        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
            setIsRulesLoading(false);
        }
        else {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.AutoPricingExceptions}/${id}`, setData, setIsLoading, onDataError);  
            //rules
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.AutoPricingExceptions}/${id}/rules`, setRules, setIsRulesLoading, onRulesError);

        }           


    }, []);

       


    return (
        <Fragment>
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} Exception</DialogTitle>
            <DialogContent>
                {isLoading || isRulesLoading || isRSLoading || isCriteriaLoading ? <LinearProgress /> :
                    <Form>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    label="Exception Name"
                                    size="small"
                                    name="name"
                                    variant="outlined"
                                    fullWidth
                                    value={data?.name || ''}
                                    required
                                    onChange={handleChange}
                                    error={!!formError.name}
                                    helperText={formError.name || ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxControl value={!!data?.enabled} name="enabled" label="Enabled" handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h2">
                                    Rules
                             </Typography>
                            </Grid>
                            {id === 0 ?
                                <Typography variant="body1" >
                                    Please save  the new Auto Pricing Exception first, and then open it again to add rules to it.
                             </Typography> :
                                <Grid item xs={12}>
                                    <Paper className={classes.panel} variant="outlined" >
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <SelectControl
                                                    label="Spec"
                                                    id="specName"
                                                    options={ruleSpecs}
                                                    
                                                    textValue="specName"
                                                    value={ruleSpec || ''}
                                                    name="ruleSpec"
                                                    handleChange={handleChangeRules}

                                                />

                                            </Grid>
                                            <Grid item xs>
                                                <SelectControl
                                                    label="Criteria"
                                                    id="criteria"
                                                    options={criterias}
                                                    textValue="criteria"
                                                    value={criteria || ''}
                                                    name="criteria"
                                                    handleChange={changeCriteria}

                                                />

                                            </Grid>
                                            <Grid item xs={3}>

                                                <TextField
                                                    label="Value 1"
                                                    size="small"
                                                    name="values1"
                                                    variant="outlined"
                                                    value={values1 || ''}
                                                    onChange={handleChangeRules}

                                                />

                                            </Grid>
                                            <Grid item xs>
                                                {showValue2 && <TextField
                                                    label="Value 2"
                                                    size="small"
                                                    name="values2"
                                                    variant="outlined"
                                                    value={values2 || ''}
                                                    onChange={handleChangeRules}
                                                />}

                                            </Grid>
                                            <Grid item xs>
                                                <Button onClick={addRule} variant="contained" className={classes.listButton} color="primary">
                                                    Add
                                    </Button>
                                            </Grid>

                                        </Grid>

                                    </Paper>

                                </Grid>
                            }

                            <Grid item xs={12}>
                                <TableGrid rows={rules} columns={columns} keycol="ruleId" />
                            </Grid>
                        </Grid>
                    </Form>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
          </Button>
                <Button onClick={saveData} color="primary">
                    Save
          </Button>
            </DialogActions>

        </Fragment> 
    );
 
}
