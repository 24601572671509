import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,  Typography,  Paper,  Select, MenuItem, Button, LinearProgress, Grid, TextField, Table, TableBody, TableRow, TableCell, TableContainer, FormControl, InputLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../core/PathConstants';
import { fetchApiData, postApiData } from '../../core/FetchData';
import AlertMessage from '../../core/AlertMessage';
import { TableGrid } from '../../core/TableGrid';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport  } from '@mui/x-data-grid';

export const AutoManualReport = function ({ country }) {

    const [reportType, setReportType] = React.useState(1);

    const handleRTChange = (event) => {
        setReportType(event.target.value);
    };

    const [priceUser, setPriceUser] = useState('');

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const [startDate, setStartDate] = useState(addDays(new Date(), -1));

    const [endDate, setEndDate] = useState(Date.now());


    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [specs, setSpecs] = useState([]);
    const [isSpecsLoading, setIsSpecsLoading] = useState(false);

    const [openConfirm, setConfirmOpen] = React.useState(false);
   

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');

    const [vehicleId, setVehicleId] = useState(null);

 


    const columns = [
        {
            field: 'vehicleId', headerName: 'Vehicle Id', width: 90,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },
        {
            field: 'samClassCode', headerName: 'SAM Class', width: 90,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },
        {
            field: 'mfg',  headerName: 'Mfg', width: 60,
            disableColumnMenu: true,
            filterable: false,
            //hideSortIcons: true,
        },
        {
            field: 'model',  headerName: 'Model', width: 200,
            disableColumnMenu: true,
            filterable: false,
            //hideSortIcons: true,
        },
        {
            field: 'engine', headerName: 'Engine', width: 100,
            disableColumnMenu: true,
            filterable: false,
            //hideSortIcons: true,
        },
        {
            field: 'submitDate', headerName: 'Submit Date', width: 120,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },
        {
            field: 'priceDate', headerName: 'Price Date', width: 100,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },
        {
            field: 'priceByUser', headerName: 'Priced By User', width: 150,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },       
        {
            field: 'price', headerName: 'Price', width: 80,
            align: 'right',
            type: 'number',
            filterable: false,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },
        {
            field: 'manualPriceReason', headerName: 'Manual Price Reason', flex:1,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },
    ];

       

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }

   
    const onError = (errMsg) => {
        showAlert(errMsg, 'error');
    };

    const onDelComplete = () => {
        showAlert('Accepted Vehicle deleted', 'success');
        setVehicleId(null);
        setData(null);
        setSpecs([]);
    };

       


   
    const onData = (d) => {
        console.log(d);
        if (Array.isArray(d)) {
            let newd = [];
            for (let i = 0; i < d.length; i++) {
                newd.push({ ...d[i], id: i });
            }
             setData(newd);
        }
       
    }

    //fetch data
    const fetchData = () => {
        setIsLoading(true);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.AutoManualReport}/${getFormattedDate(startDate)}/${getFormattedDate(endDate)}/${reportType}/${priceUser?priceUser:null}`, onData, setIsLoading, onError);

    }

   
    function getFormattedDate(d) {

        if (!d) return '';

        let date = new Date(d);

        let year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '-' + day + '-' + year;
        
    }

    const handlePriceUserChange = (e) => {
        const value = e.target.value;
        setPriceUser(value);
    }

    function ReportToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                 <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Auto / Manually Priced Vehicles Report</Typography>            
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={2} >
                    <Grid item xs={2}>
                        <FormControl sx={{ minWidth: 120 }} size="small">
                            <InputLabel id="report-type-select-label">Report Type</InputLabel>
                            <Select
                                labelId="report-type-select-label"
                                id="report-type-select"
                                value={reportType}
                                label="Report Type"
                                onChange={handleRTChange}
                            >
                                <MenuItem value={1}>Auto-Priced</MenuItem>
                                <MenuItem value={0}>Manually</MenuItem>                               
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker 
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => {
                                    setStartDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} size="small" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue) => {
                                    setEndDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} size="small" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={priceUser} label="Priced By User" size="small" name="priceUser" onChange={handlePriceUserChange} />
                    </Grid>
                    <Grid item alignItems="center">
                        <Button onClick={fetchData} color="primary" variant="contained" endIcon={<SearchIcon />}>Run</Button>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{mt:1}}>
                {(isLoading) ? <LinearProgress /> :
                    <Fragment>

                        <div style={{ height: 640, width: '100%' }}>
                            <DataGrid 
                                rows={data} columns={columns} components={{ Toolbar: ReportToolbar }} />
                           
                        </div>
                    </Fragment>
                }
            </Paper>
         
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div> 
    );
 
}
