import React, { useRef } from 'react';
import { Button, Typography } from '@mui/material';
import { FileUploadOutlined } from '@mui/icons-material';
import { read, utils } from 'xlsx';
import { postApiData } from '../../../../core/FetchData';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';

const parseJson = (obj) => {
  try {
    return JSON.parse(obj);
  } catch (error) {
    return obj;
  }
}

const UploadPromotions = ({ country, setIsLoading, showAlert }) => {
  const fileInputRef = useRef(null);

  const clearComponent = () => {
    // Clear the file input
    fileInputRef.current.value = null;
    setIsLoading(false);
  }

  const isNumeric = (value) => !isNaN(Number(value));

  const onUploadComplete = (message, type) => {
    clearComponent();
    showAlert(message, type)
  }

  const onUploadFailed = (ex) => {
    const { errorId, duplicateKey } = parseJson(ex);
    const message = errorId?.includes('Duplicate Key') 
      ? `Found duplicate Vehicle Number${` ${duplicateKey}` ?? ''}. Please ensure there are no duplicate Vehicle Numbers in the file!`
      : 'Process failed while uploading the file!';

    clearComponent();
    showAlert(message);
  }

  const onUpload = (event) => {
    event.preventDefault();

    const file = event?.target?.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const data = event.target.result;
          const workbook = read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[1];
          const worksheet = workbook.Sheets[sheetName];
          const fileJson = utils.sheet_to_json(worksheet);

          // Extracting Data from excel...
          if (fileJson.length > 0) {
            const fileData = fileJson
              .filter(item => (
                item['Country']?.toLowerCase()?.startsWith(country) &&
                isNumeric(item['Veh#']) &&
                isNumeric(item['Price'])
              ))
              .map(item => ({
                vehicleId: item['Veh#'],
                specialPrice: item['Price'],
                country: item['Country'],
                promotionKey: item['CampaignName'],
                promotionKeySpUs: item['CampaignName2ndLanguage'],
                promotionKeyFrCa: '', // This is going to be empty until the Excel is changed
              }))
              .sort((a, b) => a.vehicleId - b.vehicleId);

            if (fileData?.length === 0 || fileData.every(item => !item.vehicleId)) {
              throw new Error('Wrong File Uploaded. Validate that the file complies with the description listed below!');
            }

            postApiData(`${ApiRoot}/${country}/${ApiPaths.Promo}/batch`,
              null,
              fileData,
              () => onUploadComplete('The file was uploaded successfully!', 'success'),
              (ex) => onUploadFailed(ex)
            );
          }
          else {
            throw new Error('Wrong File Uploaded. Validate that the file complies with the description listed below!');
          }
        } catch (error) {
          showAlert(error.message);
          clearComponent();
        }
      };

      reader.readAsArrayBuffer(file);
      setIsLoading(true);
    }
  }

  return (
    <>
      <Typography variant="caption" component="div" sx={{ pt: 2, px: 2 }}>
        Please select an Excel spreadsheet file with the following formats:
        <ul>
          <li>Accepted Formats: <b>XLSX (.xlsx)</b> or <b>XLS (.xls)</b> </li>
          <li>Headers must contain: <b>Veh#, Price, Country, CampaignName, CampaignName2ndLanguage</b></li>
          <li>Only records corresponding to the selected Country will be uploaded (i.e., <b>United States (US)</b> or <b>Canada (CA)</b>).</li>
          <li><b>Veh#</b> and <b>Price</b> must be numeric values.</li>
        </ul>
      </Typography>

      <div variant='outlined'>
        <Button variant='contained' sx={{ m: 1 }} component='label' color='primary' size='small' endIcon={<FileUploadOutlined />}>
          Upload File
          <input
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            type='file'
            id='fileInput'
            hidden
            onChange={onUpload}
            ref={fileInputRef}
          />
        </Button>
      </div>
    </>
  );
}

export default UploadPromotions;
