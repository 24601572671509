import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions, TextField, Button, LinearProgress, Grid } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { useForm, Form } from '../../../../core/useForm';


const initialValues = { id:0, vehicleId:null, desc: '', price: null};

const fieldsValidation = {
    desc: {
        error: "",
        validate: "",
        minLength: 0,
        maxLength: 50
    },
    vehicleId: {
        error: "Invalid Vehicle Id",
        validate: "number",
        minLength: 5,
        maxLength: 7,
        required: true,
       
    },
    price: {
        error: "Invalid Price",
        validate: "number",
        minLength: 1,
        maxLength: 15
    },


}

export const FixedPricingForm = ({ id, onSave, onCancel, onError , country, isTemp }) => {

    const [formErrors, setFormErrors] = React.useState({});

    const [data, setData, handleChange, validateForm] = useForm(initialValues, fieldsValidation, setFormErrors);

    const [isLoading, setIsLoading] = useState(true);


   

    //save the data
    const saveData = () => {      
        
        //add
        if (validateForm()) {

            let newData = { ...data };

            newData.price = Number(data.price);
            newData.vehicleId = Number(data.vehicleId);
            if(isTemp)
                postApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricingTemp}`, 'POST', newData, onSave, onError);
            else
                postApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricing}`, 'POST', newData, onSave, onError);
            

        }
      
    }


    const onDataError = (msg) => {
        onError(msg);
    }

    const onData = (d) => {
        console.log(d);
        setData(d);
    }
    
    useEffect(() => {

               
        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
           
        }
        else {
            if(isTemp)
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricingTemp}/${id}`, onData, setIsLoading, onDataError);
            else
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FixedPricing}/${id}`, onData, setIsLoading, onDataError);
            


        }

    }, []);

      
     


    return (
        <Fragment>            
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} {isTemp ? 'NEW' : ''} Fixed Price</DialogTitle>
            <DialogContent>
                {isLoading ? <LinearProgress /> :
                    <Form>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Vehicle Id"
                                    size="small"
                                    name="vehicleId"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.vehicleId || ''}
                                    required
                                    error={!!formErrors.vehicleId}
                                    helperText={formErrors.vehicleId}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Description"
                                    size="small"
                                    name="desc"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.desc || ''}
                                    
                                    error={!!formErrors.desc}
                                    helperText={formErrors.desc}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Fixed Price"
                                    size="small"
                                    name="price"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.price || ''}
                                    
                                    error={!!formErrors.price}
                                    helperText={formErrors.price}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                }
                </DialogContent>
            <DialogActions>                
                <Button onClick={saveData} color="primary">Save</Button>
                <Button onClick={onCancel} color="primary">Close</Button>
            </DialogActions>
          
        </Fragment> 
    );
 
}
