import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNewPromoContext } from './NewPromoContextProvider';
import { Body, Footer, Header, PromoLayout } from './PromoLayout';
import { Divider, Grid, Typography, TextField, useMediaQuery, useTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ApiPaths } from '../../../../core/PathConstants';
import { requests } from '../../../../core/ApiService';
import ConfirmationPopup from '../../../../core/ConfirmPopup';
import AgingBucketDetailsList from './AgingBucketDetailsList';
import SelectableList from './SelectableList.js';
import { isEqual } from '../../../../core/utils.js';
import dayjs from "dayjs";

const SetPricingModal = ({ country, open, onClose }) => {
    const [agingBuckets, setAgingBuckets] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedAgingBucket, setSelectedAgingBucket] = useState({});
    const [selectedManufacturers, setSelectedManufacturers] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedSamClasses, setSelectedSamClasses] = useState([]);
    const { setLoading, showAlert, promotion, staticData } = useNewPromoContext();

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
    const campaignName = country.startsWith('us') ? 'Promotion Name Spanish' : 'Promotion Name French'
    const labelAlignment = isSmScreen ? 'left' : 'right';

    const initializeSelections = useCallback((agingBucket) => {
        const { manufacturers, locations, samClasses } = staticData;

        const initializedManufacturers = [
            {
                code: 'ALL',
                displayText: 'ALL',
                isChecked: agingBucket.allManufacturers
            },
            ...manufacturers?.map(manufacturer => ({
                code: manufacturer.makeCode,
                displayText: manufacturer.makeCode,
                isChecked: agingBucket.manufacturers?.split(/,\s*/).includes(manufacturer.makeCode) || false
            }))
        ];

        const initializedLocations = [
            {
                code: 'ALL',
                displayText: 'ALL',
                isChecked: agingBucket.allLocations
            },
            ...locations?.map(location => ({
                code: location.locationCode,
                displayText: `${location.locationName} (${location.locationCode})`,
                isChecked: agingBucket.allLocations ? false : agingBucket.locations?.split(/,\s*/).includes(location.locationCode.toString()) || false
            }))
        ];

        const initializedSamClasses = [
            {
                code: 'ALL',
                displayText: 'ALL',
                isChecked: agingBucket.allClasses
            },
            ...samClasses?.map(samClass => ({
                code: samClass.samClassCode,
                displayText: samClass.samClassName,
                isChecked: agingBucket.vehicleClasses?.split(/,\s*/).includes(samClass.samClassCode.toString()) || false
            }))
        ];

        // Set the state for manufacturers, locations and SAM classes
        setSelectedManufacturers(initializedManufacturers);
        setSelectedLocations(initializedLocations);
        setSelectedSamClasses(initializedSamClasses);

        // Update the selected aging bucket state here
        setSelectedAgingBucket(agingBucket);
    }, [staticData]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data } = await requests.get(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/aging-buckets`);

                // Validate there are aging buckets to set the Price to
                if (data?.length <= 0) {
                    showAlert(`No Aging Buckets found for ${promotion.promotionKey}. Please add Aging Buckets first!`, 'error');
                    onClose(true);
                }
                else {
                    setAgingBuckets(data);
                    initializeSelections(data[0] || {});
                }
            } catch (err) {
                showAlert(`Process failed while retrieving aging buckets for promotion ${promotion.promotionKey}!`, 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [country, initializeSelections, onClose, promotion.promotionId, promotion.promotionKey, setLoading, showAlert]);

    const handleSave = async (saveForLater) => {
        setLoading(true);

        // Update agingBuckets with current selectedAgingBucket changes before saving
        const updatedBuckets = agingBuckets.map(bucket =>
            bucket.agingBucketId === selectedAgingBucket.agingBucketId ? selectedAgingBucket : bucket
        );

        try {
            await requests.post(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/aging-buckets`, updatedBuckets);

            showAlert('Pricing parameters successfully set!', 'success');
            onClose(saveForLater ? 'cancel' : '');
        } catch ({ data }) {
            showAlert(data?.description || `Process failed while saving Aging Bucket!`, 'error');
        }
        finally {
            setLoading(false);
        }
    };

    const handleConfirm = (isCancel) => {
        setConfirmOpen(false);
        if (isCancel) {
            onClose('cancel');
        }
    };

    const handleAgingBucketSelect = (updatedBuckets) => {
        const newSelectedBucket = updatedBuckets.find(bucket => bucket.isChecked);
        if (newSelectedBucket) {
            initializeSelections(newSelectedBucket);

            setAgingBuckets(prevBuckets => {
                const bucketToUpdate = prevBuckets.find(bucket => bucket.agingBucketId === selectedAgingBucket.agingBucketId);

                if (bucketToUpdate && !isEqual(bucketToUpdate, selectedAgingBucket)) {
                    return prevBuckets.map(bucket =>
                        bucket.agingBucketId === selectedAgingBucket.agingBucketId ? selectedAgingBucket : bucket
                    );
                }

                return prevBuckets; // No changes needed
            });
        }
    };

    const handleDiscountTypeSelect = (updatedDiscountTypes) => {
        const selectedType = updatedDiscountTypes.find(type => type.isChecked);

        if (selectedType) {
            setSelectedAgingBucket(prevState => ({
                ...prevState,
                discountTypeId: selectedType.discountTypeId,
                discountTypeDescription: selectedType.typeDescription,
                discount: null,
                percentDiscount: null
            }));
        }
    };

    const handleManufacturerSelect = useCallback((updatedManufacturers) => {
        setSelectedManufacturers(updatedManufacturers);

        const allManufacturers = updatedManufacturers.find(manufacturer => manufacturer.code === 'ALL');

        const updatedManufacturersString = allManufacturers.isChecked ? '' : updatedManufacturers
            .filter(manufacturer => manufacturer.code !== 'ALL' && manufacturer.isChecked)
            .map(manufacturer => manufacturer.code)
            .join(',');

        setSelectedAgingBucket(prevBucket => ({
            ...prevBucket,
            allManufacturers: allManufacturers.isChecked,
            manufacturers: updatedManufacturersString
        }));
    }, []);

    const handleLocationSelect = useCallback((updatedLocations) => {
        setSelectedLocations(updatedLocations);

        const allLocations = updatedLocations.find(loc => loc.code === 'ALL');

        const updatedLocationsString = allLocations.isChecked ? '' : updatedLocations
            .filter(location => location.code !== 'ALL' && location.isChecked)
            .map(location => location.code)
            .join(',');

        setSelectedAgingBucket(prevBucket => ({
            ...prevBucket,
            allLocations: allLocations.isChecked,
            locations: updatedLocationsString
        }));
    }, []);


    const handleSamClassSelect = useCallback((updatedSamClasses) => {
        setSelectedSamClasses(updatedSamClasses);

        const allClasses = updatedSamClasses.find(samClass => samClass.code === 'ALL');

        const updatedSamClassesString = allClasses.isChecked ? '' : updatedSamClasses
            .filter(samClass => samClass.code !== 'ALL' && samClass.isChecked)
            .map(samClass => samClass.code)
            .join(',');

        setSelectedAgingBucket(prevBucket => ({
            ...prevBucket,
            allClasses: allClasses.isChecked,
            vehicleClasses: updatedSamClassesString
        }));
    }, []);

    const setBucketDetails = useCallback((bucketDetails) => {
        setSelectedAgingBucket(prevBucket => ({
            ...prevBucket,
            ...bucketDetails
        }));
    }, []);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="l" fullWidth>
            <PromoLayout>
                <Header>
                    <DialogTitle style={{ textAlign: 'center' }}>Set Pricing Parameters for <b>{promotion.promotionKey}</b></DialogTitle>
                </Header>
                <Body>
                    <DialogContent>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={10}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <Typography variant="subtitle1" align={labelAlignment}>Promotion Name</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                size="small"
                                                name="promotionKey"
                                                variant="outlined"
                                                value={promotion.promotionKey || ''}
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Typography variant="subtitle1" align={labelAlignment}>Start Date</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                size="small"
                                                value={dayjs(promotion.startDateTime).format('MM/DD/YYYY hh:mm A')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Typography variant="subtitle1" align={labelAlignment}>{campaignName}</Typography>
                                        </Grid>

                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                size="small"
                                                name={country.startsWith('us') ? promotion.promotionKeySpUs : promotion.promotionKeyFrCa}
                                                variant="outlined"
                                                value={country.startsWith('us') ? promotion.promotionKeySpUs || '' : promotion.promotionKeyFrCa || ''}
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Typography variant="subtitle1" align={labelAlignment}>End Date</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                size="small"
                                                value={dayjs(promotion.endDateTime).format('MM/DD/YYYY hh:mm A')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <SelectableList
                                        title="Aging Buckets"
                                        items={agingBuckets.map(bucket => ({
                                            ...bucket,
                                            code: bucket.agingBucketId,
                                            displayText: `${bucket.minDays} - ${bucket.maxDays}`,
                                            isChecked: selectedAgingBucket.agingBucketId === bucket.agingBucketId
                                        }))}
                                        onItemSelect={handleAgingBucketSelect}
                                    />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </DialogContent>
                    {selectedAgingBucket.agingBucketId && (
                        <>
                            <Divider />
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <AgingBucketDetailsList bucketDetails={selectedAgingBucket} setBucketDetails={setBucketDetails} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <SelectableList
                                                    title="Discount Type"
                                                    items={staticData.discountTypes.map(type => ({
                                                        ...type,
                                                        code: type.discountTypeId,
                                                        displayText: type.typeDescription,
                                                        isChecked: selectedAgingBucket.discountTypeId === type.discountTypeId
                                                    }))}
                                                    onItemSelect={handleDiscountTypeSelect}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SelectableList
                                                    title="Manufacturers"
                                                    multiselect
                                                    items={selectedManufacturers}
                                                    onItemSelect={handleManufacturerSelect}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SelectableList
                                                    title="Locations"
                                                    multiselect
                                                    items={selectedLocations}
                                                    onItemSelect={handleLocationSelect}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SelectableList
                                                    title="SAM Classes"
                                                    multiselect
                                                    items={selectedSamClasses}
                                                    onItemSelect={handleSamClassSelect}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </>
                    )}
                    <ConfirmationPopup
                        open={confirmOpen}
                        onClose={() => handleConfirm()}
                        onConfirm={() => handleConfirm(true)}
                        message="Are you sure you want to cancel all modifications? Data will not be saved!"
                    />
                </Body>
                <Footer>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={onClose}>Back</Button>
                            <Button variant='contained' color='inherit' onClick={() => setConfirmOpen(true)}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={() => handleSave(true)}>Save for later</Button>
                            <Button variant='contained' color='inherit' onClick={() => handleSave()}>Save and Continue</Button>
                        </Grid>
                    </Grid>
                </Footer>
            </PromoLayout>
        </Dialog>
    );
};

export default SetPricingModal;
