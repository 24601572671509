import React, { useState, useEffect, useCallback } from 'react';
import { Toolbar, Typography, ButtonBase, Container } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';

import { UserContext } from './UserContext'
import LoginMenu from './LoginMenu';
import './Layout.css';
import { bearerToken, fetchApiData } from './FetchData';
import { ApiPaths, ApiRoot } from './PathConstants';
import NavMenu from '../components/NavMenu';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import { useOktaAuth } from '@okta/okta-react';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, country }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        overflowX: 'hidden',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        // flexGrow: 1,
    },
    title: { //
        flexGrow: 1,
    },
    logoButton: { //set default height of logo button for compatibility with IE11
        height: 45,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
}));


export const Layout = ({ children, country }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [user, setUser] = useState({});
    const location = useLocation();

    const { oktaAuth, authState } = useOktaAuth();

    const logoClass0 = 'logo-light0';//theme.palette.primary.main == '#E13A3E' ? 'logo-light0' : 'logo-dark0'
    const logoClass1 = 'logo-light1';//theme.palette.primary.main == '#E13A3E' ? 'logo-light1' : 'logo-dark1'
    const logoClass2 = 'logo-light2'; //theme.palette.primary.main == '#E13A3E' ? 'logo-light2' : 'logo-dark2'

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    const onUserDataError = (err) => {
        console.log(err);
    }
    //useEffect(() => {
    //    console.log(startUp);
    //    if (!startUp)
    //        fetchApiData(`${ApiRoot}/${ApiPaths.UserApi}/details`, onUserData, null, onUserDataError);

    //}, [startUp]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isTokenActive = useCallback(() => {
        const storedToken = oktaAuth.getAccessToken();

        if (!storedToken) {
            return false;
        }

        try {
            const decodedToken = JSON.parse(atob(storedToken.split('.')[1]));
            return decodedToken.exp > Date.now() / 1000;
        } catch (e) {
            console.error('Token validation error:', e);
            return false;
        }
    }, [oktaAuth]);

    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');

        // Check if user info is stored in localStorage and if the token is active
        if (storedUserInfo && isTokenActive()) {
            // Retrieve the token from OktaAuth and store it
            bearerToken.token = oktaAuth.getAccessToken();

            // Set user from stored data
            const userInfo = JSON.parse(storedUserInfo);
            setUser(userInfo);
        } else if (authState && authState.isAuthenticated) {
            oktaAuth.getUser().then(info => {
                console.log(info);

                // Retrieve the token from OktaAuth and store it
                bearerToken.token = oktaAuth.getAccessToken();

                // Decode the token to get additional info like groups
                let tokenpl = oktaAuth.token.decode(bearerToken.token);
                let groups = tokenpl?.payload?.group;
                console.log(groups);

                // Update the user state with the fetched info
                const userInfo = { user: info, groups, details: null };
                setUser(userInfo);

                // Store the complete user info and token in localStorage
                localStorage.setItem('userInfo', JSON.stringify(userInfo));

                // Fetch additional user details
                fetchApiData(`${ApiRoot}/${ApiPaths.UserApi}/details`, (d) => {
                    // Update the user state with the fetched info
                    const updatedUser = { ...userInfo, details: d };
                    setUser(updatedUser);

                    // Store the complete user info and token in localStorage
                    localStorage.setItem('userInfo', JSON.stringify(updatedUser));
                    console.log(d);
                }, null, onUserDataError);
            });
        } else {
            // Clear user info from localStorage
            localStorage.removeItem('userInfo');
            setUser(null);
        }
    }, [authState, isTokenActive, oktaAuth]);


    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    {country &&
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                    }

                    <Typography variant="h6" className={classes.title}>
                        <ButtonBase component={Link} className={classes.logoButton} to='/' >
                            <svg xmlns="http://www.w3.org/2000/svg" className="view-size" viewBox="0 0 155 38" tabIndex="-1" focusable="false">
                                {/*Ryder*/}
                                <path className={logoClass0} d="M48.3 9.4c1.7 0 3.1-0.6 3.4-2.3 0.4-1.7-0.7-2-2.5-2h-0.8l-0.9 4.3H48.3M40.8 0h13.3c4.8 0 7.3 1.5 6.5 5.7 -0.6 2.6-2.1 4.5-5.6 5.4v0.1c2.2 0.2 3.1 1.1 3.2 2.9 0.1 1.9-0.7 5.1-0.4 8.5h-8.7c-0.2-1.7 0.2-4.5 0.3-6.4 0.1-1.1-0.4-1.6-1.9-1.6h-1l-1.7 8h-8.7L40.8 0z"></path>
                                <polygon className={logoClass0} points="62.9 23 60 6.9 67.9 6.9 68.3 15.5 68.4 15.5 72.3 6.9 79.8 6.9 67.8 28.5 59.6 28.5 "></polygon>
                                <path className={logoClass0} d="M89 12.2c0-0.9-0.5-1.4-1.3-1.4 -1.6 0-2.1 1.4-2.6 3.9 -0.5 2.1-0.6 3.9 1 3.9 0.8 0 1.3-0.4 1.6-0.8 0.3-0.5 0.6-1.4 0.9-3C88.9 13.3 88.9 12.9 89 12.2M87.6 20.7C85.9 22.4 84 23 82.3 23c-3.8 0-5.9-2.9-4.7-8.5 1.3-5.9 4.7-8.1 8.1-8.1 1.7 0 3.3 0.5 4.2 1.9L91.7 0h7.5l-4.8 22.5h-7.1L87.6 20.7z"></path>
                                <path className={logoClass0} d="M108.5 12.7l0-0.2c0.3-1.5-0.3-2.3-1.5-2.3 -1.2 0-2.2 0.8-2.5 2.3l0 0.2H108.5M114.2 17.5c-1.3 3.6-4.4 5.5-10.3 5.5 -4.6 0-8.3-2.1-6.9-8.4 1.1-5.4 5.4-8.2 11-8.2 5.9 0 8.1 3 6.9 8.6l-0.2 1h-10.8l-0.1 0.5c-0.4 1.8 0.1 2.6 1.6 2.6 0.8 0 1.8-0.5 2.2-1.6L114.2 17.5 114.2 17.5z"></path>
                                <path className={logoClass0} d="M117.8 6.9h7.3L125 8.6c1.4-1.4 2.5-1.9 3.5-1.9 0.6 0 1.3 0.1 1.9 0.4l-1.2 5.7c-0.8-0.2-1.7-0.4-3.1-0.4 -1.4 0-2.1 1-2.4 2l-1.7 8.2h-7.5L117.8 6.9"></path>
                                {/*Arrow*/}
                                <path className={logoClass2} d="M36 1c-5.4 0.7-10.4 2-14.6 3.7H0l33.5 8 0.3-1.3L19.1 8c4-2.6 9.8-4.7 16.7-6L36 1"></path>
                                <path className={logoClass2} d="M65.9 31.6L64 35.5c-5.5 1.4-11.7 2.2-18.3 2.2 -21.3 0-38.5-8.2-38.5-18.3 0-3.5 2.1-6.9 5.7-9.8l3.2 0.8c-1.9 1.9-2.9 3.9-2.9 6 0 8.9 17.8 16 39.8 16 4.7 0 9.1-0.3 13.3-0.9"></path>

                                <path className={logoClass0} d="M134.3 8.3c0.3 0 0.8 0.1 0.8-0.4 0-0.3-0.3-0.4-0.6-0.4h-0.6v0.8H134.3M135.4 9.5H135l-0.6-1h-0.5v1h-0.3V7.3h0.9c0.2 0 0.4 0 0.5 0.1s0.3 0.3 0.3 0.5c0 0.4-0.3 0.6-0.7 0.6L135.4 9.5zM136.1 8.4c0-0.9-0.8-1.7-1.7-1.7 -0.9 0-1.7 0.8-1.7 1.7s0.8 1.7 1.7 1.7C135.3 10.2 136.1 9.4 136.1 8.4M132.4 8.4c0-1.1 0.9-2 2-2s2 0.9 2 2 -0.9 2-2 2S132.4 9.5 132.4 8.4"></path>
                                <path className={logoClass0} d="M134.3 8.3c0.3 0 0.8 0.1 0.8-0.4 0-0.3-0.3-0.4-0.6-0.4h-0.6v0.8C133.9 8.3 134.3 8.3 134.3 8.3zM135.4 9.5H135l-0.6-1h-0.5v1h-0.3V7.3h0.9c0.2 0 0.4 0 0.5 0.1s0.3 0.3 0.3 0.5c0 0.4-0.3 0.6-0.7 0.6L135.4 9.5zM136.1 8.4c0-0.9-0.8-1.7-1.7-1.7 -0.9 0-1.7 0.8-1.7 1.7s0.8 1.7 1.7 1.7C135.3 10.2 136.1 9.4 136.1 8.4M132.4 8.4c0-1.1 0.9-2 2-2s2 0.9 2 2 -0.9 2-2 2S132.4 9.5 132.4 8.4"></path>
                                <path className={logoClass1} d="M134.3 8.3c0.3 0 0.8 0.1 0.8-0.4 0-0.3-0.3-0.4-0.6-0.4h-0.6v0.8C133.9 8.3 134.3 8.3 134.3 8.3zM135.4 9.5H135l-0.6-1h-0.5v1h-0.3V7.3h0.9c0.2 0 0.4 0 0.5 0.1s0.3 0.3 0.3 0.5c0 0.4-0.3 0.6-0.7 0.6L135.4 9.5zM136.1 8.4c0-0.9-0.8-1.7-1.7-1.7 -0.9 0-1.7 0.8-1.7 1.7s0.8 1.7 1.7 1.7C135.3 10.2 136.1 9.4 136.1 8.4zM132.4 8.4c0-1.1 0.9-2 2-2s2 0.9 2 2 -0.9 2-2 2S132.4 9.5 132.4 8.4z"></path>
                                {/*Ever Better*/}
                                <polygon className={logoClass2} points="75.9 25.9 82.9 25.9 82.4 28.5 78.5 28.5 78 30.4 81.7 30.4 81.2 32.9 77.5 32.9 77 35.2 81.1 35.2 80.6 37.7 73.4 37.7 "></polygon>
                                <polygon className={logoClass2} points="82.8 28.8 85.8 28.8 85.3 35.2 85.4 35.2 87.6 28.8 90.6 28.8 86.6 37.7 83 37.7 "></polygon>
                                <path className={logoClass2} d="M92.8 33.8L92.5 35c-0.2 0.9 0.1 1.2 0.6 1.2 0.5 0 0.9-0.3 1.2-1.6H97c-0.5 2.2-1.8 3.4-4.2 3.4 -2.9 0-3.5-1.3-2.8-4.7 0.7-3.4 2.1-4.7 4.8-4.7 2.4 0 3.4 1 2.7 4.3l-0.2 0.9C97.2 33.8 92.8 33.8 92.8 33.8zM94.9 31.8c0.2-0.9 0.1-1.4-0.5-1.4s-0.9 0.4-1.1 1.4l-0.1 0.4h1.6L94.9 31.8z"></path>
                                <path className={logoClass2} d="M99 28.8h2.9l-0.3 1.4h0c0.6-1.1 1.5-1.7 2.4-1.7h0.5l-0.6 2.8c-0.3-0.1-0.5-0.1-0.7-0.1 -1 0-1.8 0.4-2 1.6l-1 4.9h-3L99 28.8z"></path>
                                <path className={logoClass2} d="M109.9 25.9h3l-0.8 3.9h0c0.5-0.9 1.3-1.2 2.1-1.2 1.7 0 2.5 1 1.8 4.5 -0.7 3.3-1.7 4.9-3.7 4.9 -1 0-1.6-0.5-1.7-1.5h0l-0.3 1.2h-2.9L109.9 25.9zM111.6 35.8c0.7 0 1-0.6 1.4-2.5 0.4-1.9 0.4-2.5-0.3-2.5 -0.7 0-0.9 0.6-1.4 2.5C110.9 35.2 110.9 35.8 111.6 35.8"></path>
                                <path className={logoClass2} d="M119.5 33.8l-0.3 1.2c-0.2 0.9 0.1 1.2 0.6 1.2 0.5 0 0.9-0.3 1.2-1.6h2.7c-0.5 2.2-1.8 3.4-4.2 3.4 -2.9 0-3.5-1.3-2.8-4.7 0.7-3.4 2.1-4.7 4.8-4.7 2.4 0 3.4 1 2.7 4.3l-0.2 0.9C123.9 33.8 119.5 33.8 119.5 33.8zM121.6 31.8c0.2-0.9 0.1-1.4-0.5-1.4s-0.9 0.4-1.1 1.4l-0.1 0.4h1.6L121.6 31.8z"></path>
                                <path className={logoClass2} d="M125.2 28.8h1l0.6-2.6h3l-0.6 2.6h1.2l-0.4 2h-1.2l-0.9 4.3c-0.1 0.5 0.1 0.7 0.6 0.7h0.5l-0.4 2c-0.6 0.1-1.1 0.1-1.6 0.1 -1.8 0-2.5-0.3-2.1-2.3l1-4.8h-1L125.2 28.8z"></path>
                                <path className={logoClass2} d="M130.1 28.8h1.3l0.6-2.6h3l-0.6 2.6h1.2l-0.4 2H134l-0.9 4.3c-0.1 0.5 0.1 0.7 0.6 0.7h0.5l-0.4 2c-0.6 0.1-1.1 0.1-1.6 0.1 -1.8 0-2.5-0.3-2.1-2.3l1-4.8h-1.3L130.1 28.8z"></path>
                                <path className={logoClass2} d="M137.9 33.8l-0.3 1.2c-0.2 0.9 0.1 1.2 0.6 1.2 0.5 0 0.9-0.3 1.2-1.6h2.7c-0.5 2.2-1.8 3.4-4.2 3.4 -2.9 0-3.5-1.3-2.8-4.7 0.7-3.4 2.1-4.7 4.8-4.7 2.4 0 3.4 1 2.7 4.3l-0.2 0.9C142.3 33.8 137.9 33.8 137.9 33.8zM140 31.8c0.2-0.9 0.1-1.4-0.5-1.4s-0.9 0.4-1.1 1.4l-0.1 0.4h1.6L140 31.8z"></path>
                                <path className={logoClass2} d="M144.1 28.8h2.9l-0.3 1.4h0c0.6-1.1 1.5-1.7 2.4-1.7h0.5l-0.6 2.8c-0.3-0.1-0.5-0.1-0.7-0.1 -1 0-1.8 0.4-2 1.6l-1 4.9h-3L144.1 28.8z"></path>
                                <polygon className={logoClass2} points="147.5 34.5 150.2 34.5 149.5 37.7 146.8 37.7 "></polygon>
                                <path className={logoClass2} d="M152.5 27h-0.6v1.7h-0.3V27h-0.6v-0.3h1.5L152.5 27 152.5 27zM155 28.7h-0.3v-1.6l0 0 -0.7 1.6h-0.2l-0.7-1.6 0 0v1.6h-0.3v-2h0.5l0.6 1.5 0.6-1.5h0.5L155 28.7 155 28.7z"></path>
                            </svg>
                            <span className="app-title" >
                                UVS Pricing {(country === 'us') ? " - USA" : (country === 'ca') ? " - CANADA" : ""}
                            </span>
                        </ButtonBase>

                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {!location.pathname.includes('login/callback') &&
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <Tooltip title="USA">
                                <ButtonBase sx={{ mx: 1 }} component={Link} to='/us' aria-label="USA" color="inherit">
                                    {(country === 'us') ?
                                        <img src='/images/US_icon.png' alt="USA" />
                                        :
                                        <img src='/images/US_icon_inactive.png' alt="USA" style={{ opacity: 0.3 }} />
                                    }
                                </ButtonBase>
                            </Tooltip>
                            <Tooltip title="CANADA">
                                <ButtonBase sx={{ mx: 1 }} component={Link} to='/ca' aria-label="CANADA" color="inherit">
                                    {(country === 'ca') ?
                                        <img src='/images/CAN_icon.png' alt="Canada" />
                                        :
                                        <img src='/images/CAN_icon_inactive.png' alt="Canada" style={{ opacity: 0.3 }} />
                                    }
                                </ButtonBase>
                            </Tooltip>
                            <LoginMenu user={user} />

                        </Box>
                    }
                </Toolbar>
            </AppBar>
            {country &&
                <Drawer
                    variant="permanent"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <NavMenu user={user} open={mobileOpen} country={country} />
                </Drawer>
            }
            <div className={classes.content}>
                <div className={classes.toolbar} />
                <Container maxWidth="false">
                    <UserContext.Provider value={user}>
                        {children}
                    </UserContext.Provider>
                </Container>
            </div>

        </div>

    );

}
