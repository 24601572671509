
const RoutePrefix = '/(us|ca)';
export const ApplicationRoutes = {
    Home: `${RoutePrefix}`,
    AdminHome: `${RoutePrefix}/admin`,
    Specs: `${RoutePrefix}/admin/specs`,
    SpecsPricing: `${RoutePrefix}/specs-pricing/:vehicleId?/:tab?`,
    SpecsEdit: `${RoutePrefix}/specs-edit/:vehicleId?`,
    RePricing: `${RoutePrefix}/re-pricing/:vehicleId?`,
    AcceptVehiclePricing: `${RoutePrefix}/accept-vehicle-pricing/:vehicleId`,
    AcceptVehicle: `${RoutePrefix}/accept-vehicle/:vehicleId?/(category)?/:categoryId?`,
    AutoPricingExceptions: `${RoutePrefix}/admin/pricingex`,
    Discounts: `${RoutePrefix}/admin/discounts`,
    
    Promo: `${RoutePrefix}/admin/promo`,
    NewPromo: `${RoutePrefix}/admin/new-promo`,
    CreateNewPromo: `${RoutePrefix}/admin/new-promo/add`,
    SetUpPromo: `${RoutePrefix}/admin/new-promo/set-up/:promoName`,

    Categories: `${RoutePrefix}/admin/maint/cat`,
    Locations: `${RoutePrefix}/admin/maint/loc`,
    BulkPricing: `${RoutePrefix}/pricing/bulk`,

    ApprovalHome: `${RoutePrefix}/approvals`,
    PendingApprovals: `${RoutePrefix}/approvals/pending`,
    PendingFinalApprovals: `${RoutePrefix}/approvals/final`,

    DupVehicle: `${RoutePrefix}/dup/dupvehicle`,

    ReportsHome: `${RoutePrefix}/reports`,
    ReportsAutoManual: `${RoutePrefix}/reports/automanual`,
    ReportsUnadvertised: `${RoutePrefix}/reports/unadvertised`,

    PricingHome: `${RoutePrefix}/admin/pricing`,
    BasePrices: `${RoutePrefix}/admin/pricing/base`,
    PriceMatrix: `${RoutePrefix}/admin/pricing/matrix`,
    PriceAddDeduct: `${RoutePrefix}/admin/pricing/adddeduct`,
    MileageMatrix: `${RoutePrefix}/admin/pricing/mil`,
    AreaAddDeduct: `${RoutePrefix}/admin/pricing/area`,
    OlderVehicle: `${RoutePrefix}/admin/pricing/oldveh`,
    MinPricing: `${RoutePrefix}/admin/pricing/min`,
    FixedPricing: `${RoutePrefix}/admin/pricing/fix`,

    PricingHomeTemp: `${RoutePrefix}/admin/pricing/temp`,
    BasePricesTemp: `${RoutePrefix}/admin/pricing/temp/base`,
    PriceMatrixTemp: `${RoutePrefix}/admin/pricing/temp/matrix`,
    PriceAddDeductTemp: `${RoutePrefix}/admin/pricing/temp/adddeduct`,
    MileageMatrixTemp: `${RoutePrefix}/admin/pricing/temp/mil`,
    AreaAddDeductTemp: `${RoutePrefix}/admin/pricing/temp/area`,
    OlderVehicleTemp: `${RoutePrefix}/admin/pricing/temp/oldveh`,
    MinPricingTemp: `${RoutePrefix}/admin/pricing/temp/min`,
    FixedPricingTemp: `${RoutePrefix}/admin/pricing/temp/fix`,
  
    ClearVehicle: `${RoutePrefix}/admin/other/clearvehicle`,
    ClearAuthorization: `${RoutePrefix}/admin/other/clearauthorization`,
    PricingHistory: `${RoutePrefix}/admin/other/pricinghistory`,
    QuarterlyPricing: `${RoutePrefix}/admin/other/quarterlypricing`,

}

export const ApplicationPaths = {
    Home: '/',
    AdminHome: '/admin',
    Specs: '/admin/specs',
    AutoPricingExceptions: '/admin/pricingex',
    Discounts: '/admin/discounts',
    Promo: '/admin/promo',
    Categories: '/admin/maint/cat',
    Locations: '/admin/maint/loc',

    BulkPricing: '/pricing/bulk',

    ApprovalHome: '/approvals',
    PendingApprovals: '/approvals/pending',
    PendingFinalApprovals: '/approvals/final',

    DupVehicle: '/dup/dupvehicle',

    ReportsHome: '/reports',
    ReportsAutoManual: '/reports/automanual',
    ReportsUnadvertised: '/reports/unadvertised',
    SpecsPricing: `/specs-pricing`,
    SpecsEdit: `/specs-edit`,
    RePricing: `/re-pricing`,
    AcceptVehicle: `/accept-vehicle`,
    AcceptVehiclePricing: `/accept-vehicle-pricing`,


    PricingHome: '/admin/pricing',
    BasePrices: '/admin/pricing/base',
    PriceMatrix: '/admin/pricing/matrix',
    PriceAddDeduct: '/admin/pricing/adddeduct',
    MileageMatrix: '/admin/pricing/mil',
    AreaAddDeduct: '/admin/pricing/area',
    OlderVehicle: '/admin/pricing/oldveh',
    MinPricing: '/admin/pricing/min',
    FixedPricing: '/admin/pricing/fix',

    PricingHomeTemp: '/admin/pricing/temp',
    BasePricesTemp: '/admin/pricing/temp/base',
    PriceMatrixTemp: '/admin/pricing/temp/matrix',
    PriceAddDeductTemp: '/admin/pricing/temp/adddeduct',
    MileageMatrixTemp: '/admin/pricing/temp/mil',
    AreaAddDeductTemp: '/admin/pricing/temp/area',
    OlderVehicleTemp: '/admin/pricing/temp/oldveh',
    MinPricingTemp: '/admin/pricing/temp/min',
    FixedPricingTemp: '/admin/pricing/temp/fix',

    ClearVehicle: '/admin/other/clearvehicle',
    ClearAuthorization: '/admin/other/clearauthorization',
    PricingHistory: '/admin/other/pricinghistory',   

    QuarterlyPricing:'/admin/other/quarterlypricing',

    NewPromo: '/admin/new-promo',
    CreateNewPromo: '/admin/new-promo/add',
    SetUpPromo: '/admin/new-promo/set-up',
};

const getApiRootPath = () => {
    if (window.location.origin.startsWith('https://dev-uvs'))
        return 'https://apidev.ryder.com/uvs-pricing/v1/api';
    if (window.location.origin.startsWith('https://qa-uvs'))
        return 'https://apiqa.ryder.com/uvs-pricing/v1/api';
    if (window.location.origin.startsWith('https://uvspricing'))
        return 'https://api.ryder.com/uvs-pricing/v1/api';

    return 'https://localhost:7094/api';
}

export const ApiRoot = getApiRootPath();

export const ApiPaths = {
    UserApi: `user`,
    BasePrices: `basepricing`,
    PriceMatrix: `basepricing/details`,
    PriceAddDeduct: `pricingadddeduct`,
    MileageMatrix: `mileage`,
    AreaAddDeduct: `area`,
    OlderVehicle: `pricingoldervehicle`,
    MinPricing: `minprice`,
    FixedPricing: `fixedprice`,
   
    PricingAuth: `pricingauth`,
    PricingAuthFinal: `pricingauth/final`,

    BasePricesTemp: `basepricingtemp`,
    PriceMatrixTemp: `basepricingtemp/details`,
    PriceAddDeductTemp: `pricingadddeducttemp`,
    MileageMatrixTemp: `mileagetemp`,
    AreaAddDeductTemp: `areatemp`,
    OlderVehicleTemp: `pricingoldervehicletemp`,
    MinPricingTemp: `minpricestemp`,
    FixedPricingTemp: `fixedpricestemp`,

    QuarterlyPricing: `quarterlypricing`,
    Notifications: `quarterlypricing/notif`,

    DiscountsRR: `discounts/rr`,
    DiscountsNotRR: `discounts/notrr`,
    Discounts: `discounts`,
   
    Categories: `categories`,
    CategoriesEnabled: `categories/enabled`,
    Locations: `locations`,
    LocationsLookup: `locations/lookup`,

    Specs: `specs`,
    SpecTypes: `specs/types`, 

    AutoPricingExceptions: `autopricingexceptions`,
    RuleSpecs: `autopricingexceptions/rulespecs`,
    RuleCriteria: `autopricingexceptions/criterias`,

    FilterSamClasses: `filter/samclass`,
    FilterMakes: `filter/makes`,
    FilterModels: `filter/models`,
    UnitTypes: `filter/unittype`,
    AttributeUnitTypes: `specs/unittype`,

    AcceptedVehicle: `adminother/accepted`,
    VehicleAuthorization: `adminother/authorization`,
    PriceHistory: `adminother/pricehistory`,

    LatestPrices: `reports/latest`,
    PriceMonthByCondition: `reports/bycondition`,
    AutoManualReport: `reports/automanual`,
    UnadvertisedReport: `reports/unadvertised-vehicles`,
    BulkPricing: `reports/bulk-pricing`,
   
    Promo: 'promo',
    NewPromo: 'promotion',

    VehicleSpecs: `VehicleSpecs`,
    VehicleSpecs_Pricing: `VehicleSpecs/pricing`,
    VehicleSpecs_Pricing_Update: `VehicleSpecs/take-price-modification`,
    VehicleSpecs_VehiclesIds: `VehicleSpecs/vehicle-ids`,
    VehicleSpecs_Update: `VehicleSpecs/update`,
    VehicleSpecs_Update_Pricing: `VehicleSpecs/update-pricing`,
    //VehicleSpecs_Update_Pricing_CA: `VehicleSpecs/update-pricing-ca`,
    VehicleSpecs_Update_Accept: `VehicleSpecs/update-accept`,
    VehicleSpecs_Complete_Accept: `VehicleSpecs/complete-accept`,

    DuplicateSpecs: `VehicleSpecs/dup`,

    Adjustment: `ConditionAdjustment`,
    AdjustmentBase: `ConditionAdjustment/base`,
    
};



export const GroupIds = {
    PricingAdmin: 138,
    PresalePricing: 471,
    QuarterlyPricing: 472,
}
