import React, { useEffect, useState, useContext } from 'react'
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants'
import { fetchApiData, postApiData } from '../../../../core/FetchData'
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { format } from 'fecha';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { red, grey } from '@mui/material/colors'
import { UserContext } from '../../../../core/UserContext';
import { StyledTable } from './components/StyledDefaults'
import SnackAlert from './components/SnackAlert';
import FormValidationText from './components/FormValidationText';
import { useForm } from "react-hook-form";
import { adminGroups, containsGroup } from '../../../../MenuConfig';
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'


const SingleVehiclePricing = ({country, vehicleId}) => {
    
    const [loading, setLoading]     = useState(false);
    const [error, setError]         = useState("");
    const [okStatus, setOkStatus]   = useState("");
    const [data, setData]           = useState(null);

    const userContext   = useContext(UserContext);
    const userEmail     = (userContext?.user?.upn ?? userContext?.user?.preferred_username ?? '').split('@')[0]

    const { handleSubmit, reset, control } = useForm({defaultValues: {pricingValue: '', pricingComments: ''}});
    const [sendToSamChecked, setSendToSamChecked] = useState(false);
    
    const onDataReady = (dat) => { 
        setData(dat); 
        reset({pricingValue: dat?.pricingValue || '', pricingComments: dat?.pricingComments || ''})
    }
    const onError     = (err) => setError(err)

    useEffect(() => { 
        if(userEmail != ''){
            setLoading(true);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs_Pricing}/${vehicleId}/requested-by/${userEmail}/false`, onDataReady, setLoading, onError)
            console.log(data);
        }
    }, [vehicleId,country,userEmail]);



    const onSubmit = (formData) =>
        postApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs_Pricing_Update}`,
            'POST',
            { ...formData, ...{ userEmail, vehicleId, DoNotSendToSAM: sendToSamChecked } },
            () => setOkStatus("Information Saved!"),
            () => setError("Error while Updating")
        );

    const validate = (val, name, label) => {
        val = (val || "").trim();
        if (val === "")            
            return `${label} required.`
        const rgx = /^[0-9]*$/; //=> if decimal (to ask) /^-?\d+(\.\d{1,2})?$/
        if (name === 'pricingValue' && !(rgx.test(val)))
            return `Provide a valid integer value for the take Price`
        if (name === 'pricingComments' && val.length > 500)
            return `Provide less than 500 characters`
        return true
    }

    const handleChange = (event) => {
        setSendToSamChecked(event.target.checked);
    };


    return (<>  
        {loading ? <CircularProgress color="inherit" size={40} /> :
        error ? <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>{error}</Alert>:
        data && (
        <Stack sx={{ width: '100%' }} spacing={1}>
            {data.isTempPricing === true 
            ? (<Alert severity="warning">This is only a temporary Pricing Detail. It does not include any AREA price adjustments</Alert>)
            : (
            <>
                {data.sold && <Alert severity="warning">{`Vehicle was sold ${format(new Date(data.soldDate), ' [on] M/D/YYYY')}`}</Alert>}
                {data.saleInProcess && <Alert severity="warning">Vehicle is 'Sale in Process'</Alert>}
                {data.specApprovalStatus === 0 && <Alert severity="warning">Spec Approval Pending</Alert>}
                {data.specApprovalStatus === 1 && data.isSpecApprovalStatusProcessed === 1 && <Alert severity="warning">Spec Approved - Waiting to be processed</Alert>}
                {data.doNotSendToSAM && <Alert severity="warning"><strong>Previous Price Change was NOT sent to Other Systems</strong></Alert>}
                                
                <TableContainer>
                    <StyledTable size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Accepted at</b></TableCell>
                                <TableCell>{data?.acceptedLocName ?? ''}</TableCell>
                                <TableCell><b>Accepted by</b></TableCell>
                                <TableCell>{`${data?.userName ?? ''}`.trim()}{data.acceptedDate && format(new Date(data.acceptedDate), ' [on] M/D/YYYY [at] HH:mm A')}</TableCell>
                            </TableRow>
                            {(data.transferLocName || '').trim() !== '' && (
                            <TableRow>
                                <TableCell><b>Transferred to</b></TableCell>
                                <TableCell colSpan={3}>{data?.transferLocName ?? ''}</TableCell>
                            </TableRow>
                            )}
                            {data.pricingException === true && (
                            <TableRow>
                                <TableCell sx={{ color: red[900] }} colSpan={4}>Vehicle needs Special Pricing{data.pricingExceptionReason && ` -"${data.pricingExceptionReason}"-`}. Contact your FAM.</TableCell>
                            </TableRow>
                            )}
                            {data.literSizeException === true && (
                            <TableRow>
                                <TableCell sx={{ color: red[900] }} colSpan={4}>Liter Size or Engine Model has been entered incorrectly. Please check these specs</TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
            </>
            )}

            {data.showPriceList === true && (
                <TableContainer component={Paper}>
                    <StyledTable size="small">
                        <TableHead>
                            <TableRow sx={{backgroundColor: grey[100]}}>
                                <TableCell><b>Pricing Type - {data.modelType}</b></TableCell>
                                <TableCell align="right"><b>Ask</b></TableCell>
                                <TableCell align="right"><b>Take</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.vehiclePricingDetailList?.map(x => (
                                <TableRow key={x.vehiclePricingId}>
                                    <TableCell>
                                        <b style={{color: 'grey', display: 'block'}}>{x.typeDescription}</b>
                                        {x.detailName}
                                    </TableCell>
                                    <TableCell align="right">{x.amount}</TableCell>
                                    <TableCell align="right">{x.take}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow key="totals" sx={{backgroundColor: grey[100]}}>
                                <TableCell><b>Totals</b></TableCell>
                                <TableCell align="right"><b>{data.totalAsk}</b></TableCell>
                                <TableCell align="right"><b>{data.totalTake}</b></TableCell>
                            </TableRow>
                        </TableBody>
                    </StyledTable>
                </TableContainer>
            )}

            {data.isTempPricing === false && (
                            <TableContainer component={Paper}>
                                {(data.sold !== true && data.groupIdRequestor >= 3 && containsGroup(adminGroups, userContext?.groups) )
                ? ( <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledTable size="small">
                            <TableHead>
                                <TableRow sx={{backgroundColor: grey[100]}}>
                                    <TableCell colSpan={2}><b>Please enter any corrections to the take price</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{width: '25%', verticalAlign: 'top'}}>
                                        <FormValidationText control={control} validate={validate} size="small"
                                            name="pricingValue" 
                                            label="Take price should be" sx={{width: '100%', mt: 2}}/>
                                    </TableCell>
                                    <TableCell valign='top' style={{ verticalAlign: 'top'}}>
                                        <FormValidationText control={control} validate={validate} rows={2} size="small" 
                                            name="pricingComments" 
                                            label="Reason" sx={{width: '100%', mt: 2}}/>                                                
                                     </TableCell>
                                </TableRow>                                    
                            </TableBody>
                        </StyledTable>

                        <StyledTable size="small" sx={{width:'75%'}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell key="conditionVal1" align={"left"} sx={{ width: '45%' }} >
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleChange} checked={sendToSamChecked} />}
                                            label="Do Not Push to Other Systems"
                                        />
                                    </TableCell>
                                    <TableCell key="conditionVal" align={"left"} sx={{ width: '64%' }}>
                                        <Stack alignItems="center" justifyContent="left" padding={2}>
                                            <Button variant="contained" type='submit' size='small'>Save</Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </StyledTable>

                    </form>)
                    : (
                    <StyledTable>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: '1%'}} sx={{backgroundColor: grey[100]}}><b>Take Price</b></TableCell>
                                <TableCell>{data.pricingValue || <i style={{color:'silver'}}> - n/a -</i> }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: '1%', whiteSpace:'nowrap'}} sx={{backgroundColor: grey[100]}}><b>Pricing Comments</b></TableCell>
                                <TableCell>{data.pricingComments || <i style={{color:'silver'}}> - n/a -</i>}</TableCell>
                            </TableRow>
                        </TableBody>
                    </StyledTable>)}
                </TableContainer>
            )}{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </Stack>)}
        <SnackAlert errorMsg={error} onCloseError={()=> {setError('')}} okMsg={okStatus} onCloseOk= {()=> setOkStatus('')}/>
    </>)
}

const MemSingleVehiclePricing = React.memo(SingleVehiclePricing)
export default MemSingleVehiclePricing;