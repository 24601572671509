import React, { useEffect, useState, useContext} from 'react'
import { useParams, useHistory  } from "react-router-dom"
import { UserContext } from '../../../../core/UserContext'
import { ApplicationPaths, ApiPaths, ApiRoot } from '../../../../core/PathConstants'
import { fetchApiData, postApiData } from '../../../../core/FetchData'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import SnackAlert from './components/SnackAlert'
import SearchUnaccepted from './components/SearchUnaccepted'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { AcceptFieldForm } from './components/AcceptFieldForm'


const AcceptVehicle = ({country}) => {
    const {vehicleId = "0", categoryId = "0"}   = useParams()
    const history       = useHistory()
    const userContext   = useContext(UserContext)
    const userEmail     = (userContext?.user?.upn ?? userContext?.user?.preferred_username ?? '').split('@')[0]

    const [loading, setLoading]     = useState(false);
    const [error, setError]         = useState('');
    const [data, setData]           = useState(null);
    const [okStatus, setOkStatus]   = useState("");

    const onDataReady   = (dat) => setData(dat)
    const onError       = (err) => {setError(err); setData(null);}

    useEffect(() => { 
        if (+vehicleId > 0 && +categoryId > 0) {
            setLoading(true);
            fetchApiData(
                `${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}/specs-for-acceptance/${categoryId}`, 
                onDataReady , 
                setLoading  , 
                (err) => onError(err || "Error while getting the data.")
            );
        } 
    }, [vehicleId, categoryId, country]);

    const searchUnaccepted = (prm) => {
        const rgx = /^[1-9]{1}[0-9]{5}$/; //-> six digits number
        if (prm && !rgx.test(prm))
            setError('Provide a six digits integer as Vehicle Number to continue');
        else if (prm !== vehicleId) 
            fetchApiData(
                `${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/is-unaccepted-vehicle/${prm}`, 
                (_) => history.push(`/${country}${ApplicationPaths.AcceptVehicle}/${prm}/category/2`) , 
                setLoading  , 
                (err) => {
                    onError(err || "Invalid Vehicle Id")
                    history.push(`/${country}${ApplicationPaths.AcceptVehicle}`)
                }
            )
    }

    const onSubmitSpecs = (specList) => {
        postApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs_Update_Accept}`, 
            'POST', 
            { vehicleId, categoryId, specList, userEmail }, 
            (rsp) => {
                setOkStatus("Information Saved!");
                (rsp.nextCategory == -1) 
                    ? history.push(`/${country}${ApplicationPaths.AcceptVehiclePricing}/${vehicleId}`) 
                    : history.push(`/${country}${ApplicationPaths.AcceptVehicle}/${vehicleId}/category/${rsp.nextCategory}`) 
            }, 
            (err) => { 
                setError(err ?? "Error While Updating the Specs")
            });
    }

    return (
        <>
            <Box sx={{ width: '100%', px:3}}>
                <Stack  direction="row"  justifyContent="flex-start"  alignItems="center"  spacing={8} sx={{my:2}}>
                    <SearchUnaccepted search={searchUnaccepted} />
                    {   data &&  data.categoryGroup && <Box sx={{textTransform:'uppercase', color:'slategray'}}>
                            <Typography variant="subtitle1" sx={{lineHeight: '1.2em'}}>vehicle number <strong>{data.vehicleId}</strong></Typography>
                            <Typography variant="h6" sx={{lineHeight: '1em'}}>Edit {data.categoryGroup.category.catDesc ?? ""}</Typography>
                        </Box> }

                     {   data &&  (data.categoryGroup || null) == null  && <Box sx={{textTransform:'uppercase', color:'slategray'}}>
                        <Typography variant="h6" sx={{lineHeight: '1em'}}>no categories provided for this vehicle</Typography>
                     </Box>                       
                    }
                 </Stack>   
                {   loading ? <Alert severity="info" icon={<CircularProgress color="inherit" size={40} fontSize="inherit"/>}><AlertTitle><strong>searching</strong></AlertTitle> ... vehicle number {vehicleId}</Alert> :
                    error   ? <Alert severity="error" icon={<ErrorOutlineIcon sx={{color: 'primary.main'}} />}> {error}</Alert> : 
                    data && data.categoryGroup && (
                    <Paper sx={{ width: '100%', px:4, py:2 }} spacing={1}>
                        <AcceptFieldForm 
                            groupings       = {data.categoryGroup.groupings || []} 
                            samClassCode    = {data.samClassCode || 0}
                            rideGoNoGo      = {data.rideGoNoGo}  
                            engineCosts     = {data.engineCosts}     
                            vehicleCondition= {data.vehicleCondition}
                            onSubmitSpecs   = {onSubmitSpecs}
                            catId           = {categoryId}
                            country         = {country}
                            vehicleId       = {vehicleId}

                        />
                        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                    </Paper>
                    )
                }
            </Box>
            <SnackAlert errorMsg={error} onCloseError={()=> {setError('')}} okMsg={okStatus} onCloseOk= {()=> setOkStatus('')}/>
        </>
    )
}

export default AcceptVehicle

/*categoryGroup: null
engineCosts: "N/A"
rideGoNoGo: "Go"
samClassCode: null
vehicleCondition: "N/A"
vehicleId: 111111*/