import { Grid, Typography, Box, Container, Paper, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { AdminMenuItems, containsGroup } from '../../MenuConfig';


import { useContext } from 'react';
import { UserContext } from '../../core/UserContext';


import React, { Fragment } from 'react';


export const AdminHome = ({ country }) => {
    const user = useContext(UserContext);


    return (
        <Container>
            <Paper
                sx={{
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    mb: 4,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${'/images/RyderBackground1.webp'})`,
                }}
            >
                {/* Increase the priority of the hero background image */}
                {<img style={{ display: 'none' }} src="/images/RyderBackground1.webp" alt="Ryder" />}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.5)',
                    }}
                />
                <Grid container>
                    <Grid item md={6}>
                        <Box
                            sx={{
                                position: 'relative',
                                p: { xs: 3, md: 3 },
                                pr: { md: 0 },
                            }}
                        >
                            <Typography component="h3" variant="h3" color="inherit" >
                                UVS Pricing
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                Administration
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {/*<Typography component="h2" variant="h4" my={2}>UVS Pricing</Typography>*/}
            <Typography component="h2" variant="h5" my={1} color="text.secondary">
                Admin Menu
            </Typography>
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    {AdminMenuItems.map((item, index) => (
                        <Fragment key={index + 1}>
                            {
                                containsGroup(item.groups, user?.groups) && (item.icon ?
                                    <Grid item xs={6}>
                                        <Button startIcon={item.icon} component={Link} to={`/${country}${item.link}`}>
                                            {item.text}
                                        </Button>
                                    </Grid>
                                    : <Grid item xs={12}><Divider textAlign="left">{item.text}</Divider></Grid>
                                )
                            }
                        </Fragment>

                    ))}

                </Grid>
            </Paper>
        </Container>
    );
}
