import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions, TextField, Button, LinearProgress, Grid, Dialog, Divider, IconButton } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { useForm, CheckboxControl, Form, SelectControl } from '../../../../core/useForm';
import DoneIcon from '@mui/icons-material/Done';
import { TableGrid } from '../../../../core/TableGrid';
import { AttachMatrixForm } from './AttachMatrixForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddSpecsControl } from './AddSpecsControl';

const initialValues = { id: 0, name: '', sam: '', year: 0, yearEnd: 0, amount: 0, percent: 0, multiplier: 0, over: 0, addDeductEnabled: 0};

const fieldsValidation = {
    name: {
        error: "Name is required",
        validate: "",
        required: true,       
    },
    sam: {
        error: "SAM is required",
        validate: "",
        required: true,  
     
    },
    year: {
        error: "Value has to be a number",
        validate: "integer",
        
    },
    yearEnd: {
        error: "Value has to be a number",
        validate: "integer",        
    },
    amount: {
        error: "Value has to be a number",
        validate: "number",
    },
    percent: {
        error: "Value has to be a number",
        validate: "number",
    },
    multiplier: {
        error: "Value has to be a number",
        validate: "number",
    },
    over: {
        error: "Value has to be a number",
        validate: "number",
    },

}

export const PriceAddDeductForm = ({ id, onSave, onCancel, onError , country, isTemp }) => {

    const [formErrors, setFormErrors] = React.useState({});

    const [data, setData, handleChange, validateForm] = useForm(initialValues, fieldsValidation, setFormErrors);

    const [isLoading, setIsLoading] = useState(true);
    const [isPSLoading, setIsPSLoading] = useState(true);
  
    const [priceSpecs, setPriceSpecs] = useState(null);
    

    //const onDataSaved = (d) => {

        
    //    onSave();
    //}

    //save the data
    const saveData = () => {      
        
        //add
        if (validateForm()) {

            let newData = { ...data };

            newData.year = Number(data.year);
            newData.yearEnd = Number(data.yearEnd);
            newData.over = Number(data.over);
            newData.multiplier = Number(data.multiplier);
            newData.amount = Number(data.amount);
            newData.percent = Number(data.percent);

            newData.addDeductEnabled = Number(data.addDeductEnabled);
            if (isTemp)
                postApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeductTemp}`, 'POST', newData, onSave, onError);
            else
                postApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeduct}`, 'POST', newData, onSave, onError);
            

        }
      
    }

  
    const onDataError = (msg) => {
        onError(msg);
    }


    const onDelComplete = () => {
        if(isTemp)
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeductTemp}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
        else
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeduct}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
     

    }

    const onSaveComplete = () => {
        //after saving load the latest specs
        if(isTemp)
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeductTemp}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
        else
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeduct}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
     

    }

    const onDel = (specId, criteria, specValue) => {
        if(isTemp)
            postApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeductTemp}/specs`, 'DELETE', { id: id, specId: Number(specId), criteria, specValue }, onDelComplete, onError);
        else
            postApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeduct}/specs`, 'DELETE', { id: id, specId: Number(specId), criteria, specValue }, onDelComplete, onError);
        

    }

    const onSaveSpec = (specId, criteria, specValue) => {
        if(isTemp)
            postApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeductTemp}/specs`, 'POST', { id: id, specId: Number(specId), criteria, specValue }, onSaveComplete, onError);
        else
            postApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeduct}/specs`, 'POST', { id: id, specId: Number(specId), criteria, specValue }, onSaveComplete, onError);
        

    }
       
   
    useEffect(() => {
                
        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
            setIsPSLoading(false);
           
        }
        else {
            if (isTemp) {
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeductTemp}/${id}`, setData, setIsLoading, onDataError);
                //specs
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeductTemp}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);
            }
            else {
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeduct}/${id}`, setData, setIsLoading, onDataError);
                //specs
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceAddDeduct}/${id}/specs`, setPriceSpecs, setIsPSLoading, onDataError);


            }
        }

    }, [id]);



   
    return (
        <Fragment>            
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} {isTemp ? 'NEW' : ''} Price Adds/Deducts</DialogTitle>
            <DialogContent>
                {isLoading || isPSLoading ? <LinearProgress /> :
                    <Form>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                     label="Name"
                                    size="small"
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.name || ''}
                                    required
                                    error={!!formErrors.name}
                                    helperText={formErrors.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="SAM "
                                    size="small"
                                    name="sam"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    value={data?.sam || ''}
                                    
                                    error={!!formErrors.sam}
                                    helperText={formErrors.sam}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="From Year"
                                    size="small"
                                    name="year"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.year || ''}                                    
                                    error={!!formErrors.year}
                                    helperText={formErrors.year}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="To Year"
                                    size="small"
                                    name="yearEnd"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.yearEnd || ''}                                    
                                    error={!!formErrors.yearEnd}
                                    helperText={formErrors.yearEnd}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Amount"
                                    size="small"
                                    name="amount"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.amount || ''}                                    
                                    error={!!formErrors.amount}
                                    helperText={formErrors.amount}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Percent"
                                    size="small"
                                    name="percent"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.percent || ''}                                    
                                    error={!!formErrors.percent}
                                    helperText={formErrors.percent}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                   label="Multiplier"
                                    size="small"
                                    name="multiplier"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.multiplier || ''}                                    
                                    error={!!formErrors.multiplier}
                                    helperText={formErrors.multiplier}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Over"
                                    size="small"
                                    name="over"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.over || ''}                                    
                                    error={!!formErrors.over}
                                    helperText={formErrors.over}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/*ShowExtraFields*/}
                                <CheckboxControl value={!!data?.addDeductEnabled} name="addDeductEnabled" label="Enabled" handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: 5 }}>
                                <Divider />
                            </Grid>
                                <AddSpecsControl id={id} data={priceSpecs} onError={onError} onSave={onSaveSpec} onDelete={onDel} country={country} />                            
                            </Grid>
                    </Form>
                }
                </DialogContent>
            <DialogActions>
                <Button onClick={saveData} color="primary">Save</Button>
                <Button onClick={onCancel} color="primary">Close</Button>                
            </DialogActions>            
        </Fragment> 
    );
 
}
