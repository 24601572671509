import React, { useEffect, useRef, useState } from 'react';
import { Typography, Grid, Button, TextField, DialogContent, Box, useMediaQuery, useTheme } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Body, Footer, PromoLayout } from './PromoLayout.js';
import { useHistory } from 'react-router-dom';
import dayjs from "dayjs";
import { ApiPaths, ApplicationPaths } from '../../../../core/PathConstants.js';
import { useNewPromoContext } from './NewPromoContextProvider.js';
import { requests } from '../../../../core/ApiService.js';
import { isEqual } from '../../../../core/utils.js';

const _maxLengthAllowed = 255;

export const CreateNewPromo = ({ country }) => {
    const history = useHistory();
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [error, setError] = useState('');
    const { setLoading, showAlert, promotion, setPromotion, resetPromotion } = useNewPromoContext();
    const originalPromotionRef = useRef(null);

    const labelAlignment = isSmScreen ? 'left' : 'right';

    useEffect(() => {
        const fetchPromotionDetails = async () => {
            if (!promotion.promotionId) return;

            try {
                setLoading(true);
                const { data } = await requests.get(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}`);
                setPromotion(data);
                originalPromotionRef.current = data;
            } catch (error) {
                showAlert('Error fetching promotion details', 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchPromotionDetails();
    }, [country, promotion.promotionId, setLoading, setPromotion, showAlert]);

    const getHelperText = (name) => {
        const fieldValue = promotion[name];
        return (fieldValue && fieldValue.length >= (_maxLengthAllowed - 10)) ? `${fieldValue.length}/${_maxLengthAllowed}` : '';
    };

    const handleCancel = () => {
        resetPromotion();
        history.replace(`/${country}${ApplicationPaths.NewPromo}`);
    }

    const handleChange = (event) => {
        setPromotion({ ...promotion, [event.target.name]: event.target.value });
    };

    const handleStartDateChange = (newValue) => {
        if (newValue.isAfter(promotion.endDateTime)) {
            setError('End date must be later than start date');
        } else {
            setError('');
        }
        setPromotion({ ...promotion, startDateTime: newValue.toDate() });
    };

    const handleEndDateChange = (newValue) => {
        if (newValue.isBefore(promotion.startDateTime, 'day')) {
            setError('End date must be later than start date');
        } else {
            setError('');
            setPromotion({ ...promotion, endDateTime: newValue.toDate() });
        }
    };

    const isFormValid = () => {
        return !error &&
            promotion.promotionKey &&
            promotion.startDateTime &&
            promotion.endDateTime &&
            (!country.startsWith('us') || promotion.promotionKeySpUs) &&
            (!country.startsWith('ca') || promotion.promotionKeyFrCa);
    };

    const handleSave = async () => {
        if (!isEqual(originalPromotionRef.current, promotion)) {
            setLoading(true);

            try {
                const { data } = await requests.post(`${country}/${ApiPaths.NewPromo}`, promotion);
                setPromotion(data);

                const successMessage = !originalPromotionRef.current?.promotionId ? 'New Promotion Added Successfully' : 'Promotion updated successfully';
                showAlert(successMessage, 'success');
            } catch ({ data }) {
                showAlert(data?.description || 'Process failed while creating Promotion!', 'error');
            }
            finally {
                setLoading(false);
            }
        }

        history.push(`/${country}${ApplicationPaths.SetUpPromo}/${promotion.promotionKey}`, { from: history.location.pathname });
    };

    return (
        <>
            <Typography variant='h4' style={{ margin: 3, textAlign: 'center' }}>Create New Promotion</Typography>
            <DialogContent sx={isSmScreen ? {} : { display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <PromoLayout >
                    <Body>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1" align={labelAlignment}>Promotion Name</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        size="small"
                                        name="promotionKey"
                                        variant="outlined"
                                        value={promotion.promotionKey || ''}
                                        onChange={handleChange}
                                        inputProps={{ maxLength: _maxLengthAllowed }}
                                        helperText={getHelperText('promotionKey')}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1" align={labelAlignment}>Start Date and Time</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <MobileDateTimePicker
                                        renderInput={(props) => <TextField {...props} size="small" />}
                                        value={promotion.startDateTime}
                                        onChange={handleStartDateChange}
                                        minDate={dayjs().startOf('day')}
                                        maxDate={dayjs().add(60, 'day')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1" align={labelAlignment}>End Date and Time</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <MobileDateTimePicker
                                        renderInput={(props) => <TextField {...props} size="small" />}
                                        value={promotion.endDateTime}
                                        onChange={handleEndDateChange}
                                        minDate={dayjs(promotion.startDateTime).add(1, 'day')}
                                        maxDate={dayjs(promotion.startDateTime).add(1, 'year')}
                                    />
                                    {error && (
                                        <Grid item xs={12} md={12}>
                                            <Box color="red">
                                                {error}
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>

                                {country.startsWith('us') && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="subtitle1" align={labelAlignment}>Promotion Name Spanish</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                size="small"
                                                name="promotionKeySpUs"
                                                variant="outlined"
                                                value={promotion.promotionKeySpUs || ''}
                                                onChange={handleChange}
                                                inputProps={{ maxLength: _maxLengthAllowed }}
                                                helperText={getHelperText('promotionKeySpUs')}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                )}
                                {country.startsWith('ca') && (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="subtitle1" align={labelAlignment}>Promotion Name French</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                size="small"
                                                name="promotionKeyFrCa"
                                                variant="outlined"
                                                value={promotion.promotionKeyFrCa || ''}
                                                onChange={handleChange}
                                                inputProps={{ maxLength: _maxLengthAllowed }}
                                                helperText={getHelperText('promotionKeyFrCa')}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </LocalizationProvider>
                    </Body>
                    <Footer>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Button variant='contained' color='inherit' onClick={handleCancel}>Cancel</Button>
                            </Grid>
                            <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant='contained' color='inherit' onClick={handleSave} disabled={!isFormValid()}>Save and Continue</Button>
                            </Grid>
                        </Grid>
                    </Footer>
                </PromoLayout>
            </DialogContent>
        </>
    );
}
