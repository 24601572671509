import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, LinearProgress, TextField } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import AlertMessage from '../../../../core/AlertMessage';
import { TableGrid } from '../../../../core/TableGrid';
import { requests } from '../../../../core/ApiService.js';

export const DiscountsNotRR = ({ country }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    const originalDataRef = useRef([]); // To store the original data

    const FooterSave = () => {
        return (<div style={{ padding: 10 }}>
            <Button variant="contained" color="primary" onClick={onSave} > Save</Button>
        </div>);
    };

    const showAlert = useCallback((message, type = 'error') => {
        setAlertMessage(message);
        setAlertType(type);
        setOpenAlert(true);
    }, []);

    const onError = useCallback((msg) => {
        showAlert('Error: ' + msg, 'error');
    }, [showAlert]);

    const onErrorLoading = useCallback((msg) => {
        showAlert(msg, 'error');
    }, [showAlert]); 

    const onSave = async () => {
        try {
            setIsLoading(true);

            // Determine changes
            const changes = data.reduce((acc, currentItem) => {
                const originalItem = originalDataRef.current.find(orig => orig.id === currentItem.id);
                if (!originalItem || currentItem !== originalItem) {
                    acc.push(currentItem);
                }
                return acc;
            }, []);

            if (changes.length > 0) {
                await requests.put(`${ApiRoot}/${country}/${ApiPaths.Adjustment}/batch`, changes);
                fetchData();
                showAlert('Adjustments successfully saved', 'success');
            } else {
                showAlert('No changes to save', 'info');
            }
        } catch (error) {
            onError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = useCallback(() => {
        setIsLoading(true);
        requests.get(`${ApiRoot}/${country}/${ApiPaths.Adjustment}`)
            .then(({data}) => {
                setData(data);
                originalDataRef.current = data;
            })
            .catch((error) => {
                onErrorLoading(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [country, onErrorLoading]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    //column definition 
    const columns = [
        {
            field: 'samClassCode', headerName: 'SAM Code', width: 150,
        },
        {
            field: 'samClassName', headerName: 'SAM Class Name', flex: 1,
        },
        {
            field: 'adjustments', headerName: 'Adjustment%', width: 140,
            renderCell: (params) => (
                <TextField
                    margin="dense"
                    size="small"
                    name={params.field}
                    value={params.value || ''}
                    onChange={(e) => handleChange(e, params)}
                />

            ),


        },


    ];

    const handleChange = (e, { id, field, country }) => {
        //const name = e.target.name
        const value = e.target.value
        //console.log(value);

        if (field === 'adjustments') {

            //let adjustments = Number(value);
            let adjustments = value;

            if (isNaN(adjustments)) {
                adjustments = 0;
            }

            if (value > 99) {
                adjustments = 0;
                showAlert('Enter value: 0 - 99', 'error');
            }

            const updatedRows = data.map((row) => {
                if (row.id === id) {
                    return { ...row, adjustments };
                }
                return row;
            });
            setData(updatedRows);
        }
    }

    return (
        <div>

            {(isLoading) ? <LinearProgress /> :
                <div style={{ height: 640, width: '100%' }}>
                    <TableGrid rows={data} columns={columns} height={600} keycol="id"
                        footer={
                            <FooterSave />
                        }
                    />
                </div>
            }

            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div>
    );

}
