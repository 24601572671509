import React, { useEffect, useState } from 'react';
import { Typography, Tab, Tabs, Paper, Box, Icon } from '@mui/material';
import { DiscountsNotRR } from './DiscountsNotRR';
import { DiscountsRR } from './DiscountsRR';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { fetchApiData } from '../../../../core/FetchData';
import { LinearProgress } from '@mui/material';
import AlertMessage from '../../../../core/AlertMessage';

import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';



export const Discounts = ({country}) => {
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [condition, setCondition] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');

    const changeValue = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => {
        fetchData();
    }, []);

    //fetch data
    const fetchData = () => {
        setIsLoading(true);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.AdjustmentBase}`, onData, setIsLoading, onErrorLoading);
    }

    const onData = (d) => {

        if (Array.isArray(d)) {
            console.log(d[0].condition);
            setCondition(d[0].condition);
        }
    } 

    const onErrorLoading = (msg) => {
        showAlert(msg, 'error');
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }

    return (
        <div>
            {(isLoading) ? <LinearProgress /> :
            <>
            <Typography variant="h5" style={{ margin: 3 }}>Maintain Adjustments for {condition} Vehicles</Typography>
            <Paper>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={changeValue}
                    aria-label="discount tabs"
                >
                    {/*<Tab label="Not R&R" icon={<LocalParkingIcon />} />*/}
                    {/*<Tab label="R&R" icon={<SettingsRemoteIcon />}/>*/}
                 
                </Tabs>
                <Box p={3}>{console.log(value)}
                    {/*{value === 0 && <DiscountsNotRR country={country}/>}*/}
                    {/*{value === 1 && <DiscountsRR country={country}/>}*/}
                    <DiscountsNotRR country={country} />

                </Box>
             </Paper>
             </>
            }
        </div> 
    );
 
}
