import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography,  Paper, Button, LinearProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../../core/FetchData';
import { BasePricesForm } from './BasePricesForm';
import AlertMessage from '../../../../core/AlertMessage';
import { TableGrid } from '../../../../core/TableGrid';
import AttachFileIcon from '@mui/icons-material/AttachFile';


export const AttachMatrixForm = ({ id, onCancel, onAttachComplete , country, isTemp }) => {
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setConfirmOpen] = React.useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');


   
    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }
       

    const onError = (msg) => {
        showAlert('Error retrieving data');
    };

    const onSaveError = (msg) => {
        showAlert('Error: ' + msg);
    };

    const onComplete = () => {
        
    };
       

 


    const onAttacht = (priceId) => {
        //setSelectedId(id);
        //setOpen(true);
        console.log('attach:', country, id, priceId);
        if(isTemp)
            postApiData(`${ApiRoot}/${country}/${ApiPaths.BasePricesTemp}/${id}/attach/${priceId}`, 'POST', null, onAttachComplete, onSaveError);
        else
            postApiData(`${ApiRoot}/${country}/${ApiPaths.BasePrices}/${id}/attach/${priceId}`, 'POST', null, onAttachComplete, onSaveError);
        

    }
   

    const onData = (d) => {     
        setData(d);
    }


    //fetch data
    const fetchData = () => {
        setIsLoading(true);
        if (isTemp)
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrixTemp}`, onData, setIsLoading, onError);
        else
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrix}`, onData, setIsLoading, onError);

    }

    useEffect(() => {
        fetchData();
    }, []);


    //column definition 
    const columns = [
        {
            field: 'priceId',
            headerName: ' ',
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            
            renderCell: (params) => (
                <IconButton aria-label="Attach"
                    onClick={(event) => {
                        event.ignore = true;
                        onAttacht(params.value);
                    }
                    } >
                    <AttachFileIcon />
                </IconButton>

            ),
        },       
        {
            field: 'name',
            headerName: 'Name',            
            flex: 1
        },
       

    ];



    return (
        <div style={{ padding: 5 }}>
            <Typography variant="h5" style={{ margin: 3 }}>Attach Matrix</Typography>
            <Paper>
                {(isLoading) ? <LinearProgress /> :
                    <Fragment>
                        <DialogContent>
                            <div style={{ height: 450, width: '100%' }}>
                                <TableGrid rows={data} height={400} columns={columns} keycol="priceId" />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onCancel} color="primary">Cancel</Button>
                        </DialogActions>
                    </Fragment>
                }

            </Paper>
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div>
    );

}
