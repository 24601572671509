import React from 'react';
import { AlertTitle, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

export default function AlertMessage({ openAlert, alertMessage, alertType, setOpenAlert, useAlertTitle }) {

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    // Check if the alert message ends with punctuation and add a period if it doesn't
    const formattedAlertMessage = /[.!?]$/.test(alertMessage) ? alertMessage : `${alertMessage}.`;

    return (
        <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity={alertType} onClose={handleAlertClose} >
                {useAlertTitle && <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>}
                {formattedAlertMessage}
            </Alert>
        </Snackbar>
    );
};

