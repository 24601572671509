import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import React, { useState, useEffect } from 'react'
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants'
import { fetchApiData } from '../../../../core/FetchData'
import CircularProgress from '@mui/material/CircularProgress'
import { useDebouncedCallback } from 'use-debounce';
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'


const filter = createFilterOptions();
const MakeModelSearch = ({ country, onChange, label = "Search Make/Model", specID, prevSpecID, makeSelected, modelSelected, attributeCode }) => {

    const [value, setValue] = useState("")
    const [makeModelData, setmakeModelData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const makeModel = (makeSelected === true ? "Make" : "Model")
    const makeModelIds = (makeSelected === true ? "makeid" : "modelid")

    const onChangeValue = (_, newValue) => {   

        //    if (makeSelected)
        //        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/MakeList/${newValue}/${attributeCode}/${specID}/1`, (data) => onDataValidationReady(data, newValue), setLoading, () => onValidationError(newValue));
        //    if (modelSelected) {
        //        //fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/ModelList/${encodedValue}/${attributeCode}/${specID}/1`, (data) => onDataValidationReady(data, newValue), setLoading, () => onValidationError(newValue));
        //    }
        if (makeSelected || modelSelected) {
            onDataValidationReadyNew(newValue);  
            setmakeModelData([]);
        }
    }

    useEffect(() => { onChange(value); }, [value]);

    const debounced = useDebouncedCallback(
        (e) => {

            if (e && e.target) {
                setLoading(true)

                if (e.target.value) {

                    let ec = encodeURIComponent(e.target.value);

                    if (makeSelected) {

                        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/MakeList/${ec}/${attributeCode}/10`, onDataReady, setLoading, onError)

                    }
                    if (modelSelected) {
                        //var tArrayList = [];
                        //tArrayList.push({ "Model": e.target.value });
                        //let encodedValue = JSON.stringify(tArrayList);
                        //fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/ModelList/${encodedValue}/${attributeCode}/${specID}/10`, onDataReady, setLoading, onError)

                        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/ModelList/${ec}/${attributeCode}/10`, onDataReady, setLoading, onError)
                    }
                        
                }  
                else { setLoading(false) }         
            }
        },
        200 //-> delay in ms
    );
    const onDataReady = (data) => {
        if ((data ?? []).length > 0) {
            const dt = (data ?? []).map(x => `${x}`) || []
            setmakeModelData(dt)
        } else
            setmakeModelData([])
    }

    const onDataValidationReady = (data, newValue) => {

        let newValueStr = ""
        if ((data ?? []).length > 0) {
            newValueStr = `${data[0] || ""}`
        }
        //if (newValueStr === "")
        //    onValidationError(newValue)
        setValue(newValueStr);        

    }

    const onDataValidationReadyNew = (newValue) => {
        if (newValue === null || newValue === "")
            setValue("")
        else
           setValue(newValue);
    }

    const onError = () => setErrorMsg("error while loading " + makeModel  );
    const onValidationError = (value) => setErrorMsg(`"${value}" is not an accepted Make/Model`);
    const onCloseError = () => setErrorMsg("")

    return (
        <>
            <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                value={value}
                onChange={onChangeValue}
                onInputChange={(e) => debounced(e)}//onInputChange              
                size="small"
                id="makeModelIds"
                options={makeModelData}
                filterOptions={(opts) => opts}
                renderInput={
                    (params) => <TextField {...params} size="small" label={label} variant="outlined" type={"text"}
                        sx={{
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',//'-webkit-appearance': 'none',
                                margin: 0,
                                padding: 0,
                            },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (<InputAdornment position="start"><SearchOutlined /></InputAdornment>),
                            endAdornment: (<React.Fragment>{loading ? <CircularProgress color="inherit" size={12} /> : null}{params.InputProps.endAdornment}</React.Fragment>
                            ),
                        }}
                    />}
                PaperComponent={({ children }) => (<Paper>{children}</Paper>)}
                autoHighlight={false}
                freeSolo={true}
            />
            <Snackbar open={errorMsg !== ""} autoHideDuration={5000} onClose={onCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={onCloseError} severity="error" sx={{ width: '100%' }}>{errorMsg}</Alert>
            </Snackbar>
        </>
    )
}

//const MemMakeModelSearch = React.memo(MakeModelSearch)
const MemMakeModelSearch = MakeModelSearch
export default MemMakeModelSearch;

