import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions,  FormControlLabel, Input, Chip, TextField, Paper,  Button, Checkbox, LinearProgress, FormControl, InputLabel, Select, MenuItem, Grid, List, ListItem, ListItemText, NativeSelect, Typography, Dialog, Divider } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { TextFieldValidation } from '../../../../core/TextFieldValidation';
import { useForm, CheckboxControl, Form, ListboxControl, SelectControl } from '../../../../core/useForm';
import formValidation from '../../../../core/formValidation';
import DoneIcon from '@mui/icons-material/Done';
import { TableGrid } from '../../../../core/TableGrid';
import { AttachMatrixForm } from './AttachMatrixForm';
import { Link } from 'react-router-dom';
import { PriceMatrixForm } from './PriceMatrixForm';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme =>({
    listButton: {
        margin: theme.spacing(1)
       
    },
    panel: {
        margin: theme.spacing(1)

    },
     chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const initialValues = { id:0, name:'', sam:0, make:' ', model:[], priceId: 0};

const fieldsValidation = {
    name: {
        error: "",
        validate: "",
        minLength: 2,
        maxLength: 50
    },
    sam: {
        error: "",
        validate: "number",
        minLength: 0,
        maxLength: 10
    },
    make: {
        error: "",
        validate: "",
        minLength: 1,
        maxLength: 4
    },
    model: {
        error: "",
        validate: "",
        minLength: 0,
        maxLength: 2000
    },

}

export const BasePricesForm= ({ id, onSave, onCancel, onError , country , isTemp }) => {
    const classes = useStyles();
    const [formErrors, setFormErrors] = React.useState({});

    const [data, setData, handleChange, validateForm] = useForm(initialValues, fieldsValidation, setFormErrors);

    const [isLoading, setIsLoading] = useState(true);
    const [isSamLoading, setIsSamLoading] = useState(true);
    const [isMakeLoading, setIsMakeLoading] = useState(true);
    const [isModelLoading, setIsModelLoading] = useState(true);

    const [samClasses, setSamClasses] = useState(true);
    const [makes, setMakes] = useState(true);
    const [models, setModels] = useState(null);

    const [openMatrix, setOpenMatrix] = useState(false);
    const [openEditMatrix, setOpenEditMatrix] = useState(false);


    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    
    const [priceMatrix, setPriceMatrix] = useState(null);
    const [isPMLoading, setPMIsLoading] = useState(true);


    //save the data
    const saveData = () => {      
        
        //add
        if (validateForm()) {

            let newData = { ...data };

            newData.sam = Number(data.sam);

            if (!data.make)
                newData.make = '    ';

            //change model to string
            
            if (Array.isArray(data?.model)) {
                if (data.model.indexOf('Any') > -1)
                    newData.model = '';
                else { 
                    newData.model = newData.model.join(', ');
                }
            }

            if (isTemp) {
                postApiData(`${ApiRoot}/${country}/${ApiPaths.BasePricesTemp}`, 'POST', newData, onSave, onError);
            } else {
                postApiData(`${ApiRoot}/${country}/${ApiPaths.BasePrices}`, 'POST', newData, onSave, onError);
            }

            
        }
      
    }

    const onModelError = (msg) => {
        onError(msg);
    }

    const onMakeError = (msg) => {
        onError(msg);
    }

    const onSamError = (msg) => {
        onError(msg);
    }

    const onDataError = (msg) => {
        onError(msg);
    }



    const matrixCols = [

        {
            field: 'year',
            headerName: 'Year', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'askprice',
            headerName: 'Ask Price', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'takeprice',
            headerName: 'Take Price',
            disableColumnMenu: true,
            hideSortIcons: true,
        },
    ]


    const onData = (bpData) => {


        if (bpData) {
            
            if (!bpData.model) {
                bpData.model = ['Any'];
            }
            else
                bpData.model = bpData.model.split(", ");
        }

        getModels(bpData?.sam, bpData?.make);

        if (isTemp) {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrixTemp}/${bpData.priceId}`, setPriceMatrix, setPMIsLoading, onDataError);

        } else {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMatrix}/${bpData.priceId}`, setPriceMatrix, setPMIsLoading, onDataError);

        }
        //get attached
        

        setData(bpData);
    }

   

    //sam classes
    const onSamClasses = (samData) => {
        if (Array.isArray(samData)) {
            samData.unshift({ name: 'Any', code: 0 });
        }
        //setData({...data, model:[] })
        setSamClasses(samData);
    }

    //makes
    const onMakes = (mkData) => {
        if (Array.isArray(mkData)) {
            mkData.unshift({ make: '    ' });
        }
       // setData({ ...data, model: [] })
        setMakes(mkData);
    }

    //models
    const onModels = (mdData) => {

        if (Array.isArray(mdData)) {
            if (data?.make && mdData.length > 0) {
                for (let i = 0; i < mdData.length; i++) {
                    mdData[i] = mdData[i]?.trim();
                }
            }

            mdData.unshift('Any');
        }

        setModels(mdData);
    }

    const fetchData = () => {

        if (isTemp) {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.BasePricesTemp}/${id}`, onData, setIsLoading, onDataError);
        } else {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.BasePrices}/${id}`, onData, setIsLoading, onDataError);
        }
        
    }

    useEffect(() => {

        //fetch dropdowns
        //makes
        //fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FilterMakes}`, onMakes, setIsMakeLoading, onMakeError);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/MakeList`, onMakes, setIsMakeLoading, onMakeError);

        //sam classes
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FilterSamClasses}`, onSamClasses, setIsSamLoading, onSamError);
               
        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
           
        }
        else {
            fetchData();
        }

    }, []);

    const getModels = (sam, make) => {
        //base on sam class and makes retrieve the models
        //setData({ ...data, model: ['Any'] });
        if (!!make && make.trim() !== '') {
            //fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FilterModels}/${sam}/${make}`, onModels, setIsModelLoading, onModelError);
            let ec = encodeURIComponent(make);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/ModelList/${sam}/${ec}`, onModels, setIsModelLoading, onModelError);

        }
        else {
            setIsModelLoading(false);
            setModels(null);
        }        
    }

    const handleSelection = (e) => {

        const name = e.target.name;
        
        if (name === "sam") {
            getModels(e.target.value, data?.make);

        }
        else {
            //make changes
            getModels(data?.sam, e.target.value);
        }

        let value = e.target.value;        

        setData({
            ...data,
            [name]: value, model: ['Any'] 
        });        
       
    }

    

   
    const handleChangeModel = (sels, value) => {
        //if any is selected then deselect the others
        if (Array.isArray(sels)) {
            if (value === 'Any') {
                sels = ['Any'];
            }
            else {
                //remove any
                let ix = sels.indexOf('Any');
                if (ix > -1) {
                    sels.splice(ix, 1);
                }
            }
        }
            setData({ ...data, model: sels });
    }

    const handleClose = () => {
        setOpenMatrix(false);
    };

    const onAttach = () =>
    {
        setOpenMatrix(true);
    }

    const onDetach = () => {
        if (isTemp) {
            postApiData(`${ApiRoot}/${country}/${ApiPaths.BasePricesTemp}/${id}/attach/0`, 'POST', null, onAttachComplete, onDataError);

        } else {
            postApiData(`${ApiRoot}/${country}/${ApiPaths.BasePrices}/${id}/attach/0`, 'POST', null, onAttachComplete, onDataError);

        }
        
    }
   
    const onEditMatrix = () => {
        setOpenEditMatrix(true);
    }

    const handleCloseEdit = () => {
        setOpenEditMatrix(false);
    }

    const onSaveErrorEdit = (msg) => {
        onError(msg);
    };

    const handleSaveEdit = () => {
        setOpenEditMatrix(false);
        //refresh
        //showAlert('Record Successfully saved', 'success')
        fetchData();
    }

    const onAttachComplete = () => {
        setOpenMatrix(false);
        //refresh form
        setIsLoading(true);
        
        if (isTemp) {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.BasePricesTemp}/${id}`, onData, setIsLoading, onDataError);
        } else {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.BasePrices}/${id}`, onData, setIsLoading, onDataError);
        }
    }

    return (
        <Fragment>            
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} {isTemp ? 'NEW' : ''} Base Price</DialogTitle>
            <DialogContent>
                {isLoading || isMakeLoading || isSamLoading ? <LinearProgress /> :
                    <Form>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Name"
                                    size="small"
                                    name="name"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.name || ''}
                                    required
                                    error={!!formErrors.name}
                                    helperText={formErrors.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectControl
                                    label="SAM Class"
                                    id="code"
                                    options={samClasses}
                                    textValue="name"
                                    value={data?.sam || 0}
                                    name="sam"
                                    valueType="number"
                                    handleChange={handleSelection}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectControl
                                    label="Make"
                                    id="make"
                                    options={makes}
                                    textValue="make"
                                    value={data?.make || ''}
                                    name="make"
                                    handleChange={handleSelection}

                                />
                            </Grid>
                           
                            <Grid item xs={12}>
                                <ListboxControl
                                    label="Models"
                                    options={models}
                                    multiselect
                                    selectedItems={data?.model}
                                    handleChange={handleChangeModel}                                                                    
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: 5 }}>
                                <Divider />
                            </Grid>
                            
                            {data?.id === 0 ?
                                <Typography variant="body1" >
                                    Please save the new Base Price first, and then open it again to attach a price matrix to it.
                             </Typography> : (data?.priceId !== 0 ?
                                <Fragment>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">{priceMatrix?.[0]?.name}</Typography>
                                            </Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={onDetach} variant="contained">Detach Price Matrix</Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button onClick={onEditMatrix} variant="contained">Edit</Button>
                                        </Grid>

                                        {/*<Button onClick={onAttach}>Edit</Button>*/}
                                        <Grid item xs={12} style={{ marginTop: 5 }}>                                            
                                        <TableGrid rows={priceMatrix} columns={matrixCols} keycol="id" footer={<div></div>} />
                                    </Grid>
                                </Fragment>
                                : <Button onClick={onAttach} variant="contained">Attach Price Matrix</Button>)
                                    }
                           
                            <Grid item xs={12}>
                            </Grid>

                        </Grid>
                    </Form>
                }
                </DialogContent>
            <DialogActions>
                <Button onClick={saveData} color="primary">
                        Save
                </Button>
                <Button onClick={onCancel} color="primary">
                    Close
          </Button>
                
            </DialogActions>
            <Dialog open={openMatrix} aria-labelledby="form-dialog-id" fullWidth maxWidth="md">
                <AttachMatrixForm id={data?.id} onCancel={handleClose} onAttachComplete={onAttachComplete} country={country} isTemp={isTemp} />
            </Dialog>
            <Dialog open={openEditMatrix} aria-labelledby="form-dialogmatrix-id" fullWidth maxWidth="lg">
                <PriceMatrixForm id={data?.priceId} onCancel={handleCloseEdit} onError={onSaveErrorEdit} country={country} onSave={handleSaveEdit} isTemp={isTemp} />
            </Dialog>
        </Fragment> 
    );
 
}
