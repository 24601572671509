import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from './core/Layout';
import { Home } from './components/Home';
import { MainPage } from './components/MainPage';
import { AdminHome } from './components/admin/AdminHome';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import './custom.css';
import { BasePrices } from './components/admin/main/pricing/BasePrices';
import { ApplicationRoutes } from './core/PathConstants';
import { PriceMatrix } from './components/admin/main/pricing/PriceMatrix';
import { PriceAddDeduct } from './components/admin/main/pricing/PriceAddDeduct';
import { MileageMatrix } from './components/admin/main/pricing/MileageMatrix';
import { AreaAddDeduct } from './components/admin/main/pricing/AreaAddDeduct';
import { OlderVehiclePricing } from './components/admin/main/pricing/OlderVehiclePricing';
import { MinimumPricing } from './components/admin/main/pricing/MinimumPricing';
import { FixPricing } from './components/admin/main/pricing/FixedPricing';
import { PricingHome } from './components/admin/main/pricing/PricingHome';
import { ApprovalHome } from './components/approvals/ApprovalHome';
import { ReportsHome } from './components/reports/ReportsHome';
import { AutoManualReport } from './components/reports/AutoManualReport';
import { UnadvertisedReport } from './components/reports/UnadvertisedReport';

import { Specs } from './components/admin/main/specs/Specs';
import { SpecsPricing } from './components/admin/main/specs/SpecsPricing';
import SpecsEdit from './components/admin/main/specs/SpecsEdit';
import RePricing from './components/admin/main/specs/RePricing';
import AcceptVehicle from './components/admin/main/specs/AcceptVehicle';
import AcceptVehiclePricing from './components/admin/main/specs/AcceptVehiclePricing';
import { AutoPricingExceptions } from './components/admin/main/pricingexceptions/AutoPricingExceptions';
import { Discounts } from './components/admin/main/discounts/Discounts';
import { Categories } from './components/admin/maintenance/Categories';
import { Locations } from './components/admin/maintenance/Locations';
import { ClearVehicle } from './components/admin/other/ClearVehicle';
import { ClearAuthorization } from './components/admin/other/ClearAuthorization';
import { PricingHistory } from './components/admin/other/PricingHistory';
import CssBaseline from '@mui/material/CssBaseline';
import { useLocation, useHistory } from 'react-router-dom';
import { red, grey } from '@mui/material/colors';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import { getOktaConfig } from './Config';
import { NewPricingHome } from './components/admin/main/pricing/NewPricingHome';
import { QuarterlyPricing } from './components/admin/other/QuarterlyPricing';
import { PendingApprovals } from './components/approvals/PendingApprovals';
import { PendingFinalApprovals } from './components/approvals/PendingFinalApprovals';
import { DupVehicle } from './components/dup/DupVehicle';
import { BulkPricing } from './components/pricing/BulkPricing';
import { Promo } from './components/admin/main/promo/Promo';
import { NewPromoContextProvider } from './components/admin/main/promoNew/NewPromoContextProvider';
import { NewPromoHome } from './components/admin/main/promoNew/NewPromoHome';
import { CreateNewPromo } from './components/admin/main/promoNew/CreateNewPromo';
import { SetUpPromoHome } from './components/admin/main/promoNew/SetUpPromoHome';


const siteTheme = createTheme({
    palette: {
        background: {
            default: grey[50],
            grey100: grey[100]
        },
        primary: {
            main: red[700],
        },
        secondary: {
            main: grey[600],
        },

        //background: {
        //    default: "#F3F6F9"
        //},
        //primary: {
        //    main: '#E13A3E',
        //},
    },

    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-columnHeader': {
                        color: grey[50],
                        backgroundColor: grey[500],
                        height: '100%',
                    },
                    '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
                        color: grey[50],
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 700,
                    },
                    '& .MuiDataGrid-sortIcon': {
                        color: grey[100]
                    },
                },

            },

        },
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    color: 'white',
                    backgroundColor: 'black',
                    fontSize: 14
                    // color: 'black',
                    // backgroundColor: grey[300],
                }
            }
        },
    },


});

const oktaAuth = new OktaAuth(
    getOktaConfig()
);


export default function App() {
    const location = useLocation();
    const history = useHistory();
    const [country, setCountry] = useState('');

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    }

    useEffect(() => {
        if (location.pathname.startsWith('/us')) {
            setCountry('us');
        }
        else if (location.pathname.startsWith('/ca')) {
            setCountry('ca');
        }
        else
            setCountry('');


    }, [location.pathname]);



    return (
        <ThemeProvider theme={siteTheme}>
            <CssBaseline />
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Layout country={country} >
                    <SecureRoute exact path='/' component={MainPage} />
                    <SecureRoute exact path={ApplicationRoutes.Home} >
                        <Home country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.ApprovalHome} >
                        <ApprovalHome country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.PendingApprovals} >
                        <PendingApprovals country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.PendingFinalApprovals} >
                        <PendingFinalApprovals country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.DupVehicle} >
                        <DupVehicle country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.ReportsHome} >
                        <ReportsHome country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.ReportsAutoManual} >
                        <AutoManualReport country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.ReportsUnadvertised} >
                        <UnadvertisedReport country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.AdminHome}>
                        <AdminHome country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.Specs} >
                        <Specs country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.SpecsPricing} >
                        <SpecsPricing country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.SpecsEdit} >
                        <SpecsEdit country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.AcceptVehiclePricing} >
                        <AcceptVehiclePricing country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.AcceptVehicle} >
                        <AcceptVehicle country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.PricingHome}>
                        <PricingHome country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.RePricing} >
                        <RePricing country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.BasePrices} >
                        <BasePrices country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.PriceMatrix} >
                        <PriceMatrix country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.PriceAddDeduct} >
                        <PriceAddDeduct country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.MileageMatrix} >
                        <MileageMatrix country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.AreaAddDeduct} >
                        <AreaAddDeduct country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.OlderVehicle} >
                        <OlderVehiclePricing country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.MinPricing} >
                        <MinimumPricing country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.FixedPricing} >
                        <FixPricing country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.BasePricesTemp} >
                        <BasePrices country={country} isTemp={true} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.PriceMatrixTemp} >
                        <PriceMatrix country={country} isTemp={true} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.PriceAddDeductTemp} >
                        <PriceAddDeduct country={country} isTemp={true} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.MileageMatrixTemp} >
                        <MileageMatrix country={country} isTemp={true} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.AreaAddDeductTemp} >
                        <AreaAddDeduct country={country} isTemp={true} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.OlderVehicleTemp} >
                        <OlderVehiclePricing country={country} isTemp={true} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.MinPricingTemp} >
                        <MinimumPricing country={country} isTemp={true} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.FixedPricingTemp} >
                        <FixPricing country={country} isTemp={true} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.PricingHomeTemp}>
                        <NewPricingHome country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.AutoPricingExceptions} >
                        <AutoPricingExceptions country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.Discounts} >
                        <Discounts country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.Promo} >
                        <Promo country={country} />
                    </SecureRoute>

                    <NewPromoContextProvider country={country}>
                        <SecureRoute exact path={ApplicationRoutes.NewPromo} >
                            <NewPromoHome country={country} />
                        </SecureRoute>
                        <SecureRoute exact path={ApplicationRoutes.CreateNewPromo} >
                            <CreateNewPromo country={country} />
                        </SecureRoute>
                        <SecureRoute exact path={ApplicationRoutes.SetUpPromo} >
                            <SetUpPromoHome country={country} />
                        </SecureRoute>
                    </NewPromoContextProvider>

                    <SecureRoute exact path={ApplicationRoutes.Categories} >
                        <Categories country={country} />
                    </SecureRoute>

                    <SecureRoute exact path={ApplicationRoutes.Locations}  >
                        <Locations country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.QuarterlyPricing} >
                        <QuarterlyPricing country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.ClearVehicle} >
                        <ClearVehicle country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.ClearAuthorization} >
                        <ClearAuthorization country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.PricingHistory} >
                        <PricingHistory country={country} />
                    </SecureRoute>
                    <SecureRoute exact path={ApplicationRoutes.BulkPricing} >
                        <BulkPricing country={country} />
                    </SecureRoute>
                    <Route path="/login/callback" component={LoginCallback} />
                </Layout>
            </Security>
        </ThemeProvider>
    );

}

