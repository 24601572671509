import React        from 'react'
import Tabs         from '@mui/material/Tabs';
import Tab          from '@mui/material/Tab';
import FieldForm    from './FieldForm';

const a11yProps = (index) => ({ id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}`})

export const MenuTabs = ({ categories, selectedTab, handleChange }) => (
    <Tabs orientation="vertical" variant="scrollable" value={selectedTab} onChange={handleChange} >
        {categories.map(({catId,catDesc}) => <Tab key={catId} value={catId} label={catDesc} {...a11yProps(catId)} sx={{minHeight: '30px'}}/>)}
    </Tabs>
)

const FieldsTabs = ({
    fields          , 
    selectedTab     ,
    
    country         ,
    vehicleId       , 
    requestorGroupId,
    locationId      ,
    locationName    ,
    acceptedDateStr ,    
    acceptedBy      ,
    rideGoNoGo      ,
    engineCosts     ,
    vehicleCondition,
    onSubmitSpecs 
}) => fields.map(({ category: { catId }, groupings }) => (
    <div key={catId} role="tabpanel" hidden={selectedTab !== catId} id={`vertical-tabpanel-${catId}`} aria-labelledby={`vertical-tab-${catId}`}>
        {selectedTab === catId && 
            <FieldForm 
                catId           = {catId} 
                groupings       = {groupings} 
                
                country         = {country} 
                vehicleId       = {vehicleId}
                requestorGroupId= {requestorGroupId} 
                locationId      = {locationId} 
                locationName    = {locationName}
                acceptedDateStr = {acceptedDateStr}
                acceptedBy      = {acceptedBy}
                rideGoNoGo      = {rideGoNoGo}
                engineCosts     = {engineCosts}  
                vehicleCondition= {vehicleCondition}
            onSubmitSpecs={onSubmitSpecs}
            allFields={fields}
        />}
    </div>
))

export default FieldsTabs
