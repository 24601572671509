import React, { useEffect, useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,  Typography,  Paper,  Select, MenuItem, Button, LinearProgress, Grid, TextField, Table, TableBody, TableRow, TableCell, TableContainer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../core/FetchData';
import AlertMessage from '../../../core/AlertMessage';
import { TableGrid } from '../../../core/TableGrid';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from 'react';
import MemSingleVehicleSearch from '../main/specs/SingleVehicleSearch';


export const ClearVehicle = function ({country}) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [specs, setSpecs] = useState([]);
    const [isSpecsLoading, setIsSpecsLoading] = useState(false);

    const [openConfirm, setConfirmOpen] = React.useState(false);
   

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');

    const [vehicleId, setVehicleId] = useState(null);


    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }

   
    const onError = (errMsg) => {
        showAlert(errMsg, 'error');
    };

    const onDelComplete = () => {
        showAlert('Accepted Vehicle deleted', 'success');
        setVehicleId(null);
        setData(null);
        setSpecs([]);
    };

    //DEL functions
    //on click yes to delete
    const handleYes = () => {
        setConfirmOpen(false);
        if (vehicleId) {
            postApiData(`${ApiRoot}/${country}/${ApiPaths.AcceptedVehicle}/${vehicleId}`, 'DELETE', null, onDelComplete, onError);
        }
    };
          


    const onDel = (id) => {
        
        setConfirmOpen(true);
    }


   
    const onData = (d) => {
        
        setData(d);
    }

    //fetch data
    const fetchData = () => {

        if (vehicleId) {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.AcceptedVehicle}/${vehicleId}`, onData, setIsLoading, onError);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.AcceptedVehicle}/${vehicleId}/specs`, setSpecs, setIsSpecsLoading, onError);

        }
        else
            onError('Please enter a vehicle #');

    }

   
    const handleChange = (e) => {
        const value = e.target.value;
        setVehicleId(value);      
    }

    function get2SpecsPerRow() {
        if (!specs) return [];
        let nspecs = [];
        for (let i = 0; i < specs.length; i += 2) {

            nspecs.push([specs[i], specs[i+1]]);
        }
       
        return nspecs;
    }


    function getFormattedDate(d) {

        if (!d) return '';

        let date = new Date(d);

        //var year = date.getFullYear();

        //var month = (1 + date.getMonth()).toString();
        //month = month.length > 1 ? month : '0' + month;

        //var day = date.getDate().toString();
        //day = day.length > 1 ? day : '0' + day;

        //return month + '/' + day + '/' + year;

        return date.toLocaleString(); 
    }

    const onVehicleSelection = useCallback((prm) => {
        if (!isNaN(prm)) setVehicleId(+prm);

    }, []);


    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Clear Vehicle Acceptance</Typography>            
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={4}>
                    <Grid item xs={3} align="right">
                        <Typography variant="h6">Vehicle #:</Typography> 
                    </Grid>
                    <Grid item xs={3}>
                        <MemSingleVehicleSearch country={country} onChange={onVehicleSelection} label="" />

                        {/*<TextField                            */}
                        {/*    hiddenLabel*/}
                        {/*    variant="outlined"*/}
                        {/*    size="small"*/}
                        {/*    name="vehicleId"*/}
                        {/*    fullWidth*/}
                        {/*    value={vehicleId || ''}                            */}
                        {/*    onChange={handleChange}*/}
                        {/*/>*/}
                    </Grid>
                    <Grid item>
                        <Button onClick={fetchData} color="primary" variant="contained" endIcon={<SearchIcon />}>Go</Button>
                    </Grid>
                </Grid>
            </Paper>
           
            {data && specs &&
                <Fragment>
                {data?.soldDate && <Typography style={{ margin: 4 }}><b>Vehicle was sold on {getFormattedDate(data?.soldDate)}.</b>{data?.saleInProcess && <b>&nbsp;Vehicle is in 'Sale in Process' status</b>}</Typography>}

                <TableContainer component={Paper} sx={{ my: 1 }} square>
                    <Table size="small" >
                        <TableBody>
                            <TableRow>
                                <TableCell key="AcceptedAt">
                                    <b>Accepted at</b>
                                </TableCell>
                                <TableCell key="AcceptedAtVal">
                                    {data?.loc_name}
                                </TableCell>
                                <TableCell key="AcceptedBy">
                                    <b>Accepted by</b>
                                </TableCell>
                                <TableCell key="AcceptedByVal">
                                    {data?.first + ' ' + data?.last + ' on ' + getFormattedDate(data?.acceptedDate)}
                                </TableCell>
                            </TableRow>
                            {
                                get2SpecsPerRow().map((spec, index) => {
                                    return (<TableRow>
                                        <TableCell key={'name1-' + index}>
                                            <b>{spec?.[0]?.specName}</b>
                                        </TableCell>
                                        <TableCell key={'val1-' + index} >
                                            {(spec?.[0]?.specTypeDesc === 'Memo') ? spec?.[0]?.vehicleSpecMemo :  spec?.[0]?.vehicleSpecValue}
                                        </TableCell>
                                        <TableCell key={'name2-' + index}>
                                            <b>{spec?.[1]?.specName}</b>
                                        </TableCell>
                                        <TableCell key={'val2-' + index} >
                                            {(spec?.[1]?.specTypeDesc === 'Memo') ? spec?.[1]?.vehicleSpecMemo : spec?.[1]?.vehicleSpecValue}
                                        </TableCell>
                                    </TableRow>);
                                })
                            }
                                
                            
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container>
                    <Grid item xs={12}>
                        <Button onClick={onDel} color="primary" style={{margin:12}} variant="contained" endIcon={<DeleteIcon />}>Clear</Button>
                    </Grid>
                </Grid>
                </Fragment>
            }
            {/*{spec?.[1]?.vehicleSpecValue + ' ' + spec?.[1]?.unitTypeDesc}*/}
            {/*Open dialog*/}            
            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Clear Vehicle"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to remove this accepted vehicle?
                             </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} color="primary">Yes</Button>
                    <Button onClick={handleConfirmClose} color="primary" autoFocus>No</Button>
                </DialogActions>
            </Dialog>            
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div> 
    );
 
}
