import { Checkbox, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Typography } from "@mui/material";

const SelectableList = ({ title, items, onItemSelect, multiselect = false }) => {
  if (items?.length <= 0) return null;

  const handleSelect = (item) => {
    let updatedItems;

    if (multiselect) {
      if (item.code === 'ALL') {
        // If "ALL" is selected, unselect all other items
        updatedItems = items.map(el =>
          el.code === 'ALL' ? { ...el, isChecked: !el.isChecked } : { ...el, isChecked: false }
        );
      } else {
        // If any other item is selected, unselect "ALL"
        updatedItems = items.map(el =>
          el.code === item.code ? { ...el, isChecked: !el.isChecked } :
          el.code === 'ALL' ? { ...el, isChecked: false } : el
        );
      }
      onItemSelect(updatedItems);
    } else {
      // Handle single selection by ensuring only one item is selected
      updatedItems = items.map(el =>
        el.code === item.code ? { ...el, isChecked: true } : { ...el, isChecked: false }
      );
      onItemSelect(updatedItems);
    }
  };

  return (
    <Paper elevation={2}>
      <Typography variant="h6" style={{ alignSelf: 'flex-start', paddingLeft: '16px' }}>
        {title}
      </Typography>
      <MenuList sx={{ height: 264, overflow: 'auto' }}>
        {items.map((item, index) => {
          const key = index + 1;

          return (
            <MenuItem
              key={key}
              onClick={() => handleSelect(item)}
              style={{ paddingTop: '4px', paddingBottom: '4px' }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={item.isChecked}
                  tabIndex={-1}
                  disableRipple
                  edge="start"
                  inputProps={{ 'aria-labelledby': `label-${key}` }}
                  style={{ padding: '0', marginRight: '8px' }}
                />
              </ListItemIcon>
              <ListItemText id={`label-${key}`} primary={item.displayText} />
            </MenuItem>
          )
        })}
      </MenuList>
    </Paper>
  );
}

export default SelectableList;