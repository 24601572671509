import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, List, ListItemIcon, ListItem, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';
import formValidation from './formValidation';

export function useForm(InitialFormValues, fieldsValidation, setFormErrors) {
    const [values, setValues] = React.useState(InitialFormValues);

    const handleInputChange = (e, valueType) => {
        const name = e.target.name;
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

        if (valueType) {
            if (valueType == 'number') {
                value = Number(value);
            }
        }

        setValues({
            ...values,
            [name]: value
        });
    }


    const validateForm = () => {
        let valid = true;
        let temp = {};
        if (fieldsValidation) {
             for (const name in fieldsValidation) {
                 if (fieldsValidation[name].required && !values[name]) {
                    valid = false;
                    temp = { ...temp, [name]: fieldsValidation[name].error };
                }
                else {
                    const error = formValidation(name, values[name], fieldsValidation) || "";
                    if (error) {
                        valid = false;
                        temp = { ...temp, [name]: fieldsValidation[name].error ?? error };
                    }
                } 

                setFormErrors?.(
                    temp
                );
            }
        }
        
        return valid;
    }

    return [
        values,
        setValues,
        handleInputChange,
        validateForm
    ];
}

const useStyles = makeStyles(theme => ({
    root: {

        '& .MuiFormControl-root': {
            width: '100%',
            margin: theme.spacing(1),
            paddingRight: theme.spacing(2)
        }
    }
}));

export function Form(props) {
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate autoComplete="off">
            {props.children}
        </form>
        );
}


export function SelectControl({ label, name, value, handleChange, options, id, textValue, width, valueType, helperText }) {
    
    return (
        <FormControl variant="outlined" size="small" style={{ width: width ?? '100%' }} error={!!helperText}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}                
                value={value}
                name={name}
                onChange={(e) => handleChange(e,valueType)}
                size="small"
                native
            >
                {(options && options.map((sc, index) =>
                    /*<MenuItem key={sc[id]} value={sc[id]}>{sc[textValue]}</MenuItem>*/
                     <option key={ `${sc[id]}-${index}`}  value={sc[id]}>{sc[textValue]}</option>
                )
                )
                }
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
        );
}

export function ListboxControl({ label, selectedIndex, setSelectedIndex,  handleChange, options, id, textValue, width, multiselect, selectedItems, setSelectedItems, height  }) {
        

    const handleListItemClick = (e, value) => {

        if (multiselect && Array.isArray(selectedItems)) {
            let sels = [...selectedItems];

            let ix = sels.indexOf(value);
            if (ix > -1) {
                sels.splice(ix, 1);
            }
            else {
                sels.push(value);
            }
           
            if (handleChange)
                handleChange(sels, value);
        }
        else {
           

            let ix = options?.findIndex((item)=> item === value);
            setSelectedIndex(ix);
            if (handleChange)
                handleChange(value);
          
        }
    };

  
                           
    return (
        <FormControl>
            <Typography variant="caption">
                {label}
          </Typography>
            <List variant="outlined" 
                
                style={{
                    width: width ?? '100%',
                    height: height ?? 140,                                        
                    overflow: 'auto'
                }}
                component="div"
                role="list"
                > 
                {(options && options.map((m, index) => {
                    const labelId = `list-label-${index}`;
                    return (
                        <ListItem button role={undefined} key={index} style={{ paddingTop: 0, paddingBottom: 0 }}
                            onClick={(e) => handleListItemClick(e, m)}
                            selected={Array.isArray(selectedItems) ? (textValue ? selectedItems.findIndex(o => { return o[textValue] === m[textValue]}) !== -1: selectedItems.indexOf(m) !== -1): selectedIndex=== index}

                    //(selectedItems.indexOf(textValue ? m[textValue] : m) !== -1) : selectedIndex=== index}
                        >
                            {multiselect && <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={(textValue ? selectedItems.findIndex(o => { return o[textValue] === m[textValue] }) !== -1 : selectedItems.indexOf(m) !== -1)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>}
                            <ListItemText id={labelId} primary={textValue ? m[textValue] : m} />
                        </ListItem>
                    );                
                })) } 
            </List>
        </FormControl>
    );
}

export function CheckboxControl({ label, name, value, handleChange }) {

    return (
        <FormControl size="small" style={{marginTop:0, marginBottom: 0}}>
            <FormControlLabel  control={<Checkbox name={name}
                checked={value}
                onChange={handleChange}

            />} label={label} />
        </FormControl>
    );
}

