import React, { createContext, useCallback, useContext, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import AlertMessage from '../../../../core/AlertMessage';
import dayjs from 'dayjs';

const NewPromoContext = createContext();

export const useNewPromoContext = () => {
    return useContext(NewPromoContext);
}

export const NewPromoContextProvider = ({country, children }) => {
    const defaultPromotion = {
        promotionKey: '',
        promotionKeySpUs: '',
        promotionKeyFrCa: '',
        startDateTime: dayjs().startOf('day'),
        endDateTime: dayjs().startOf('day').add(1, 'day'),
        country: country,
        isActive: false,
        exclusionTypes: [],
        excludedVehicles: []
    };

    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    const [promotion, setPromotion] = useState(defaultPromotion);
    const [staticData, setStaticData] = useState({});

    const showAlert = useCallback((message, type = 'error') => {
        setAlertMessage(message);
        setAlertType(type);
        setOpenAlert(true);
    }, []);

    const resetPromotion = () => setPromotion({ ...defaultPromotion });

    return (
        <NewPromoContext.Provider value={{loading, setLoading, showAlert, promotion, setPromotion, resetPromotion, staticData, setStaticData }}>
            {/* Loading overlay when loading is true */}
            <Backdrop open={loading} style={{ zIndex: 999999 }}>
                <CircularProgress />
            </Backdrop>
            {children}
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} useAlertTitle={true} />
        </NewPromoContext.Provider>
    );
}
