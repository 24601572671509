import { useState } from 'react';
import { Typography, Button, Select, MenuItem, TextField, InputLabel, Grid, FormControl, Autocomplete } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { fetchApiData } from '../../../../core/FetchData';

const FetchPromotions = ({ country, utcLocations, setFetchedData, setIsLoading, showAlert }) => {
    const [utcValue, setUtcValue] = useState(0);
    const [searchType, setSearchType] = useState(0);
    const [vehicleNumber, setVehicleNumber] = useState('');

    //Fetch data
    const fetchData = () => {
        if (!vehicleNumber && utcValue === 0) {
            showAlert('Please Select a valid UTC or enter a Vehicle Number', 'info');
            return;
        }

        setIsLoading(true);

        const urlQuery = vehicleNumber > 0 ? `?searchType=3&vehicleId=${vehicleNumber}` : `?searchType=${searchType}&locationCode=${utcValue}`;
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Promo}/prices${urlQuery}`, setFetchedData, setIsLoading, handleFetchError);
    }

    const handleFetchError = (err) => {
        setFetchedData([]);

        try {
            const json = JSON.parse(err);
            showAlert(json?.description || "Failed retrieving the Data!");
        } catch (e) {
            return showAlert(err);
        }
    }

    const handleUtcSelect = (event, newValue) => {
        setVehicleNumber('');
        setUtcValue(newValue ? newValue.value : 0);
    };

    const handleSearchTypeSelect = (e) => {
        setSearchType(e.target.value);
    };

    const handleVehicleNumberInput = (e) => {
        const regex = /^[0-9\b]+$/;

        if (e.target.value === '' || regex.test(e.target.value)) {
            // Remove non-numeric characters from the input
            const input = e.target.value;

            // Limit the input to 6 characters
            const limitedInput = input.slice(0, 6);

            // Update the state with the limited input
            setVehicleNumber(limitedInput);

            // Reset Utc to [ALL]
            setUtcValue(0);
        }
    }

    const options = [
        { value: 0, label: '--- Select One ---' },
        ...utcLocations.map(x => ({ value: +x.locationCode, label: x.locationName })),
    ];

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12} md={3} style={{ padding: '16px' }}>
                <Autocomplete
                    id='utcSelect'
                    value={options.find(option => option.value === utcValue) || options[0]}
                    onChange={handleUtcSelect}
                    options={options}
                    getOptionLabel={option =>
                        options.filter(opt => opt.label === option.label).length > 1
                            ? `${option.label} (${option.value})`
                            : option.label
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            label='UTC'
                            variant='outlined'
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: '16px' }}>
                <FormControl variant='outlined' style={{ width: '100%' }}>
                    <InputLabel htmlFor='searchTypeSelect'>Search Type</InputLabel>
                    <Select
                        id='searchTypeSelect'
                        label='Search Type'
                        value={searchType}
                        onChange={handleSearchTypeSelect}
                        fullWidth
                        variant='outlined'
                    >
                        <MenuItem value='0'>Active with Special Price</MenuItem>
                        <MenuItem value='1'>All available for Special Price</MenuItem>
                        <MenuItem value='2'>Sale in Process/Sold with Special Price</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={1} style={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='h5' style={{ textAlign: 'center' }}>OR</Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: '16px' }}>
                <TextField
                    label='Vehicle Number'
                    variant='outlined'
                    id='vehicleNumberInput'
                    onChange={handleVehicleNumberInput}
                    onKeyUpCapture={(e) => e.key === 'Enter' && fetchData()}
                    InputLabelProps={{ shrink: true }}
                    value={vehicleNumber}
                />
                <FormControl variant='outlined' style={{ padding: '16px' }}>
                    <Button
                        variant='contained'
                        color='primary'
                        className='btn'
                        size='small'
                        onClick={fetchData}
                        disabled={!vehicleNumber && utcValue === 0}
                    >
                        Go
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default FetchPromotions;
