import { IconButton, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ClearIcon from '@mui/icons-material/Clear';

const CustomDatePicker = ({ value, onChange, clearDate, ...props }) => {
    return (
        <DatePicker
            renderInput={({ InputProps, ...params }) => (
                <TextField
                    {...params}
                    size="small"
                    InputProps={{
                        ...InputProps,
                        readOnly: true,
                        endAdornment: (
                            <>
                                <IconButton
                                    size="small"
                                    style={{ margin: 0, marginRight: '-20px', visibility: value ? 'visible' : 'hidden' }}
                                    onClick={() => value && clearDate()}
                                >
                                    <ClearIcon />
                                </IconButton>
                                {InputProps?.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            value={value}
            onChange={onChange}
            componentsProps={{
                actionBar: {
                    actions: value ? ['clear'] : []
                },
            }}
            {...props}
        />
    );
};

export default CustomDatePicker;