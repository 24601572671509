import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography, Button, Paper, LinearProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../../core/FetchData';
import AlertMessage from '../../../../core/AlertMessage';
import { useParams } from 'react-router-dom';
import { TableGrid } from '../../../../core/TableGrid';

import { SpecsForm } from './SpecsForm';
import { SelectControl } from '../../../../core/useForm';


export const Specs = ({ country }) => {
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setConfirmOpen] = React.useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    //const { catId } = useParams();
    const [categories, setCategories] = useState([]);

    const [catId, setCatId] = useState(0);

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const handleClose = () => {
        setOpen(false);
    };

    const onError = (msg) => {
        showAlert('Error retrieving Specs');
    };


    const onSaveError = (msg) => {
        showAlert('Error saving Specs' + msg);
    };

    //DEL functions
    const onDelError = (msg) => {
        showAlert('Error: ' + msg, 'error');

    };

    const onDelComplete = () => {
        showAlert('Spec deleted', 'success');
        fetchData();
    };

    //on click yes to delete
    const handleYes = () => {
        setConfirmOpen(false);
        if (selectedId) {
            postApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/${selectedId}`, 'DELETE', null, onDelComplete, onDelError);
        }
    };


    const handleSave = () => {
        setOpen(false);
        //refresh
        showAlert('Spec successfully saved', 'success')
        fetchData();
    };


    const onDel = (id) => {
        setSelectedId(id);
        //
        setConfirmOpen(true);
    }


    const onEdit = (id) => {
        setSelectedId(id);
        setOpen(true);

    }
    const onAdd = () => {
        setSelectedId(0);
        setOpen(true);
    }

    const onData = (d) => {

        setData(d);
    }


    //fetch data
    const fetchData = () => {
        setIsLoading(true);

        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/cats/${catId}`, onData, setIsLoading, onError);
    }

    useEffect(() => {
        fetchData();
    }, [catId]);

    const onCategories = (d) => {
        console.log(d);
        setCategories(d);

        if (Array.isArray(d) && d.length > 0) {
            setCatId(d[0].catID);
        }
    }

    useEffect(() => {

        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.CategoriesEnabled}/true`, onCategories, null, onError);

    }, []);


    //column definition 
    const columns = [
        {
            field: 'specID',
            headerName: ' ',
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderHeader: (params) => (
                <IconButton aria-label="Add"
                    onClick={(event) => {
                        event.ignore = true;
                        onAdd();
                    }
                    } >
                    <AddCircleIcon style={{ color: 'white' }} />
                </IconButton>

            ),
            renderCell: (params) => (
                <IconButton aria-label="Edit"
                    onClick={(event) => {
                        event.ignore = true;
                        onEdit(params.value);
                    }
                    } >
                    <EditIcon />
                </IconButton>

            ),
        },
        {
            field: 'specEnabled', headerName: 'Enabled', width: 150,
            type: 'boolean'

        },
        {
            field: 'specName', headerName: 'Spec Name', flex: 1,

        },
        {
            field: 'del',
            headerName: ' ', width: 60,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton aria-label="Del"
                    onClick={(event) => {
                        event.ignore = true;
                        onDel(params.id);
                    }
                    } >
                    <DeleteIcon style={{ color: 'salmon' }} />
                </IconButton>

            ),
        },

    ];

    //useEffect(() => {
    //    fetchData();
    //}, [catValue]);



    const catChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setCatId(value);

    }

    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Maintain Specs</Typography>
            <Paper style={{ padding: 8 }}>
                <SelectControl
                    label="Category Name"
                    id="catID"
                    options={categories}
                    textValue="catDesc"
                    value={catId || 0}
                    name="catID"
                    size="small"
                    margin="dense"
                    handleChange={catChange}
                />
            </Paper>
            <Paper>
                {(isLoading) ? <LinearProgress /> :
                    <div style={{ height: 640, width: '100%' }}>
                        <TableGrid rows={data} columns={columns} keycol="specID" />
                    </div>
                }
            </Paper>
            {/*Open dialog*/}
            <Dialog open={open} aria-labelledby="form-dialog-id" fullWidth maxWidth="lg">
                <SpecsForm id={selectedId} onCancel={handleClose} onError={onSaveError} onSave={handleSave} country={country} />
            </Dialog>
            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Spec"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this Spec?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleConfirmClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div>
    );

}
