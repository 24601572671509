import { FormHelperText, TextField } from '@mui/material';
import React from 'react'
import { Controller } from "react-hook-form";

const FormValidationText = ({ name, control, label, rows = 0, validate, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ validate: (val) => validate(val, name, label) }}
            render={({
                field: { onChange, value },
                fieldState: { isTouched, isDirty, error },
            }) => (
                <>
                    <TextField
                        onChange={onChange}
                        value={value}
                        label={label}
                        multiline={rows > 0}
                        rows={rows}
                        {...props}
                    />
                    {error ? <FormHelperText htmlFor='render-select' error>{error.message}</FormHelperText> : null}
                </>
            )}
        />
    )
}

export default FormValidationText