import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography, Paper, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Button, LinearProgress, TextField, TablePagination } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../core/PathConstants';
import { fetchApiData, postApiData } from '../../core/FetchData';
import AlertMessage from '../../core/AlertMessage';
import { TableGrid } from '../../core/TableGrid';
import { Fragment } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { SelectControl } from '../../core/useForm';

const useStyles = makeStyles((theme) => ({

    tableHeader: {
        //  fontWeight: 'bold !important', backgroundColor: theme.palette.secondary.light, color: theme.palette.secondary.contrastText
    },
}));



const RequestPriceCell = ({ index, value, field, onChangeValue }) => {
    const [dataValue, setDataValue] = useState(value);

    const handleChange = (e) => {
        const newValue = e.target.value
        setDataValue(newValue);

        onChangeValue(index, field, Number(newValue));

    }
    const onBlur = (e) => {
        const newValue = e.target.value;
        let n = Number(newValue);
        if (isNaN(n)) {
            setDataValue(0);
            onChangeValue(index, field, 0);
        }
    }

    return (<input type="text" value={dataValue} size={7} maxLength={7} onChange={(e) => handleChange(e)} onBlur={(e) => onBlur(e)} />);
   
};

const CommentsCell = ({ index, value, field, onChangeValue }) => {
    const [dataValue, setDataValue] = useState(value);

    const handleChange = (e) => {
        const newValue = e.target.value
        setDataValue(newValue);

        onChangeValue(index, field, newValue);

    }
   

    return (<input type="text" value={dataValue || ''} size={16} maxLength={50} onChange={(e) => handleChange(e)}  />);

};



const RadioCell = ({ index, value, field, onChangeValue }) => {
    const [dataValue, setDataValue] = useState(value);
    
    const handleChange = (e) => {
        const newValue = e.target.value
        setDataValue(newValue);
        onChangeValue(index, field, Number(newValue) );

        
    }

    return (
        (dataValue === -1) ?
            <Typography variant="body" style={{ margin: 3 }}>Not Accepted in SAM Yet</Typography> :
            <FormControl size="small">
                <RadioGroup
                    aria-labelledby="radio-buttons-group"
                    name={field + index + 'group'}
                    value={dataValue}
                    onChange={(e) => handleChange(e)}
                >
                    <FormControlLabel value="0" control={<Radio size="small" />} label={<small>Pending</small>} />
                    <FormControlLabel value="1" control={<Radio size="small" />} label={<small>Accepted</small>} />
                    <FormControlLabel value="2" control={<Radio size="small" />} label={<small>Rejected</small>} />
                </RadioGroup>
            </FormControl>
   );
};


export const PendingApprovals = ({ country }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');    
    const classes = useStyles();
    const [statusFilter, setStatusFilter] = useState(0);
    const [isAll, setIsAll] = useState(false);

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }

       

    const onError = (msg) => {
        showAlert('Error: ' + msg);
    };
     
    const onSaveComplete = () => {
        showAlert('Pricing Authorization successfully saved', 'success');
        setIsLoading(true);
        fetchData();
    }

    const onSave = () => {
        let saveData = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].changed) {
                saveData.push({ id: data[i].id, option: (data[i].famAuthorized === -1) ? 0 : data[i].famAuthorized, price: data[i].requestPrice, comment: data[i].famComments })
            }           
        }

        console.log(saveData);
         postApiData(`${ApiRoot}/${country}/${ApiPaths.PricingAuth}`, 'POST', saveData, onSaveComplete, onError);
    }


    const FooterSave = () => {
        return (<div style={{ padding: 10 }}>
            <Button variant="contained" color="primary" onClick={onSave} > Save</Button>

        </div>);
    };


    const onData = (d) => {
        setData(d);
    }

    //fetch data
    const fetchData = () => {
        setIsLoading(true);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PricingAuth}/${isAll}`, onData, setIsLoading, onError);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {       
        fetchData();
    }, [isAll]);

    
    const onInputChange = (index, field, value) => {
         data[index] = { ...data[index], [field]: value, changed:true };
      
    };

  
    //column definition 
    const columns = [
        {
            field: 'vehicleId', headerName: 'Vehicle', width: 40,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'location', headerName: 'Location', width: 110,
            disableColumnMenu: true,
            filterable: false,
            hideSortIcons: true,
        }, {
            field: 'requestor', headerName: 'Requestor', width: 110,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'pricingComments', headerName: 'UTC Comments', flex: 1,
            disableColumnMenu: true,
            filterable: false,
            hideSortIcons: true,
        },
        {
            field: 'takePrice', headerName: 'Current Price', width: 80,
            align: 'right',
            type: 'number',
            filterable: false,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'condition', headerName: 'Veh Condition', width: 50,
            
            filterable: false,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
         {
            field: 'requestPrice', headerName: 'Requested Price', width: 100,
            disableColumnMenu: true,
            hideSortIcons: true,
             renderCell: (params) => (            
                 <RequestPriceCell index={params.index} value={params.value} field={params.field} onChangeValue={onInputChange} />
                ),
        },
        {
            field: 'famAuthorized', headerName: 'Price Approval', width: 160,
            disableColumnMenu: true,
            hideSortIcons: true,            
            renderCell: (params) => (
               
                <RadioCell index={params.index} value={params.value} field={params.field} onChangeValue={onInputChange} />
            ),

        },        
        {
            field: 'famComments', headerName: 'Comments', width: 170,
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: (params) => (                
                <CommentsCell index={ params.index } value={ params.value } field={ params.field } onChangeValue={ onInputChange } />

            ),
        },        

    ];

    const statusChange = (e) => {
        const value = e.target.value;
        setStatusFilter(value);
        
        setIsAll(value === '1');

    }

     

    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Pricing Changes Authorization</Typography>
            <Paper style={{ padding: 8 }}>
                <SelectControl
                    label="Status"
                    id="id"
                    options={[{ status: 'Pending', id: 0 }, { status: 'All', id: 1 }]}
                    textValue="status"
                    value={statusFilter}
                    name="status"
                    handleChange={statusChange}
                    size="small"
                    margin="dense"
                    
                />
            </Paper>
            <Paper>
                {(isLoading) ? <LinearProgress /> :
                    <Fragment>

                        <div style={{ height: 640, width: '100%' }}>
                            {/*<DataGrid                              */}

                            {/*    rows={data} columns={columns}  />*/}
                            <TableGrid rows={data} columns={columns} keycol="id" size="small" footer={<FooterSave />} />
                        </div>
                    </Fragment>
                }
            </Paper>           
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div>
    );

}
