import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport } from '@mui/x-data-grid';
import { ApiPaths, ApiRoot } from '../../core/PathConstants';
import { fetchApiData } from '../../core/FetchData';
import AlertMessage from '../../core/AlertMessage';
import { Typography,  Paper, LinearProgress } from '@mui/material';
import { Fragment } from 'react';

export const UnadvertisedReport = function ({ country }) {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    const [openAlert, setOpenAlert] = useState(false);

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    };

    const onError = (errMsg) => {
        showAlert(errMsg, 'error');
    };

    const onData = (d) => {
        console.log(d);
        if (Array.isArray(d)) {
            let newd = [];
            for (let i = 0; i < d.length; i++) {
                newd.push({ ...d[i], id: i });
            }
             setData(newd);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.UnadvertisedReport}`, onData, setIsLoading, onError)
    },[]);

    function ReportToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                 <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        { field: 'vehicleId', headerName: 'Vehicle Number', disableColumnMenu: true , flex: 1 },
        { field: 'chassisYear', headerName: 'Year', disableColumnMenu: true , flex: 0.5 },
        { field: 'chassisMakeCode', headerName: 'Make', disableColumnMenu: true , flex: 0.5 },
        { field: 'chassisModel', headerName: 'Model', disableColumnMenu: true , flex: 1 },
        { field: 'askPrice', headerName: 'Ask Price', disableColumnMenu: true , flex: 1 },
        { field: 'takePrice', headerName: 'Take Price', disableColumnMenu: true , flex: 1 },
        { field: 'specialPrice', headerName: 'Special Price', disableColumnMenu: true , flex: 1 },
        { field: 'locId', headerName: 'Location', disableColumnMenu: true , flex: 1 },
        { field: 'acceptDate', headerName: 'Accepted Date', disableColumnMenu: true , flex: 1 },
        { field: 'fboOrVosa', headerName: 'VOSA or FBO', disableColumnMenu: true , flex: 1 },
    ];

    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Unadvertised Vehicles Report</Typography>
            <Paper sx={{mt:1}}>
                {(isLoading) ? <LinearProgress /> :
                    <Fragment>

                        <div style={{ height: 640, width: '100%' }}>
                            <DataGrid 
                                rows={data} columns={columns} components={{ Toolbar: ReportToolbar }} />
                        </div>
                    </Fragment>
                }
            </Paper>
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />
        </div>
    )
}