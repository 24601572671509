import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import React from 'react'

const SnackAlert = ({errorMsg, onCloseError, okMsg, onCloseOk}) =>  <>
        <Snackbar open={errorMsg !== ""} autoHideDuration={4000} onClose={onCloseError} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
            <Alert onClose={onCloseError} severity="error" sx={{ width: '100%' }}>{errorMsg}</Alert>
        </Snackbar>
        <Snackbar open={okMsg !== ""} autoHideDuration={3000} onClose={onCloseOk} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
            <Alert onClose={onCloseOk} severity="success" sx={{ width: '100%' }}>{okMsg}</Alert>
        </Snackbar>
    </>

export default SnackAlert