
const OktaConfigLocal = {
    issuer: 'https://dev-64462935.okta.com/oauth2/default',
    clientId: '0oa4qgsyds4KqGGJ85d7',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'group'],
    
}
const OktaConfigDevQA = {
    issuer: 'https://ryder.oktapreview.com/oauth2/default',
    clientId: '0oa17ozob7wEbBuaU0h8',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'group'],
}

const OktaConfigProd = {
    issuer: 'https://ryder.okta.com/oauth2/default',
    clientId: '0oajd9aba3BQW5P5b357',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'group'],
}

export function getOktaConfig() {

    if (window.location.origin.indexOf('localhost') > 0) {
        return OktaConfigLocal;
        
    } else
        if (window.location.origin.indexOf('dev-uvspricing') > 0 || window.location.origin.indexOf('qa-uvspricing') > 0) {
            return OktaConfigDevQA;
        } else {
            return OktaConfigProd;
        } 

}

