import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions,  FormControlLabel, TextField,  Button, Checkbox } from '@mui/material';
import formValidation from '../../../core/formValidation';
import { fetchApiData, postApiData } from '../../../core/FetchData';
import { ApiPaths, ApiRoot } from '../../../core/PathConstants';




export const CategoryForm = ({ id, onSave, onCancel, onError , country }) => {   
    const [data, setData] = useState({ catDesc: '', catOrder: null, catEnabled: null }); 
    //const classes = useStyles();
    const [formErrors, setFormErrors] = useState({});
   

    const handleChange = e => {
        const name = e.target.name
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value

        // Set values        
        setData({
            ...data,
            [name]: (name === 'catOrder') ? Number(value) : (name === 'catEnabled') ? value | 0 : value
        });

        if (name in fieldsValidation) {            // Set errors
            
            const error = formValidation(name, value, fieldsValidation) || ""

            setFormErrors(prev => ({
                ...prev,
                [name]: error
            }))
        }

    }

    // Validations of some fields
    const fieldsValidation = {
        catDesc: {
            error: "",
            validate: "",
            minLength: 2,
            maxLength: 25
        },
        catOrder: {
            error: "",
            validate: "number",           
        },                   

    }

    const validate = () => {
        let valid = true;
        
        for (const name in fieldsValidation) {
            
            const error = formValidation(name, data[name], fieldsValidation) || ""
            if (error) valid = false;
            console.log(error);
            setFormErrors(prev => ({
                ...prev,
                [name]: error
            }));
        }       

        return valid;
    }
   
    //save the data
    const saveData = () => {      
        
        //add
        if (validate()) {
            
            postApiData(`${ApiRoot}/${country}/${ApiPaths.Categories}`, 'POST', data, onSave, onError);
        }
      
    }

    //get the record
    const fetchData = () => {
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Categories}/${id}`, setData, null, null);          
    }

    //sam classes
   
  

    useEffect(() => {

        //fetch the current record
        if (!id || id === 0) {
            setData({ catDesc: '', catOrder: null, catEnabled: null })
        }
        else
            fetchData();

    }, []);

       


    return (
        <Fragment>            
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} Category</DialogTitle>
                <DialogContent>
                    <TextField                    
                    margin="dense"                    
                    label="Category Name"
                    size="small"
                    name="catDesc"
                    fullWidth
                    value={data?.catDesc || ''}
                    required
                    error={!!formErrors.catDesc}
                    helperText={formErrors.catDesc}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="Sort Order"
                    size="small"
                    name="catOrder"
                    fullWidth
                    value={data?.catOrder || ''}
                    required
                    error={!!formErrors.catOrder}
                    helperText={formErrors.catOrder}
                    onChange={handleChange}
                />
                <FormControlLabel control={<Checkbox name="catEnabled"                    
                    checked={!!data?.catEnabled}
                    size="small"
                    onChange={handleChange}

                />} label="Enabled" />               
                  
                </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={saveData} color="primary">
                     Save
                </Button>
             </DialogActions>

        </Fragment> 
    );
 
}
