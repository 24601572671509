import {styled} from '@mui/material'
import Table from '@mui/material/Table'
import TextField from '@mui/material/TextField';

export const StyledTable = styled(Table)(({ }) => ({ 'tr:last-child td': { border: 0 } }))
//const StyledTable = styled(Table)(() => ({'tr:last-child td': { border: 0 }}));

export const StyledTextField = styled(TextField)(({ }) => ({
    '&>label, &>div': { fontSize: '1rem' },
    '&>div>input, &>div>textarea':{ fontSize: '1rem'}
}));