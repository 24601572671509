import { DiscountTypes } from "./newPromoConstants";
import { Box, Grid } from '@mui/material';
import CustomTextField from '../../../../core/CustomTextField';
import { useState } from "react";

const DiscountFields = ({ bucketDetails, setBucketDetails }) => {
    const [discountError, setDiscountError] = useState('');
    const [percentDiscountError, setPercentDiscountError] = useState('');

    const handleDiscountChange = (newDiscount) => {
        // Setting min value to 1000 as per business requirement.
        if (newDiscount && newDiscount < 1000)
            setDiscountError('Discount must be at least $1000.00!');
        else
            setDiscountError('');

        setTimeout(() => {

            setBucketDetails({ discount: newDiscount > 0 ? parseFloat(newDiscount).toFixed(2) : null });
        }, 5); // Delay updating the parent state
    };

    const handlePercentDiscountChange = (newDiscount) => {
        // Setting allowed Percent Discount as per business requirement.
        if (newDiscount && (newDiscount <= 1 || newDiscount > 99))
            setPercentDiscountError('Percent Discount must be between 1.01% and 99%');
        else
            setPercentDiscountError('');

        setTimeout(() => {
            setBucketDetails({ percentDiscount: newDiscount > 0 ? parseFloat(newDiscount).toFixed(2) : null });
        }, 5); // Delay updating the parent state
    };

    const discountField = (
        <>
            <CustomTextField
                size="small"
                label="Discount"
                type="currency"
                value={bucketDetails.discount}
                onChange={handleDiscountChange}
            />
            {discountError && (
                <Grid item xs={12} md={12}>
                    <Box color="red"> {discountError} </Box>
                </Grid>
            )}
        </>
    );

    const percentDiscountField = (
        <>
            <CustomTextField
                size="small"
                label="Percent Discount"
                type="percent"
                value={bucketDetails.percentDiscount}
                onChange={handlePercentDiscountChange}
            />
            {percentDiscountError && (
                <Grid item xs={12} md={12}>
                    <Box color="red"> {percentDiscountError} </Box>
                </Grid>
            )}
        </>
    );

    switch (bucketDetails.discountTypeDescription) {
        case DiscountTypes.Amount:
        case DiscountTypes.Fixed:
            return discountField;
        case DiscountTypes.PercentTake:
        case DiscountTypes.PercentAsk:
            return percentDiscountField;
        case DiscountTypes.DollarUpToPercent:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {discountField}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {percentDiscountField}
                    </Grid>
                </Grid>
            );
        case DiscountTypes.PercentUpToDollar:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {percentDiscountField}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {discountField}
                    </Grid>
                </Grid>
            );
        default:
            return null;
    }
};

export default DiscountFields;
