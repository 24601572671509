import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,  Typography, Paper,
     Button, LinearProgress    
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../../core/FetchData';
import { BasePricesForm } from './BasePricesForm';
import AlertMessage from '../../../../core/AlertMessage';
import { TableGrid } from '../../../../core/TableGrid';



export const BasePrices = ({country, isTemp}) => {
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setConfirmOpen] = React.useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    const [saved, setSaved] = useState(false);


    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const handleClose = () => {
        setOpen(false);
        if (saved) {
            setSaved(false);
            fetchData();
        }
        
    };

    const onError = (msg) => {
        showAlert('Error retrieving data');
    };

    const onErrorDel = (msg) => {
        showAlert('Error: ' + msg);
    };

    const onErrorSaving = (msg) => {
        showAlert('Error: ' + msg);
    };
    const onDelComplete = () => {
        showAlert('Base price deleted', 'success');
        fetchData();

    };

    //DEL functions
    //on click yes to delete
    const handleYes = () => {
        setConfirmOpen(false);
        if (selectedId) {
            //if temp pricing
            if (isTemp) {
                postApiData(`${ApiRoot}/${country}/${ApiPaths.BasePricesTemp}/${selectedId}`, 'DELETE', null, onDelComplete, onErrorDel);
            } else {
                postApiData(`${ApiRoot}/${country}/${ApiPaths.BasePrices}/${selectedId}`, 'DELETE', null, onDelComplete, onErrorDel);
            }
            
        }
    };


    const handleSave = () => {
        //setOpen(false);
        //refresh
        setSaved(true);
        showAlert('Base Price successfully saved', 'success')
        //fetchData();
    };

    
    const onDel = (id) => {
        setSelectedId(id);
        //
        setConfirmOpen(true);
    }


    const onEdit = (id) => {
        setSelectedId(id);
        setOpen(true);

    }
    const onAdd = () => {
        setSelectedId(0);
        setOpen(true);
    }  

    //fetch data
    const fetchData = () => {
        setIsLoading(true);
        
        if (isTemp) {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.BasePricesTemp}`, setData, setIsLoading, onError);
        } else {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.BasePrices}`, setData, setIsLoading, onError);
        }
        
    }

    useEffect(() => {
        fetchData();
    }, []);


    const columns = [
        {
            field: 'id',
            headerName: ' ',
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderHeader: (params) => (
                <IconButton aria-label="Add"
                    onClick={(event) => {
                        event.ignore = true;
                        onAdd();
                    }
                    } >
                    <AddCircleIcon style={{ color: 'white' }} />
                </IconButton>

            ),
            renderCell: (params) => (
                <IconButton aria-label="Edit"
                    onClick={(event) => {
                        event.ignore = true;
                        onEdit(params.value);
                    }
                    } >
                    <EditIcon />
                </IconButton>

            ),
        },
        {
            field: 'name', headerName: 'Name', width: 250,

        },
        {
            field: 'sam', headerName: 'SAM', width: 120,

        },
        { field: 'make', headerName: 'Make', width: 150 },
        {
            field: 'model',
            headerName: 'Models',
            //renderCell: renderCellExpand,
            flex: 1
        },
        {
            field: 'del',
            headerName: ' ', width: 60,
            //disableColumnMenu: true,
            hideSortIcons: true,
            //disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton aria-label="Del"
                    onClick={(event) => {
                        event.ignore = true;
                        onDel(params.id);
                    }
                    } >
                    <DeleteIcon style={{ color: 'salmon' }} />
                </IconButton>

            ),
        },

    ];

  
    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Maintain {isTemp?'NEW':''} Base Prices</Typography>
            <Paper>
                {(isLoading) ? <LinearProgress /> :
                    <div style={{ height: 640, width: '100%' }}>
                        <TableGrid rows={data} columns={columns} keycol="id" />
                        {/*<DataGrid hideFooter rows={data} columns={columns} />*/}
                    </div>
                    }
            </Paper>
            {/*Open dialog*/}
            <Dialog open={open} aria-labelledby="form-dialog-id" fullWidth maxWidth="lg">
                <BasePricesForm id={selectedId} onCancel={handleClose} onError={onErrorSaving} onSave={handleSave} country={country} isTemp={isTemp} />
            </Dialog>
            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Base Price"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this Base Price?
                             </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} color="primary">
                        Yes
                             </Button>
                    <Button onClick={handleConfirmClose} color="primary" autoFocus>
                        No
                            </Button>
                </DialogActions>
            </Dialog>            
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div> 
    );
 
}
