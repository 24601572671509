import React, { useEffect, useState } from 'react';
import { Typography, Paper, Grid, Backdrop, CircularProgress } from '@mui/material';
import PromoList from './PromoList';
import { fetchApiData } from '../../../../core/FetchData';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import AlertMessage from '../../../../core/AlertMessage';
import UploadPromotions from './UploadPromotions';
import FetchPromotions from './FetchPromotions';

export const Promo = ({ country }) => {
    const [fetchedData, setFetchedData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [utcLocations, setUtcLocations] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');

    useEffect(() => {
        // Load UtcLocations
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Promo}/utcLocations`, setUtcLocations, setIsLoading);
    }, [country])

    const showAlert = (message, type = 'error') => {
        setAlertMessage(message);
        setAlertType(type);
        setOpenAlert(true);
    }

    return (
        <>
            {/* Loading overlay when isLoading is true */}
            {isLoading && (
                <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
                    <CircularProgress />
                </Backdrop>
            )}

            <Typography variant='h6' style={{ margin: 3, textAlign: 'center' }}>Import Promotions</Typography>
            <Paper elevation={3} style={{ padding: '16px', margin: '1rem 0 1.5rem 0' }}>
                <Grid container justifyContent='center'>
                    <UploadPromotions country={country} setIsLoading={setIsLoading} showAlert={showAlert} />
                </Grid>
            </Paper>

            <Paper elevation={3} style={{ padding: '16px', marginBottom: '1.5rem' }}>
                <FetchPromotions country={country} utcLocations={utcLocations} setFetchedData={setFetchedData} setIsLoading={setIsLoading} showAlert={showAlert}/>
            </Paper>

            <Paper elevation={3} style={{ padding: '16px' }}>
                <div style={{ height: 640, width: '100%' }}>
                    <PromoList data={fetchedData} />
                </div>
            </Paper>
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} useAlertTitle={true}/>
        </>
    );
}
