import React, { useEffect, useState, Fragment } from 'react';
import {  DialogTitle, DialogContent, DialogActions,  FormControlLabel, TextField,  Button, Checkbox } from '@mui/material';
import formValidation from '../../../core/formValidation';
import { fetchApiData, postApiData } from '../../../core/FetchData';
import { ApiPaths, ApiRoot } from '../../../core/PathConstants';




export const LocationForm = function ({ id, onSave, onCancel, onError , country })  {   
    const [data, setData] = useState({ loc_name: '', area: '' }); 
    //const classes = useStyles();
    const [formErrors, setFormErrors] = useState({});
   

    const handleChange = e => {
        const name = e.target.name
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value

        // Set values        
        setData({
            ...data,
            [name]: value
        });

        if (name in fieldsValidation) {            // Set errors
            
            const error = formValidation(name, value, fieldsValidation) || ""

            setFormErrors(prev => ({
                ...prev,
                [name]: error
            }))
        }

    }

    // Validations of some fields
    const fieldsValidation = {
        area: {
            error: "",
            validate: "",
            minLength: 0,
            maxLength: 50
        },                         

    }

    const validate = () => {
        let valid = true;
        
        for (const name in fieldsValidation) {
            
            const error = formValidation(name, data[name], fieldsValidation) || ""
            if (error) valid = false;
            console.log(error);
            setFormErrors(prev => ({
                ...prev,
                [name]: error
            }));
        }       

        return valid;
    }
   
    //save the data
    const saveData = () => {      
        
        //add
        if (validate()) {

            postApiData(`${ApiRoot}/${country}/${ApiPaths.Locations}`, 'PUT', data, onSave, onError);
        }
      
    }

    //get the record
    const fetchData = () => {
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Locations}/${id}`, setData, null, null);          
    }

    //sam classes
   
  

    useEffect(() => {

        //fetch the current record
        if (!id || id === 0) {
            setData({ loc_name: '', area: '' })
        }
        else
            fetchData();

    }, []);

       


    return (
        <Fragment>            
            <DialogTitle id="form-dialog-title">Edit Location Area</DialogTitle>
                <DialogContent>
                    <TextField                    
                    margin="dense"                    
                    label="Location Name"
                    size="small"
                    name="loc_name"
                    fullWidth
                    value={data?.loc_name || ''}
                    disabled
                    error={!!formErrors.loc_name}
                    helperText={formErrors.loc_name}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="Location Area"
                    size="small"
                    name="area"
                    fullWidth
                    value={data?.area || ''}                    
                    error={!!formErrors.area}
                    helperText={formErrors.area}
                    onChange={handleChange}
                />
                </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
          </Button>
                <Button onClick={saveData} color="primary">
                        Save
          </Button>
                </DialogActions>

        </Fragment> 
    );
 
}
