import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import { AdminMenuItems, MainMenuItems, containsGroup, containsRole } from '../MenuConfig';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { grey } from '@mui/material/colors';
import './NavMenu.css';
import ListItemButton from '@mui/material/ListItemButton';


const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        overflow: 'auto',
    },
    nested: {       
        paddingLeft: theme.spacing(2),
    },
    sublist: {
        //color: theme.palette.getContrastText(grey[200]),
        backgroundColor: grey[200],
    },

    nested2: {
        paddingLeft: theme.spacing(4),       
    },
}));


export default function NavMenu({ user, open, country }) {
    const classes = useStyles();
    
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    
    const location = useLocation();

    const [menu, setMenu] = useState(MainMenuItems);
    
    const handleListItemClick = (event, index) => setSelectedIndex(index);    

    useEffect(() => {
        if (location.pathname.startsWith('/us/admin') || location.pathname.startsWith('/ca/admin')) {
            if (AdminMenuItems !== menu) {
                setSelectedIndex(-1);
                setMenu(AdminMenuItems);
            }
        } else {
            if (MainMenuItems !== menu) {
                setSelectedIndex(-1);
                setMenu(MainMenuItems);
            }
        }
    });

    return (
        <div className={classes.drawerContainer}>
            <Toolbar />
            <List>
                {menu.map((item, index) => (
                    <Fragment key={`${index}${item.text}`}>{
                        (containsGroup(item.groups, user?.groups) || containsRole(item.roles, user?.details?.title))
                        ?   item.icon 
                            ?   <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    component={Link} to={`/${country}${item.link}`} selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index)}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    {open && <ListItemText primary={item.text} />}
                                </ListItemButton>
                            :   <Divider />
                        : <div />
                    }</Fragment>
                ))}
            </List>          
        </div>
    );
}

