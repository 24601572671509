import React, { useEffect, useState, Fragment } from 'react';
import {  TextField, Button, Grid,  IconButton } from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import {  fetchApiData } from '../../../../core/FetchData';
import { SelectControl } from '../../../../core/useForm';
import { TableGrid } from '../../../../core/TableGrid';
import DeleteIcon from '@mui/icons-material/Delete';

import MemMakeModelSearch from './MakeModelSearch';

export const AddSpecsControl = ({ id, onSave, data, onError, onDelete , country }) => {

    const [specs, setSpecs] = useState(null);

    const [selSpec, setSelSpec] = useState(null);

    const [criteria, setCriteria] = useState("=");
    const [spec, setSpec] = useState(0);
    const [specValue, setSpecValue] = useState(null);

    const [isSpecsLoading, setIsSpecsLoading] = useState(true);
    const [isSLLoading, setIsSLLoading] = useState(false);

    const [specList, setSpecList] = useState([]);

    const [criterias, setCriterias] = useState([{ option: "=" }]);

    let [MfgModel, setMfgModel] = useState([]);                                             
    const [showAddButton, setShowAddButton] = useState(false)                               
    const [makeListId, setMakeListId] = useState([]);                                       
    const [modelListId, setModelListId] = useState([]);                                     
    const [makeSelected, setMakeSelected] = useState(false);                                
    const [modelSelected, setModelSelected] = useState(false);                              
    const [prevSpec, setPrevSpec] = useState(0);                                            

    const criteriaConsts = [
        { option: "="},
        { option: "<>"},
        { option:">"},
        { option:"<"},
        { option:"Like"},
        { option:"Not Like"},
        { option:"Begins"},
        { option:"Ends"},
        { option: "Between"},
    ];


    const onDataError = (msg) => {
        onError(msg);
    }

    const specCols = [

        {
            field: 'specName',
            headerName: 'Spec Name',
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'criteria',
            headerName: 'Criteria', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'specValue',
            headerName: 'Value', width: 150,
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'del',
            headerName: ' ', width: 60,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton aria-label="Del"
                    onClick={(event) => {
                        event.ignore = true;                       
                        if(params?.row)
                        onDelete(params.row.specId, params.row.criteria, params.row.specValue );
                    }
                    } >
                    <DeleteIcon style={{ color: 'salmon' }} />
                </IconButton>

            ),
        },
    ];

   

    const onAdd = () => {
        if (spec=== 0 || specValue === '') {
            onError('Please select an spec and enter a value');
        }
        else {
            onSave(spec, criteria, specValue);
            setSpec(0);
            setCriteria('=');
            setSpecValue('');
            setSelSpec(null);

        }
    }


    const onCriteriaChange = (e) => {

        setCriteria(e.target.value);
    }

    //when spec changes look for spec type
    const onSpecsChange = (e) => {

        setShowAddButton(false);
        setMakeSelected(false);
        setModelSelected(false);

        const value = e.target.value;
        
        if (Array.isArray(specs)) {
            const s = specs.find(i => i.specID === Number(value));
            //list
            if (s) {

                setSelSpec(s);                

                if (s.specTypeID === 1) {
                    let critoptions = [...criteriaConsts.slice(0, 4)];
                    setCriterias(critoptions);
                    if (s.specTypeID === 1 && !MfgModel.includes(Number(s.specID)))
                    {
                        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/${s.specID}/list`, onSpecList, setIsSLLoading, onDataError);
                        setShowAddButton(true);
                    }

                    if (s.specTypeID === 1 && MfgModel.includes(Number(s.specID))) {
                        setSpecList('');
                        setSpecValue('');
                        setShowAddButton(false);
                    }

                }
                else {
                    let critoptions = [...criteriaConsts];
                    setCriterias(critoptions)
                    setShowAddButton(true);
                }

                setCriteria("=");

                // set MakeSelected/ModelSelected to true
                if (makeListId.includes(s.specID)) {
                    setMakeSelected(true);
                }
                if (modelListId.includes(s.specID)) {
                    setModelSelected(true);
                }
            }
        }

        setSpec(e.target.value);
        setSpecValue('');

        //---
        //console.log(e.target.value);
        //console.log(prevSpec);
        if (e.target.value !== prevSpec) {
            setPrevSpec(e.target.value);
        }
        
    }

    const onSpecs = (dt) => {

        if (Array.isArray(dt)) {
            dt.unshift({ specID: 0, specName: '' });
        }
        setSpecs(dt);
        LoadMakeModel(dt);
    }

    const onSpecList = (dt) => {
        //console.log(dt);
        if (Array.isArray(dt)) {
            dt.unshift({ specListValue : 'Any' });
        }
        setSpecList(dt);
        setSpecValue('Any');
    }

    
    const onChangeValue = (e) => {
        let value = e.target.value

        switch (selSpec?.specTypeID) {           
            case 2: //value
                if (!validateNumber(value, selSpec?.specFrom, selSpec?.specTo)) value = '';
                break;
            case 5: //date
                if (!validateDate(value)) value = '';
                break;            
        }

      
        setSpecValue(value);
    }

    //--
    const onVechicleSelection = (e) => {

        setShowAddButton(false);
        
        if (selSpec.specTypeID === 1 && e !== '') {
            setShowAddButton(true);
            setSpecValue(e);
            
        };

    }

    const LoadMakeModel = (dt) => {

        if (Array.isArray(dt)) {
            let specMake = dt.filter(x => x.standardSpecCode?.includes("MAKE"));
            let specModel = dt.filter(x => x.standardSpecCode?.includes("MODEL"));
            
            for (let i = 0; i < specMake.length; i++) {
                makeListId.push(specMake[i].specID);
            }
            for (let i = 0; i < specModel.length; i++) {
                modelListId.push(specModel[i].specID);
            }

            let MakeModel = [...makeListId, ...modelListId];
            setMfgModel(MakeModel);
            //console.log(MfgModel);
        }
    }

    //New


    //validation
    function isDate(strDate) {
        var inputStr = strDate
        var delim1 = inputStr.indexOf("/")
        var delim2 = inputStr.lastIndexOf("/")
        if (delim1 === delim2) { return false; }
        var mm = parseInt(inputStr.substring(0, delim1), 10)
        var dd = parseInt(inputStr.substring(delim1 + 1, delim2), 10)
        var yyyy = parseInt(inputStr.substring(delim2 + 1, inputStr.length), 10)
        if (isNaN(mm) || isNaN(dd) || isNaN(yyyy)) { return false; }
        if (mm < 1 || mm > 12) { return false; }
        var monthMax = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (dd > monthMax[mm]) { return false; }
        if (yyyy % 4 > 0 && mm === 2 && dd > 28) { return false; }
        return true;
    }

    function validateDate(controlvalue) {
        if (controlvalue !== "" && controlvalue !== "No change") {
            if (!isDate(controlvalue)) {
                onError("Please enter a date in the format MM/DD/YYYY");
               return false
            }
        }

        return true;
    }

    function isPosNumber(inputVal) {
        let inputStr = inputVal.toString();
        for (var i = 0; i < inputStr.length; i++) {
            var oneChar = inputStr.charAt(i);
            if ((oneChar < "0" || oneChar > "9") && oneChar !== "." && oneChar !== ",") {
                return false;
            }
        }
        return true;
    }

    function validateNumber(controlvalue, fromvalue, tovalue) {
        if (controlvalue !== "" && controlvalue !== "No change") {
            if (isPosNumber(controlvalue) == false) {
                onError("Please enter a numeric value");
                return false;
            }
            var inputValue = parseInt(controlvalue)
            if (fromvalue !== "") {
                var fromvalue = parseInt(fromvalue)
                if (inputValue < fromvalue) {
                    onError("Please enter a value >= " + fromvalue);                    
                    return false;
                }
            }
            if (tovalue !== "") {
                var tovalue = parseInt(tovalue)
                if (inputValue > tovalue) {
                    onError("Please enter a value <= " + tovalue);
                   
                    return false;
                }
            }
           
        }
        return true;
    }

    useEffect(() => {
        //fetch the current record
        if (!id || id === 0) {
            setIsSpecsLoading(false);
        }
        else {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}`, onSpecs, setIsSpecsLoading, onDataError);
        }

    }, [id]);


    return (

        <Fragment>
            {id === 0 || isSpecsLoading ? <div></div> :
                <Fragment>
                    <Grid item xs={3}>
                        <SelectControl
                            label="Spec Name"
                            id="specID"
                            options={specs}
                            textValue="specName"
                            name="spec"
                            value={spec}
                            handleChange={onSpecsChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectControl
                            label="Criteria"
                            id="option"
                            options={criterias}
                            textValue="option"
                            name="criteria"
                            value={criteria}
                            handleChange={onCriteriaChange}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        {
                            //selSpec?.specTypeID === 1 ?
                            selSpec?.specTypeID === 1 && MfgModel.includes(Number(spec)) ?
                                < MemMakeModelSearch country={country} onChange={onVechicleSelection} label={makeSelected === true ? "Search Make" : "Search Model"} specID={selSpec.specID} prevSpecID={prevSpec} makeSelected={makeSelected} modelSelected={modelSelected} attributeCode={selSpec.standardSpecCode} />                               
                                :
                                (
                                    selSpec?.specTypeID === 1 && !MfgModel.includes(Number(spec)) ?
                                    <SelectControl
                                        label="Value"
                                        id="specListValue"
                                        options={specList}
                                        textValue="specListValue"
                                        name="specListValue"
                                        value={specValue || ''}
                                        handleChange={onChangeValue}
                                    /> :
                                    <TextField
                                        label="Value"
                                        size="small"
                                        name="specValue"
                                        fullWidth
                                        value={specValue || ''}
                                        maxLength={selSpec?.specTypeID === 3 ? selSpec?.specLength : 25}
                                        variant="outlined"
                                        onChange={onChangeValue}
                                    />
                                )
                        }
                    </Grid>                    
                    <Grid item >
                        {
                            showAddButton   &&
                                <Button onClick={onAdd} style={{ marginTop: 8 }} variant="contained">Add</Button> 
                                                                                                
                        }
                        
                    </Grid>                 
                    <Grid item xs={12} style={{ marginTop: 5 }}>
                        <TableGrid rows={data} columns={specCols} footer={<div></div>} />
                    </Grid>
                </Fragment>
            }
        </Fragment> 
    );
 
}
