import Grid         from '@mui/material/Grid';
import Typography   from '@mui/material/Typography';
import Box          from '@mui/material/Box';
import Paper        from '@mui/material/Paper';
import Button       from '@mui/material/Button';
import {DataGrid}   from '@mui/x-data-grid';
import {Link}       from 'react-router-dom';
import Container from '@mui/material/Container';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import React, { useEffect, useState, Fragment, useContext } from 'react';
import { ApiPaths, ApiRoot } from '../core/PathConstants';
import { fetchApiData } from '../core/FetchData';
import { MainMenuItems, containsGroup, containsRole } from '../MenuConfig';
import { UserContext } from '../core/UserContext';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const Home = ({country}) => {
    const [latestPrices, setLatestPrices] = useState(null);
    const [chartData, setChartData] = useState(null);
    const user = useContext(UserContext);
    //column definition 
    const columns = [      
        { field: 'vehicleId', headerName: 'Vehicle No', width: 250},
        { field: 'amount', headerName: 'Amount', flex: 1},
        { field: 'take', headerName: 'Take', flex: 1}
    ];
    const onError = (msg) => console.log(msg);
    
    const onLastestPrices = (d) => {
        const drows = d.map(r => { return { id: r.vehicleId, vehicleId: r.vehicleId, amount: r.amount, take: r.take }} );
        setLatestPrices(drows);
    }

    const distinct = (value, index, self) => self.indexOf(value) === index
   
    const onChartData = (d) => {
        console.log(d);
        if (Array.isArray(d)) {
            let labels = d.map(r => r.priceMonth);
            labels = labels.filter(distinct);
            
            let ver     = [];
            let cert    = [];
            let asis    = [];

            d.forEach(r => {
                if (r.condition === 'Verified')
                    ver.push( r.priceCount);
                if (r.condition === 'Certified')
                    cert.push(r.priceCount);
                if (r.condition === 'Reclassified')
                    asis.push(r.priceCount);
            });

            const data = {
                labels,
                datasets: [
                    { label: 'Verified'     , data: ver , backgroundColor: 'rgba(38,160,252, 0.5)'  },
                    { label: 'Certified'    , data: cert, backgroundColor: 'rgba(104,212,205, 0.5)' },
                    { label: 'Re-Classified', data: asis, backgroundColor: 'rgba(139,117,215, 0.5)' }
                ]
            };

            setChartData(data);
        }
    }

    const fetchData = () => {
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.LatestPrices}`, onLastestPrices, onError);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.PriceMonthByCondition}`, onChartData, onError);
    }


    useEffect(() => {
       if(country)
        fetchData();
    },[country]);


    return (
        <Container>
            <Paper
                sx={{
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    mb: 4,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center',
                    backgroundImage: `url(${'/images/RyderBackground1.webp'})`,
                }}
            >
                {/* Increase the priority of the hero background image */}
                {<img style={{ display: 'none' }} src="/images/RyderBackground1.webp" alt="Ryder" />}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.5)',
                    }}
                />
                <Grid container>
                    <Grid item md={6}>
                        <Box
                            sx={{
                                position: 'relative',
                                p: { xs: 3, md: 6 },
                                pr: { md: 0 },
                            }}
                        >
                            <Typography component="h4" variant="h4" color="inherit" >
                                UVS Pricing
                            </Typography>                                                     
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Typography component="h2" variant="h5" my={1} color="text.secondary">
                Welcome to UVS Pricing for {country==='us'?'USA':'Canada'}
            </Typography>
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    {MainMenuItems.map((item, index) => (
                        <Fragment key={item.text}> {
                            (containsGroup(item.groups, user?.groups) || containsRole(item.roles, user?.details?.title)) && item.icon &&
                            <Grid item xs={6}>
                                <Button startIcon={item.icon} component={Link} to={`/${country}${item.link}`}>
                                    {item.text}
                                </Button>
                            </Grid>
                        }</Fragment>
                    ))}
                  
                </Grid>
            </Paper>
            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item xs={7}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle1" my={1} color="text.secondary">
                            <b>Vehicles Priced by Condition</b>
                        </Typography>
                        {chartData && <Bar data={chartData} />}
                    </Paper>
                </Grid>
                <Grid item xs={5}>
                    <Paper sx={{ p: 2}}>
                        <Typography variant="subtitle1" color="text.secondary">
                            <b>Latest  Priced Vehicles</b>
                        </Typography>
                        <div style={{ height: 332, width: '100%' }}>
                            {latestPrices && <DataGrid columns={columns} rows={latestPrices}  density='compact' hideFooter={true} />}
                        </div>
                    </Paper>
                 </Grid>
            </Grid>
            <br />
            <br />
      </Container>
    );
}
