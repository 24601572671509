import React from 'react';
import { TableGrid } from '../../../../core/TableGrid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const PromoList = ({ data }) => {

  // Column definition 
  const columns = [
    {
      field: 'vehicleId', headerName: 'Vehicle Number', width: 150,
      disableColumnMenu: true,
    },
    {
      field: 'locationName', headerName: 'UTC', width: 200,
      disableColumnMenu: true,
    },
    {
      field: 'askPrice', headerName: 'Ask', width: 100,
      disableColumnMenu: true,
      filterable: false,
      type: 'currency',
    },
    {
      field: 'takePrice', headerName: 'Take', width: 100,
      disableColumnMenu: true,
      filterable: false,
      type: 'currency',
    },
    {
      field: 'specialPrice', headerName: 'Special Price', width: 100,
      disableColumnMenu: true,
      filterable: false,
      type: 'currency',
    },
    {
      field: 'acceptedDate', headerName: 'Accepted Date', width: 200,
      disableColumnMenu: true,
      type: 'date'
    },
    // {
    //   field: 'edit',
    //   headerName: ' ',
    //   disableColumnMenu: true,
    //   hideSortIcons: true,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => (
    //     <IconButton aria-label='Edit'
    //       onClick={(event) => {
    //         event.ignore = true;
    //         console.log(`Editing: ${params.id}`);
    //         // onEdit(params.value);
    //       }
    //       } >
    //       <EditIcon />
    //     </IconButton>
    //   ),
    // },
    // {
    //   field: 'del',
    //   headerName: ' ',
    //   width: 60,
    //   disableColumnMenu: true,
    //   hideSortIcons: true,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => (
    //     <IconButton aria-label='Del'
    //       onClick={(event) => {
    //         event.ignore = true;
    //         // onDel(params.id);
    //         console.log(`Deleting params: ${params.id}`);
    //       }
    //       } >
    //       <DeleteIcon style={{ color: 'salmon' }} />
    //     </IconButton>

    //   ),
    // },
  ];

  return (
    <TableGrid rows={data} columns={columns} keycol='vehicleId' height={580} rowsPerPageOption={10} />
  );
}

export default PromoList;
