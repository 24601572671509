import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import Autocomplete, { createFilterOptions }  from '@mui/material/Autocomplete'
import Paper  from '@mui/material/Paper'
import React, { useState, useEffect } from 'react'
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants'
import { fetchApiData } from '../../../../core/FetchData'
import CircularProgress from '@mui/material/CircularProgress'
import { useDebouncedCallback } from 'use-debounce';
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'


const filter = createFilterOptions();
const SingleVehicleSearch = ({country, onChange, label="Search Unit Number"}) => {

    const [value, setValue]             = useState("")
    const [vehicleData, setVehicleData] = useState([]);
    const [loading, setLoading]         = useState(false);
    const [errorMsg, setErrorMsg]       = useState("");

    const onChangeValue = (_, newValue) => { 
        if (!isNaN(newValue) && newValue > 0) 
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs_VehiclesIds}/${newValue}/1`,(data) => onDataValidationReady(data,newValue), setLoading, () => onValidationError(newValue));
        else 
            setValue("");
    }

    useEffect(() => { onChange(value);}, [value]);   

    const debounced = useDebouncedCallback(
        (e) => {
            if (e && e.target && !isNaN(e.target.value) && +e.target.value > 0 ){
                setLoading(true)
                fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs_VehiclesIds}/${+e.target.value}/10`, onDataReady, setLoading, onError)
            }
        },        
        200 //-> delay in ms
    );
    const onDataReady = (data) => { 
        if((data ?? []).length > 0){
            const dt = (data ?? []).map(x => `${x}`) || []
            setVehicleData(dt)
        }else
            setVehicleData([])
    }
    const onDataValidationReady = (data, newValue) => {
        let newValueStr = ""
        if((data ?? []).length > 0){
            newValueStr = `${data[0] || ""}`
        }
        if (newValueStr === "")
            onValidationError(newValue)
        setValue(newValueStr); 
    }

    const onError = () => setErrorMsg("error while loading vehicle ids");
    const onValidationError = (value) => setErrorMsg(`"${value}" is not an accepted vehicle`);
    const onCloseError = () => setErrorMsg("")
    
    return (
        <>
        <Autocomplete
            isOptionEqualToValue    ={(option, value) => option === value }
            value                   ={value}
            onChange                ={onChangeValue}            
            onInputChange           ={(e) => debounced(e) }//onInputChange
            size                    ="small"
            id                      ="vehicle-ids"
            options                 ={vehicleData}
            filterOptions           ={(opts) => opts}
            renderInput             ={
                (params) => <TextField {...params} size="small" label={label} variant="outlined" type={"number"}
                                sx={{
                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                        WebkitAppearance: 'none',//'-webkit-appearance': 'none',
                                        margin: 0,
                                        padding: 0,
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,                                    
                                    startAdornment: (<InputAdornment position="start"><SearchOutlined /></InputAdornment>),
                                    endAdornment: (<React.Fragment>{loading ? <CircularProgress color="inherit" size={12} /> : null}{params.InputProps.endAdornment}</React.Fragment>
                                    ),
                                }}
                            />}
            PaperComponent          ={({ children }) => (<Paper>{children}</Paper>)}
            autoHighlight           ={false}            
            freeSolo                ={true}            
        /> 
        <Snackbar open={errorMsg !== ""} autoHideDuration={5000} onClose={onCloseError} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
            <Alert onClose={onCloseError} severity="error" sx={{ width: '100%' }}>{errorMsg}</Alert>
        </Snackbar>
        </>       
    )
}

const MemSingleVehicleSearch = React.memo(SingleVehicleSearch)
export default MemSingleVehicleSearch;

