import React, {useCallback, useEffect, useState, useContext} from 'react'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid'
import { useParams, useHistory  } from "react-router-dom"
import MemSingleVehicleSearch from './SingleVehicleSearch'
import { ApplicationPaths, ApiPaths, ApiRoot } from '../../../../core/PathConstants'
import { fetchApiData, postApiData } from '../../../../core/FetchData'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import { format } from 'fecha'
import Button from '@mui/material/Button'
import { UserContext } from '../../../../core/UserContext'
import SnackAlert from './components/SnackAlert'
import { StyledTable } from './components/StyledDefaults'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const RePricing = ({country}) => {
    const [ini, setIni]     = useState(true);
    const {vehicleId = "0"} = useParams()
    const history           = useHistory()
    const userContext       = useContext(UserContext)
    const userEmail         = (userContext?.user?.upn ?? userContext?.user?.preferred_username ?? '').split('@')[0]

    const onVechicleSelection = useCallback((prm) => { 
        if ((!ini)) { 
            history.push((prm !== "" && !isNaN(prm) && prm !== vehicleId)
                ? `/${country}${ApplicationPaths.RePricing}/${prm}`
                : `/${country}${ApplicationPaths.RePricing}`);
        }
    }, [country, vehicleId, ini, history])

    const [loading, setLoading] = useState(false);
    const [error, setError]     = useState('');
    const [data, setData]       = useState(null);

    const [okStatus, setOkStatus]   = useState("");
    const [sendToSamChecked, setSendToSamChecked] = useState(false);

    const ExecFetchApiData = () => { 
        fetchApiData(
            `${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}/re-pricing-info/${userEmail}`, 
            (dat) => setData(dat), 
            setLoading  , 
            (err) => { setError(err || "Error while getting the data."); setData(null);}
        )
    }

    useEffect(() => { 
        if (!isNaN(vehicleId) && +vehicleId > 0) {
            setLoading(true)
            fetchApiData(
                `${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}/re-pricing-info/${userEmail}`, 
                (dat) => setData(dat), 
                setLoading  , 
                (err) => { setError(err || "Error while getting the data."); setData(null);}
            )
        } 
    }, [vehicleId, country, userEmail]);    

    const handleFormValuesSubmit = (e) => {
        e.preventDefault()
        
        postApiData(
            `${ApiRoot}/${country}/${ApiPaths.VehicleSpecs_Update_Pricing}`, 
            'POST', 
            { vehicleId, acceptedLocationId: data.loc_id, RequestorEmail: userEmail, DoNotSendToSAM: sendToSamChecked }, 
            () => { 
                setOkStatus("Information Saved!") 
                ExecFetchApiData()
                setTimeout(() => { history.push(`/${country}${ApplicationPaths.SpecsPricing}/${vehicleId}/pricing`) },1000)
            }, 
            (err) => setError(err || "Error while Saving the Price")  
        )
    }

    const handleChange = (event) => {
        setSendToSamChecked(event.target.checked);
    };

    useEffect(() => { setIni(false) }, []); 
    
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <AppBar position="static" sx={{ padding: '16px 8px', backgroundColor: "white"}}>
                    <Grid container>
                        <Grid item xs={9} lg={5}>
                            <MemSingleVehicleSearch country={country} onChange={onVechicleSelection}/>
                        </Grid>
                        <Grid item xs={1} lg={7}></Grid>
                    </Grid>
                </AppBar>
                <Box sx={{p:3}}>
                    
                    {   loading ? <CircularProgress color="inherit" size={40} /> :
                        error   ? <ErrorOutlineIcon sx={{color: 'primary.main'}} /> : 
                        data && (
                        <Stack sx={{ width: '100%' }} spacing={1}>
                            {data.messages.map((x,i) => <Alert key={i} severity="warning">{x}</Alert>)}
                                    
                            <TableContainer>
                                <StyledTable size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{width: '10%', backgroundColor: 'background.grey100'}}><b>Unit #</b></TableCell>
                                            <TableCell sx={{width: '20%'}}>{vehicleId}</TableCell>
                                            <TableCell sx={{width: '10%', backgroundColor: 'background.grey100'}}><b>Accepted at</b></TableCell>
                                            <TableCell sx={{width: '20%'}}>{data?.locationName ?? ''}</TableCell>
                                            <TableCell sx={{width: '10%',backgroundColor: 'background.grey100'}}><b>Accepted by</b></TableCell>
                                            <TableCell>{data?.userName ?? ''}{data.acceptedDate && format(new Date(data.acceptedDate), ' [on] M/D/YYYY [at] HH:mm A')}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </StyledTable>
                            </TableContainer>

                            
                            <TableContainer component={Paper}>
                                <StyledTable size="small">
                                    <TableHead>
                                        <TableRow sx={{backgroundColor: 'background.grey100'}}>
                                            <TableCell><b>Pricing Type - {data.modelType}</b></TableCell>
                                            <TableCell align="right"><b>Ask</b></TableCell>
                                            <TableCell align="right"><b>Take</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.vehiclePricingDetailList?.map(x => (
                                            <TableRow key={x.vehiclePricingId}>
                                                <TableCell>
                                                    <b style={{color: 'grey', display: 'block'}}>{x.typeDescription}</b>
                                                    {x.detailName}
                                                </TableCell>
                                                <TableCell align="right">{x.amount}</TableCell>
                                                <TableCell align="right">{x.take}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow key="totals" sx={{backgroundColor: 'background.grey100'}}>
                                            <TableCell><b>Totals</b></TableCell>
                                            <TableCell align="right"><b>{data.totalAsk}</b></TableCell>
                                            <TableCell align="right"><b>{data.totalTake}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </StyledTable>
                            </TableContainer>
                                          
                            <TableContainer>
                                <StyledTable size="small">
                                    <TableBody>
                                       <TableRow>
                                            {/*<TableCell key="conditionVal1" align={"left"} sx={{width:'45%'}} >*/}
                                            {/*    <FormControlLabel*/}
                                            {/*                control={<Checkbox onChange={handleChange}   />} */}
                                            {/*                label="Do Not Push to Other Systems"                                                             */}
                                            {/*    />*/}
                                            {/*</TableCell>*/}

                                            <TableCell key="conditionVal" align={"center"} sx={{width:'65%'}}>
                                                 <form onSubmit={handleFormValuesSubmit}>
                                                    <Button variant="contained" type='submit' size='small'  >save</Button>
                                                </form>
                                            </TableCell>
                                        </TableRow> 
                                    </TableBody>
                                </StyledTable>
                            </TableContainer>
                        </Stack>
                        )
                    }
                </Box>
            </Box>
            
            <SnackAlert errorMsg={error} onCloseError={()=> {setError('')}} okMsg={okStatus} onCloseOk= {()=> setOkStatus('')}/>
        </>
    )
}

export default RePricing