export const ExclusionTypes = {
    ListIndividualVehicles: 'List Individual Vehicles'
};

export const DiscountTypes = {
    Amount: 'Amount ($)',
    Fixed: 'Fixed Price',
    PercentTake: '% of Take',
    PercentAsk: '% of Ask',
    DollarUpToPercent: '$ up to %',
    PercentUpToDollar: '% up to $'
};