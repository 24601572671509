import { CardActionArea, Grid, Card, CardContent, Typography, CardMedia, Container, Box, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

import React from 'react';


export const  MainPage = () => {
  
    return (
        <Container>
            <Paper
                sx={{
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    mb: 4,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center',
                    backgroundImage: `url(${'/images/RyderBackground1.webp'})`,
                }}
            >
                {/* Increase the priority of the hero background image */}
                {<img style={{ display: 'none' }} src="/images/RyderBackground1.webp" alt="Ryder" />}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.5)',
                    }}
                />
                <Grid container>
                    <Grid item md={6}>
                        <Box
                            sx={{
                                position: 'relative',
                                p: { xs: 3, md: 6 },
                                pr: { md: 0 },
                            }}
                        >
                            <Typography component="h1" variant="h2" color="inherit" >
                                UVS Pricing
                            </Typography>
                            {/*<Typography variant="h5" color="inherit" paragraph>*/}
                            {/*    This is the description*/}
                            {/*</Typography>                           */}
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {/*<Typography component="h2" variant="h4" my={2}>UVS Pricing</Typography>*/}
            <Paper sx={{p:4}}>
            <Typography component="h2" variant="h5" my={1} color="text.secondary">Please select a country to start pricing.</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} >
                    <CardActionArea component={Link} to='/us'>
                        <Card>
                             <CardMedia
                                component="img"                               
                                image="/images/USFlag.jpg"
                                height={280}
                                alt="USA"
                            />
                            <CardContent >
                                <Typography component="h2" variant="h5">
                                    US Pricing
                                </Typography>                 
                            </CardContent>
                           
                        </Card>
                    </CardActionArea>
                </Grid>
                <Grid item xs={6} >
                    <CardActionArea component={Link} to='/ca'>
                        <Card >
                             <CardMedia
                                component="img"
                                height={280}
                                image="/images/CanadianFlag.jpg"
                                alt="CANADA"
                            />
                            <CardContent>
                                <Typography component="h2" variant="h5">
                                    Canada Pricing
                                </Typography> 
                            </CardContent>
                           
                        </Card>
                    </CardActionArea>
                </Grid>
                </Grid>
            </Paper>
      </Container>
    );
 
}
