import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography, Paper,  Button, Grid, TextField} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiPaths, ApiRoot } from '../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../core/FetchData';
import AlertMessage from '../../../core/AlertMessage';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from 'react';


export const ClearAuthorization = function ({country}) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [openConfirm, setConfirmOpen] = React.useState(false);


    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');

    const [vehicleId, setVehicleId] = useState(null);


    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const onError = (errMsg) => {
        showAlert(errMsg, 'error');
    };

    const onDelComplete = () => {
        showAlert('Vehicle Pricing Authorization deleted', 'success');
        setVehicleId(null);
        setData(null);
       
    };

    //DEL functions
    //on click yes to delete
    const handleYes = () => {
        setConfirmOpen(false);
        if (vehicleId) {
            postApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleAuthorization}/${vehicleId}`, 'DELETE', null, onDelComplete, onError);
        }
    };


    const onDel = (id) => {

        setConfirmOpen(true);
    }



    const onData = (d) => {
        if (!d)
            onError('Vehicle Authorization does not exist');
        setData(d);
    }

    //fetch data
    const fetchData = () => {

        if (vehicleId) {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleAuthorization}/${vehicleId}/exists`, onData, setIsLoading, onError);
           
        }
        else
            onError('Please enter a vehicle #');

    }


    const handleChange = (e) => {
        const value = e.target.value;
        setVehicleId(value);
    }

  


    return (
        <div>
            <Typography variant="h5" style={{ margin: 3 }}>Clear Vehicle Authorization</Typography>
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={4}>
                    <Grid item xs={3} align="right">
                        <Typography variant="h6">Vehicle #:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            hiddenLabel
                            variant="outlined"
                            size="small"
                            name="vehicleId"
                            fullWidth
                            value={vehicleId || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <Button onClick={fetchData} color="primary" variant="contained" endIcon={<SearchIcon />}>Go</Button>
                    </Grid>
                </Grid>
            </Paper>

            {data && 
                <Fragment>
                   
                    <Grid container>
                        <Grid item xs={12}>
                            <Button onClick={onDel} color="primary" style={{ margin: 12 }} variant="contained" endIcon={<DeleteIcon />}>Clear</Button>
                        </Grid>
                    </Grid>
                </Fragment>
            }
            {/*Open dialog*/}
            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Clear Vehicle Authorization"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to remove this vehicle price authorization?
                             </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} color="primary">Yes</Button>
                    <Button onClick={handleConfirmClose} color="primary" autoFocus>No</Button>
                </DialogActions>
            </Dialog>
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div>
    );

}
