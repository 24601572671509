import React, { useEffect, useState } from 'react';
import { Dialog,  Typography,  Paper, LinearProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { ApiPaths, ApiRoot } from '../../../core/PathConstants';
import { fetchApiData } from '../../../core/FetchData';
import AlertMessage from '../../../core/AlertMessage';
import { LocationForm } from './LocationForm';
import { TableGrid } from '../../../core/TableGrid';



export const Locations = ({country}) => {
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setConfirmOpen] = React.useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');


    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const handleClose = () => {
        setOpen(false);
    };

    const onError = (err) => {
        showAlert('Error: ' + err, 'error');
    };

   
    const onErrorLoading = (status) => {
        showAlert('Error retrieving data');
    };


    const handleSave = () => {
        setOpen(false);
        //refresh
        showAlert('UTC Location successfully saved', 'success')
        fetchData();
    };

    const onEdit = (id) => {
        setSelectedId(id);
        setOpen(true);

    }
   

    //fetch data
    const fetchData = () => {
        setIsLoading(true);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Locations}`, onData, setIsLoading, onErrorLoading);
    }

    const onData = (d) => {

        
        setData(d);
    }

    useEffect(() => {
        fetchData();
    }, []);


    //column definition 
    const columns = [
        {
            field: 'loc_id',
            headerName: ' ',
            disableColumnMenu: true,
            hideSortIcons:true,
            disableClickEventBubbling: true,           
            renderCell: (params) => (               
                    <IconButton aria-label="Edit"
                        onClick={(event) => {
                            event.ignore = true;
                            onEdit(params.value);
                        }
                        } >
                        <EditIcon />
                    </IconButton>
                  
            ),
        },
        {
            field: 'loc_name', headerName: 'Location Name', flex: 1,
           
        },
        {
            field: 'area', headerName: 'Area', width:300,

        },
         
    ];

   

    return (
        <div>
            <Typography variant="h5" style={{margin:3}}>Maintain Locations</Typography>
            <Paper>
                {(isLoading) ? <LinearProgress /> :
                    <div style={{ height: 640, width: '100%' }}>
                        <TableGrid rows={data} columns={columns} keycol="loc_id" />
                    </div>
                    }
            </Paper>
            {/*Open dialog*/}
            <Dialog open={open} aria-labelledby="form-dialog-id" fullWidth maxWidth="md">
                <LocationForm id={selectedId} onCancel={handleClose} onError={onError} onSave={handleSave} country={country}/>
            </Dialog>           
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div> 
    );
 
}
