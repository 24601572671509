import React, { useState, useCallback, useContext, useEffect } from 'react'
import MemSingleVehicleSearch from './SingleVehicleSearch';
import { UserContext } from '../../../../core/UserContext';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card';
import { fetchApiData, postApiData } from '../../../../core/FetchData'
import { ApiPaths, ApiRoot, ApplicationPaths } from '../../../../core/PathConstants'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import FieldsTabs, {MenuTabs} from './components/FieldsTabs';
import SnackAlert from './components/SnackAlert';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useParams, useHistory  } from "react-router-dom"
import Typography from '@mui/material/Typography';

const SpecsEdit = ({ country }) => {
    const [ini, setIni]             = useState(true);
    const {vehicleId: prmVehicleId = "0"} = useParams()
    const history                   = useHistory()
    const [loading, setLoading]     = useState(false);
    const [error, setError]         = useState("");
    const [okStatus, setOkStatus]   = useState("");
    const [data, setData]           = useState([]);

    const userContext   = useContext(UserContext);
    const userEmail     = (userContext?.user?.upn ?? userContext?.user?.preferred_username ?? '').split('@')[0]
    const userID = userContext?.details?.userID;

    const [vehicleUpdates, setVehicleUpdates] = useState(0)
    const [vehicleId, setVehicleId] = useState(-1)

    const onVechicleSelection = useCallback( (prm) => {
        if ((!ini)) { 
            history.push((prm && !isNaN(prm)) 
                ? `/${country}${ApplicationPaths.SpecsEdit}/${prm}`
                : `/${country}${ApplicationPaths.SpecsEdit}`);
        }
    },[ini,country])

    useEffect(() => { 
        if (!isNaN(prmVehicleId) && +prmVehicleId > 0) {
            if (+prmVehicleId !=  vehicleId){
                setVehicleId(+prmVehicleId);            //-> else : nothing should happen
                setSelectedTab(2)
            }
        } else 
            setVehicleId(-1)
    }, [prmVehicleId, country]);   


    useEffect(() => { 
        if (vehicleId > 0 && userEmail !== "") {
            setLoading(true);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}/specs-for-edit/${userEmail}`, 
                onDataReady , 
                setLoading  , 
                (err) => onError( err ?? "Error while getting the data.")
            );
        } 
    }, [vehicleId, country, userEmail, vehicleUpdates]);

    useEffect(() => { 
        if (!(vehicleId > 0)) 
            setData([])
    }, 
    [vehicleId]);

    const onDataReady = (dat) => setData(dat)     //-> setSelectedTab(((dt?.specsFields ?? [])[0])?.category.catId ?? 2)
    const onError     = (err) => { 
        setData([])
        setError(err || "Error!") 
    }

    const [selectedTab, setSelectedTab] = React.useState(2)
    const handleChangeTabMenu = (_, newValue) => setSelectedTab(newValue)

    const onSubmitSpecs = (categoryId, specList) => {
        postApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs_Update}`, 
            'POST', 
            { vehicleId, categoryId, specList, userID }, 
            () => {
                setOkStatus("Information Saved!");
                setVehicleUpdates(vehicleUpdates + 1); //=> Nara ask                
            }, 
            (err) => { 
                setError(err ?? "Error While Updating the Specs")
                setVehicleUpdates(vehicleUpdates + 1);
            });
    }

    useEffect(() => { setIni(false) }, []);    

    const categories = data?.specsFields?.map(x => x.category) || [] ;
    const currentCategory = categories?.find( x => x.catId === selectedTab) || null;

    return (
        <>
            <Box sx={{display: 'flex', width: '100%', pt: 2}}>
                <Box sx={{minWidth: "260px"}}>
                    <Card elevation={3}>
                        <CardContent>
                            <MemSingleVehicleSearch country={country} onChange={onVechicleSelection} label={'Unit Number'}/>
                        </CardContent>
                        {((data?.specsFields?.length ?? 0) > 0) && 
                            <MenuTabs categories= {categories} selectedTab={selectedTab} handleChange={handleChangeTabMenu}/>                            
                        }
                    </Card>
                </Box>
                <Box sx={{flexGrow: 1, pl: 3}}>
                    {
                        loading 
                            ? <Alert severity="info" icon={<CircularProgress color="inherit" size={40} fontSize="inherit"/>}><AlertTitle><strong>searching</strong></AlertTitle> ... vehicle number {vehicleId}</Alert> :
                        (error && error !== '')   
                            ? <Alert severity="error" icon={<ErrorOutlineIcon sx={{color: 'primary.main'}} />}> {error}</Alert> : 
                        ((data?.specsFields?.length ?? 0) > 0) 
                            ? 
                            <>
                            <Box sx={{textTransform:'uppercase', color:'slategray', pt: 1, pb: 2}}>
                                <Typography variant="subtitle1" sx={{lineHeight: '1.2em'}}>vehicle number <strong>{data.vehicleId}</strong></Typography>
                                <Typography variant="h6" sx={{lineHeight: '1em'}}>EDIT {currentCategory?.catDesc} </Typography>
                            </Box>

                            <Paper elevation={3}>                    
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <FieldsTabs 
                                        fields          ={data.specsFields} 
                                        selectedTab     ={selectedTab} 

                                        country         ={country} 
                                        vehicleId       ={vehicleId}
                                        requestorGroupId={data.requestorGroupId} 
                                        locationId      ={data.loc_id} 
                                        locationName    ={data.locationName}
                                        acceptedDateStr ={data.acceptedDateStr}
                                        acceptedBy      ={data.acceptedBy}
                                        rideGoNoGo      ={data.rideGoNoGo}
                                        engineCosts     ={data.engineCosts}
                                        vehicleCondition={data.vehicleCondition}
                                        onSubmitSpecs   ={onSubmitSpecs}
                                    />
                                </LocalizationProvider> 
                            </Paper>
                        </>
                            : 
                            <p><i>Provide a valid unit number to continue...</i></p>
                        } 
                </Box>
            </Box>
            {error && error !== '' &&
                <SnackAlert errorMsg={error} onCloseError={()=> {setError('')}} okMsg={okStatus} onCloseOk= {()=> setOkStatus('')}/>
            }
        </>
    )
}

export default SpecsEdit



