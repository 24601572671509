import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ConfirmationPopup({ open, onClose, onConfirm, message }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirm-dialog-description"
            fullWidth
        >
            <DialogTitle id="confirmation-dialog-title" style={{textAlign: 'center'}}>Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText style={{textAlign: 'center'}}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color="inherit" onClick={onClose}>
                    No
                </Button>
                <Button variant='contained' color="primary" onClick={onConfirm}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationPopup;
