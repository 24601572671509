import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper, Button, LinearProgress} from '@mui/material';
import { ApiPaths, ApiRoot } from '../../core/PathConstants';
import { fetchApiData, postApiData } from '../../core/FetchData';
import AlertMessage from '../../core/AlertMessage';
import { Fragment } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { read, utils, writeFileXLSX } from 'xlsx';
import { darken, lighten } from '@mui/material/styles';

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);


const useStyles = makeStyles((theme) => ({
   
    tableHeader: {
      //  fontWeight: 'bold !important', backgroundColor: theme.palette.secondary.light, color: theme.palette.secondary.contrastText
    },
}));


export const BulkPricing = ({country, isTemp}) => {
    const [fileName, setFileName] = React.useState('');
   
    const [dataObj, setDataObj] = useState({ rowIndex: 0, rowCount: 0, rowData: [], sourceData: [], vehIds:[] });

    //const [data, setData] = useState([]);
    //const [vehicleIds, setVehicleIds] = useState([]);   
    //const [rowCount, setRowCount] = useState(0);
    //const [rowIndex, setRowIndex] = useState(0);
    //const [excelData, setExcelData] = useState([]);



    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    //const [searchFilter, setSearchFilter] = useState('');
    const classes = useStyles();
   

    const PAGE_SIZE = 10;
    

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const onError = (msg) => {
        setIsLoading(false);
        showAlert('Error: ' + msg);
    };
 
  

    const onUploadComplete = (d) => {

        //process data
        //console.log('Records Returned:', d.length);
        //console.log(dataObj);
        if (dataObj.rowCount == 0 || !isLoading) return;

        if (Array.isArray(d)) {
            let vehicleId = 0, totalTake = 0, totalAsk = 0, notes = '';

            //console.log("initial lengh:", dataObj.rowData.length);
            let newData = [...dataObj.rowData];
            //get ids for this call
            let vIds = dataObj.vehIds.slice(dataObj.rowIndex, dataObj.rowIndex + PAGE_SIZE);
            d.forEach((item, ix) => {
                if (vehicleId !== item.vehicleId && vehicleId !== 0) {
                    //add new row
                    newData.push({ id: vehicleId, vehicleId, totalTake, totalAsk, notes });
                    totalTake = 0;
                    totalAsk = 0;
                    notes = '';
                    let ix = vIds.indexOf(vehicleId);
                    if (ix > -1)
                        vIds.splice(ix, 1);
               
                }
                vehicleId = item.vehicleId;
                totalTake += item.takePrice;
                totalAsk += item.askPrice;
                notes += `${item.priceTypeName} with take: ${item.takePrice} and ask: ${item.askPrice}. `;
            });

            if (vehicleId !== 0) {
                newData.push({ id: vehicleId, vehicleId, totalTake, totalAsk, notes });
                let ix = vIds.indexOf(vehicleId);
                if (ix > -1)
                    vIds.splice(ix, 1);
            }

            //check missing veh ids and add them
            vIds.forEach((item) => {
                newData.push({ id: item, vehicleId: item, totalTake: 0, totalAsk: 0, notes: '' });
            });
            //console.log('Non-priced:', vIds.length);

            let ix = dataObj.rowCount;
              
            if ((dataObj.rowIndex + PAGE_SIZE) < dataObj.rowCount) {
                
                ix = dataObj.rowIndex + PAGE_SIZE;
            }

            //console.log("final lengh:", newData.length);

            setDataObj({ ...dataObj, rowIndex: ix, rowData: newData });
        }
    };

   

    useEffect(() => {
       
        if (dataObj.rowIndex < dataObj.rowCount) {
            let ix = dataObj.rowIndex;
            console.log(ix);

            let bp = dataObj.sourceData.slice(ix, ix + PAGE_SIZE);
            console.log(bp);
            if(isLoading)
            postApiData(`${ApiRoot}/${country}/${ApiPaths.BulkPricing}`, 'POST', bp, onUploadComplete, onError);
            
        } else if (dataObj.rowCount > 0) {
           //console.log(dataObj.rowIndex);
            console.log("Finish Loading:", new Date().toLocaleString());
            setIsLoading(false);

        }


    }, [dataObj]);

    //column definition 
    const columns = [        
        {
            field: 'vehicleId', headerName: 'Vehicle Number', width: 200,
            disableColumnMenu: true,
            //hideSortIcons: true,
        },
        {
            field: 'totalTake', headerName: 'Total Take', width: 120,
            align: 'right',
            type: 'number',
            disableColumnMenu: true,
            filterable: false,
            //hideSortIcons: true,
        },
        {
            field: 'totalAsk', headerName: 'Total Ask', width: 120,
            align: 'right',
            type: 'number',
            filterable: false,
            disableColumnMenu: true, 
            //hideSortIcons: true,
        }, 
        
        {
            field: 'notes', headerName: 'Notes', flex:1,
            filterable: false,
            disableColumnMenu: true,
            hideSortIcons: true,
        },

    ];


    const cancelLoading = () => {
        setIsLoading(false);
        setDataObj({ ...dataObj, rowCount: 0 , sourceData:[]});
    }


    const onFileUpload =(e) => {
         e.preventDefault();        
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                console.log("Start Loading:", new Date().toLocaleString());
                const data = e.target.result;
                const workbook = read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = utils.sheet_to_json(worksheet);

                let vehIds = [];
                   //save the vehicles ids
                if (Array.isArray(json)) {
                    json.forEach((item) => {
                       vehIds.push(Object.values(item)[0]);
                    });
                    //setVehicleIds(vehIds);
                    
                    
                }

                //setData([]);
                //paging code
                if (json.length > 0) {
                    //setExcelData(json);
                    //setRowCount(json.length);
                    //setRowIndex(0);

                    //let bp = json.slice(rowIndex, rowIndex + PAGE_SIZE);
                    //postApiData(`${ApiRoot}/${country}/${ApiPaths.BulkPricing}`, 'POST', bp, onUploadComplete, onError);

                    setDataObj({ rowIndex: 0, rowCount: json.length, rowData: [], sourceData: json, vehIds: vehIds })
                }
                else {
                    showAlert('This file doesn\'t have any row');
                }
                
            };
            reader.readAsArrayBuffer(e.target.files[0]);
            setFileName(e.target.files[0]?.name);
            setIsLoading(true);

        }
    }

    return (
        <div>
            <Typography variant="h5" sx={{ m: 1 }}>Bulk Vehicle Pricing</Typography>

            <Paper>
                <Typography variant="caption" component="div" sx={{ pt: 2, px: 2 }}>
                    Please select an Excel file with this format:
                    <ul>
                        <li>
                            First Colunm should contain the vehicle number.
                        </li>
                        <li>
                            Name of the specs should be in the FIRST row.
                        </li>
                        <li>
                            Name of the spec should be the same name of the field in database table or the same Name of the spec in UVS Pricing.
                        </li>
                        <li>
                            Columns with unknown spec names will be ignored.
                        </li>
                     </ul>

                </Typography>

                <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="button-file"
                    multiple
                    type="file"
                    onChange={onFileUpload}
                    onClick={(e)=> e.target.value = ''}
                />
                <label htmlFor="button-file">
                    <Button component="span" disabled={isLoading} sx={{ m: 1 }} variant="contained" className={classes.button}>
                        Upload Excel File
                    </Button>
                </label>
                <Button disabled={!isLoading} sx={{ m: 1 }} variant="contained" onClick={cancelLoading} >
                    Cancel
                </Button>

                {isLoading &&
                    <Fragment>
                    <Typography variant="h6" sx={{ m: 1 }}>Processing {fileName}...{dataObj.rowIndex} of {dataObj.rowCount}</Typography>
                    <LinearProgress variant="determinate" value={dataObj.rowIndex * 100 / dataObj.rowCount} />
                    </Fragment>
                }
                { fileName &&
                        <Fragment>
                        <Box sx={{
                            height: 580, width: '100%',
                            '& .row-theme-no-priced': {
                                bgcolor: (theme) =>
                                    getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                                '&:hover': {
                                    bgcolor: (theme) =>
                                        getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
                                },
                            }
                        }}>
                            <DataGrid sx={{ m: 1 }} density="compact"
                                initialState={{
                                    filter: {
                                        filterModel: {
                                            items: [{ columnField: 'vehicleId', operatorValue: 'startsWith', value: '' }],
                                        },
                                    },
                                }}
                                getRowClassName={(params) => (params.row.totalAsk === 0) ? 'row-theme-no-priced' : ''}
                            rows={dataObj.rowData} columns={columns} components={{ Toolbar: GridToolbar }} />
                        </Box>
                    </Fragment>
                    
                }
            </Paper>
           
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </div>
    );

}
