import { CardActionArea, Grid, Container, Card, CardContent, Typography, CardMedia, Box, Paper, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import React, { useEffect, useState, Fragment } from 'react';
import { ApprovalMenuItems, containsGroup, containsRole } from '../../MenuConfig';
import { useContext } from 'react';
import { UserContext } from '../../core/UserContext';

export const ApprovalHome = ({ country }) => {
    const user = useContext(UserContext);

    return (
        <Container>
            <Paper
                sx={{
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    mb: 4,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center',
                    backgroundImage: `url(${'/images/RyderBackground1.webp'})`,
                }}
            >
                {/* Increase the priority of the hero background image */}
                {<img style={{ display: 'none' }} src="/images/RyderBackground1.webp" alt="Ryder" />}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.5)',
                    }}
                />
                <Grid container>
                    <Grid item md={6}>
                        <Box
                            sx={{
                                position: 'relative',
                                p: { xs: 3, md: 6 },
                                pr: { md: 0 },
                            }}
                        >
                            <Typography component="h4" variant="h4" color="inherit" >
                                UVS Pricing
                            </Typography>                                                     
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Typography component="h2" variant="h5" my={1} color="text.secondary">
                Approvals Menu
            </Typography>
            <Paper sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        {ApprovalMenuItems.map((item, index) => (
                            <Fragment>
                                {
                                    (containsGroup(item.groups, user?.groups) || containsRole(item.roles, user?.details?.title)) && item.icon &&
                                    <Grid item xs={6}>
                                        <Button startIcon={item.icon} component={Link} to={`/${country}${item.link}`}>
                                            {item.text}
                                        </Button>
                                    </Grid>
                                }
                            </Fragment>

                        ))}

                    </Grid>               
            </Paper>          

        </Container>
    );
 
}
