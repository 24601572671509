import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CustomTextField = ({ value, type, onChange, onBlur, onFocus, readOnly = false, allowNegative = false, ...props }) => {
    const [inputValue, setInputValue] = useState(value);

    const formatValue = useCallback((input) => {
        if (!input || isNaN(Number(input)) || (!allowNegative && input <= 0)) return '';

        switch (type) {
            case 'percent':
                return new Intl.NumberFormat('en-US', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(input / 100);
            case 'currency':
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(input);
            default:
                return input;
        }
    }, [allowNegative, type]);

    useEffect(() => {
        setInputValue(formatValue(value));
    }, [formatValue, value]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;
        if (type === 'percent' || type === 'currency') {
            if (regex.test(value)) {
                setInputValue(value);
            }
        } else {
            setInputValue(value);
        }
    };

    const handleFocus = () => {
        setInputValue(value);
        if (onFocus) onFocus(value);
    };

    const handleBlur = () => {
        setInputValue(formatValue(inputValue));
        if (onChange) onChange(inputValue);
        if (onBlur) onBlur(inputValue);
    };

    return (
        <TextField
            {...props}
            value={inputValue || ''}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputProps={{
                readOnly,
                endAdornment: !readOnly && inputValue && (
                    <IconButton
                        size="small"
                        style={{ margin: 0, marginRight: '-10px' }}
                        onClick={() => {
                            setInputValue('');
                            if (onChange) onChange('');
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ),
            }}
        />
    );
};

export default CustomTextField;
