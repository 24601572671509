import React, { useEffect, useState, Fragment } from 'react';
import {
    DialogTitle, DialogContent, DialogActions, FormControlLabel, TextField, Paper, Button, Checkbox, LinearProgress, FormControl, InputLabel, Select,
    MenuItem, Grid, List, ListItem, ListItemText, NativeSelect, Typography
} from '@mui/material';
import { ApiPaths, ApiRoot } from '../../../../core/PathConstants';
import { postApiData, fetchApiData } from '../../../../core/FetchData';
import { TextFieldValidation } from '../../../../core/TextFieldValidation';
import { useForm, CheckboxControl, Form, ListboxControl, SelectControl } from '../../../../core/useForm';
import formValidation from '../../../../core/formValidation';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    listButton: {
        margin: theme.spacing(1)

    },
    panel: {
        margin: theme.spacing(1)

    }
}));

const initialValues = {
    specID: 0, catID: 0, specTypeID: 1, unitTypeID: null, specName: '', specLength: null, specDesc: '', specFROM: '', specTO: '',
    specEnabled: 0, specSortOrder: null, specTable: '', specField: '', samSpec: 0, samClasses: '', grouping: '', defaultValue: '',
    standardSpecCode: '', vmdSpecId: null
};
const fieldsValidation = {

    catID: {
        error: "Category is required",
        required: true,
    },
    specName: {
        error: "Spec Name is required",
        required: true,
    },

}

export const SpecsForm = ({ id, onSave, onCancel, onError, country }) => {
    const classes = useStyles();
    const [formError, setFormError] = React.useState({});

    const [data, setData, handleChange, validateForm] = useForm(initialValues, fieldsValidation, setFormError);

    //const [data, setData] = useState(initialValues); 
    const [isLoading, setIsLoading] = useState(true);
    const [specTypes, setSpecTypes] = useState([]);
    const [isSTLoading, setIsSTLoading] = useState(true);
    const [cats, setCats] = useState([]);
    const [isCatsLoading, setIsCatsLoading] = useState(true);
    const [specList, setSpecList] = useState([]);
    const [isSLLoading, setIsSLLoading] = useState(true);
    const [unitTypes, setUnitTypes] = useState([]);
    const [isUTLoading, setIsUTLoading] = useState(true);
    const [showListFields, setShowListFields] = useState(true);
    const [showValueFields, setShowValueFields] = useState(false);
    const [showTextFields, setShowTextFields] = useState(false);
    const [showMemoFields, setShowMemoFields] = useState(false);
    const [showTableFields, setShowTableFields] = useState(false);
    const [specListValue, setSpecListValue] = useState('');
    const [specListSort, setSpecListSort] = useState('');
    const [specListId, setSpecListId] = useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    ////const classes = useStyles();
    //const [formErrors, setFormErrors] = useState({});

    const handleListBoxChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === "specListSortOrder") {
            value = Number(value);
            setSpecListSort(value);
        }
        else {
            setSpecListValue(value);
        }


    }

    const handleListItemClick = (selValue) => {

        //show text values
        if (specList) {
            setSpecListId(selValue.specListValue);
            setSpecListValue(selValue.specListValue);
            setSpecListSort(selValue.specListSortOrder);

        }


    };

    const onAddSpecList = () => {
        setSpecListId('');
        setSpecListValue('');
        setSpecListSort('');
        setSelectedIndex(-1);
    }

    const onDelSpecList = () => {
        //post API DELETE
        if (specListId === '') {
            onError(": Select an Item to delete");
        }
        else {
            postApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/${id}/list`, 'DELETE', { specID: id, specListValue: specListValue, specListSortOrder: (specListSort === '' || isNaN(specListSort) ? null : Number(specListSort)) }, onComplete, onError);
        }
    }

    const onComplete = () => {
        //load spec list again
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/${id}/list`, setSpecList, setIsSLLoading, onSpecListError);
        onAddSpecList();
    }

    const onSaveSpecList = () => {
        if (specListValue) {
            if (!isSLLoading && specListId !== specListValue && specList.findIndex((it) => it.specListValue === specListValue) > -1) {
                //trying to insert a duplicate value
                onError(": Cannot insert duplicate List Value. Select the value to change or Add New");
            }
            else {
                setIsSLLoading(true);
                postApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/list`, 'POST', { specID: id, specListValue: specListValue, specListSortOrder: (specListSort === '' || isNaN(specListSort) ? null : Number(specListSort)), oldSpecListValue: specListId }, onComplete, onError);

            }
        }
        else {
            onError(": You must enter a list value");
        }
    }

    const onSpecType = e => {
        handleChange(e);

        let typeId = e.target.value;
        checkSpecType(typeId);
    }

    const checkSpecType = typeId => {
        // let typeSelected = specTypes.find(i => i.specTypeID == typeId).specTypeDesc;
        switch (typeId) {
            case 1: //"List":               
                setShowListFields(true);
                setShowValueFields(false);
                setShowTextFields(false);
                setShowMemoFields(false);
                break;
            case 2://"Value":

                setShowListFields(false);
                setShowValueFields(true);
                setShowTextFields(false);
                setShowMemoFields(false);
                break;
            case 3://"Text":

                setShowListFields(false);
                setShowValueFields(false);
                setShowTextFields(true);
                setShowMemoFields(false);
                break;
            case 4://"Memo":
            case 5://"Date":               
                setShowListFields(false);
                setShowValueFields(false);
                setShowTextFields(false);
                setShowMemoFields(true);
                break;
        }
    }


    function onSamSpec(e) {

        handleChange(e);
        setShowTableFields(e.target.checked);

    }


    //save the data
    const saveData = () => {

        //add
        if (validateForm()) {
            //set nullable
            data.unitTypeID = data.unitTypeID === null || isNaN(data.unitTypeID) ? null : Number(data.unitTypeID);
            data.specLength = data.specLength === null || isNaN(data.specLength) ? null : Number(data.specLength);
            data.specSortOrder = data.specSortOrder === null || isNaN(data.specSortOrder) ? null : Number(data.specSortOrder);
            data.specTypeID = data.specTypeID === null || isNaN(data.specTypeID) ? null : Number(data.specTypeID);
            data.catID = data.catID === null || isNaN(data.catID) ? null : Number(data.catID);
            data.specEnabled = data.specEnabled ? 1 : 0;
            data.samSpec = data.samSpec ? 1 : 0;
            data.vmdSpecId = Number(data.vmdSpecId) || null;

            postApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}`, 'POST', data, onSave, onError);
        }

    }



    const onSpecTypeError = (msg) => {

    }

    const onCatError = (msg) => {

    }

    const onDataError = (msg) => {

    }

    const onSpecListError = (msg) => {

    }

    const onUnitTypeError = (msg) => {

    }

    const onData = (d) => {
        setData(d);
        checkSpecType(d.specTypeID);
        setShowTableFields(d.samSpec === 1);
    }

    useEffect(() => {

        //fetch dropdowns
        //get spec types
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.SpecTypes}`, setSpecTypes, setIsSTLoading, onSpecTypeError);
        //categories
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.CategoriesEnabled}/true`, setCats, setIsCatsLoading, onCatError);
        //unittype
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.UnitTypes}`, setUnitTypes, setIsUTLoading, onUnitTypeError);

        //fetch the current record
        if (!id || id === 0) {
            setData(initialValues);
            setIsLoading(false);
            setIsSLLoading(false);
        }
        else {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/${id}`, onData, setIsLoading, onDataError);
            //speclist
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/${id}/list`, setSpecList, setIsSLLoading, onSpecListError);

        }



    }, []);



    return (
        <Fragment>
            <DialogTitle id="form-dialog-title">{(id === 0 ? 'Add' : 'Edit')} Spec</DialogTitle>
            <DialogContent>
                {isLoading || isSTLoading || isCatsLoading || isSLLoading || isUTLoading ? <LinearProgress /> :
                    <Form>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    label="Spec Label"
                                    size="small"
                                    name="specName"
                                    variant="outlined"

                                    value={data?.specName || ''}
                                    required
                                    onChange={handleChange}
                                    error={!!formError.specName}
                                    helperText={formError.specName || ''}
                                />
                            </Grid>
                            {/*ShowFields*/}
                            <Grid item xs={6}>
                                <SelectControl
                                    label="Spec Type"
                                    id="specTypeID"
                                    options={specTypes}
                                    textValue="specTypeDesc"
                                    value={data?.specTypeID || 0}
                                    name="specTypeID"
                                    valueType="number"
                                    handleChange={onSpecType}

                                />

                            </Grid>
                            <Grid item xs={6}>
                                <SelectControl
                                    label="Category"
                                    id="catID"
                                    options={cats}
                                    textValue="catDesc"
                                    value={data?.catID || 0}
                                    name="catID"
                                    valueType="number"
                                    handleChange={handleChange}
                                    helperText={formError.catID || ''}
                                />
                            </Grid>
                            {(<Fragment>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label="Standard Spec Code"
                                        size="small"
                                        name="standardSpecCode"
                                        value={data?.standardSpecCode || ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>

                                    <TextField
                                        variant="outlined"
                                        label="VMD Spec Id"
                                        size="small"
                                        name="vmdSpecId"
                                        value={data?.vmdSpecId}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Fragment>)
                            }
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="SAM Classes"
                                    size="small"
                                    name="samClasses"

                                    value={data?.samClasses || ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Description"
                                    size="small"
                                    name="specDesc"

                                    value={data?.specDesc || ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            {/*List Options*/}
                            {showListFields &&
                                (<Grid container >
                                    <Grid item xs={6}>
                                        <Paper variant="outlined" className={classes.panel} >

                                            <ListboxControl
                                                label="Current Options"
                                                options={specList}
                                                textValue="specListValue"
                                                selectedIndex={selectedIndex}
                                                setSelectedIndex={setSelectedIndex}
                                                handleChange={handleListItemClick}
                                            />

                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {id === 0 ? <Typography variant="subtitle1" className={classes.panel}>To add new list options you will have to save first the new Spec.</Typography> :
                                            <Paper variant="outlined" className={classes.panel} >
                                                {/*Add/Edit Options*/}

                                                <TextField
                                                    variant="outlined"
                                                    label="Spec Option"
                                                    size="small"
                                                    name="specListValue"
                                                    value={specListValue || ''}
                                                    onChange={handleListBoxChange}
                                                />

                                                <TextField
                                                    variant="outlined"
                                                    label="Sort Order"
                                                    size="small"
                                                    name="specListSortOrder"
                                                    value={specListSort || ''}
                                                    onChange={handleListBoxChange}
                                                />
                                                <Grid container item xs={12} >
                                                    <Grid item xs={4} >
                                                        <Button variant="contained" disabled={specListId === ''} className={classes.listButton} onClick={onAddSpecList}>New</Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button variant="contained" className={classes.listButton} onClick={onSaveSpecList}>Save</Button>
                                                    </Grid>
                                                    <Grid item xs={4} >
                                                        <Button variant="contained" className={classes.listButton} onClick={onDelSpecList}>Del</Button>
                                                    </Grid>
                                                </Grid>

                                            </Paper>
                                        }
                                    </Grid>

                                </Grid>)}
                            {showValueFields &&
                                (
                                    <Fragment>
                                        {/*Unit Type*/}
                                        <Grid item xs={6}>
                                            <SelectControl
                                                label="Unit Type"
                                                id="unitTypeID"
                                                options={unitTypes}
                                                textValue="unitTypeDesc"
                                                value={data?.unitTypeID || 0}
                                                name="unitTypeID"
                                                valueType="number"
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                        </Grid>

                                        {/*Range*/}
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined" margin="dense"
                                                label="Range From"
                                                size="small"
                                                name="specFROM"

                                                value={data?.specFROM || ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                label="To"
                                                size="small"
                                                name="specTO"

                                                value={data?.specTO || ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Fragment>
                                )}
                            {/*Length*/}
                            {showTextFields &&
                                (
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Maximun Length"
                                            size="small"
                                            name="specLength"
                                            value={data?.specLength || ''}
                                            onChange={e => handleChange(e, 'number')}
                                        />
                                    </Grid>)}
                            <Grid item xs={12}>
                                <CheckboxControl value={!!data?.specEnabled} name="specEnabled" label="Enabled" handleChange={handleChange} />

                                {/*<FormControlLabel control={<Checkbox name="specEnabled"*/}
                                {/*    checked={!!data?.specEnabled}*/}
                                {/*    size="small"*/}
                                {/*    onChange={handleChange}*/}

                                {/*/>} label="Enabled" />*/}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    label="Sort Order"
                                    size="small"
                                    name="specSortOrder"
                                    value={data?.specSortOrder || ''}
                                    onChange={e => handleChange(e, 'number')}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    label="Grouping"
                                    size="small"
                                    name="grouping"
                                    value={data?.grouping || ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Default"
                                    size="small"
                                    name="defaultValue"
                                    value={data?.defaultValue || ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={saveData} color="primary">
                    Save
                </Button>
            </DialogActions>

        </Fragment>
    );

}
