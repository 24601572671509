import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IconButton, Popover, Grid } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';
import CustomDatePicker from '../../../../core/CustomDatePicker';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

const VehicleYearsSelector = ({ bucketDetails, setBucketDetails }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const minDate = useMemo(() => dayjs('2006'), []);
    const maxDate = useMemo(() => dayjs(), []);

    const [selectedDates, setSelectedDates] = useState({});
    const initialDatesRef = useRef({ yearFrom: null, yearTo: null });

    useEffect(() => {
        const { allYears, yearFrom, yearTo } = bucketDetails;

        const from = allYears ? minDate : yearFrom ? dayjs(`${yearFrom}`) : null;
        const to = allYears ? maxDate : yearTo ? dayjs(`${yearTo}`) : null;

        setSelectedDates({ yearFrom: from, yearTo: to });
        initialDatesRef.current = { yearFrom, yearTo };
    }, [bucketDetails, maxDate, minDate]);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        const currentYearFrom = selectedDates.yearFrom?.year();
        const currentYearTo = selectedDates.yearTo?.year();
        const { yearFrom, yearTo } = initialDatesRef.current;

        // Check if there are changes
        if (yearFrom !== currentYearFrom || yearTo !== currentYearTo) {
            const allYears = currentYearFrom === minDate.year() && currentYearTo === maxDate.year();

            setBucketDetails({
                yearFrom: allYears ? null : currentYearFrom,
                yearTo: allYears ? null : currentYearTo,
                allYears: allYears
            });
        }

        setAnchorEl(null);
    };

    const handleDateChange = (key, newValue) => {
        setSelectedDates(prevState => {
            if (key === 'yearFrom') {
                const newYearTo = newValue?.year() > prevState.yearTo?.year() ? null : prevState.yearTo;
                return { yearFrom: newValue, yearTo: newYearTo };
            } else {
                return { ...prevState, yearTo: newValue };
            }
        });
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            Year
            <IconButton
                onClick={handleButtonClick}
                aria-label="Select Date Range"
                aria-haspopup="true"
                color="inherit"
            >
                <CalendarMonth />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{ transform: 'translateX(1%)' }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2} padding={2}>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <IconButton onClick={handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <CustomDatePicker
                                label='From'
                                autoFocus={false}
                                disableHighlightToday
                                value={selectedDates.yearFrom}
                                minDate={minDate}
                                maxDate={maxDate}
                                openTo={'year'}
                                views={['year']}
                                onChange={(newValue) => handleDateChange('yearFrom', newValue)}
                                clearDate={() => handleDateChange('yearFrom', null)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomDatePicker
                                label='To'
                                autoFocus={false}
                                disableHighlightToday
                                value={selectedDates.yearTo}
                                minDate={minDate}
                                maxDate={maxDate}
                                openTo={'year'}
                                views={['year']}
                                onChange={(newValue) => handleDateChange('yearTo', newValue)}
                                clearDate={() => handleDateChange('yearTo', null)}
                                shouldDisableYear={date => selectedDates.yearFrom ? date.year() <= selectedDates.yearFrom.year() : false}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Popover>
        </div>
    );
};

export default VehicleYearsSelector;