import React, {useEffect, useState } from 'react'
import { FieldWrapper, FieldList, FieldText, FieldValue, FieldDate, FieldMemo, FieldMakeList } from './FieldForm'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { useForm } from "react-hook-form";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { fetchApiData, postApiData } from '../../../../../core/FetchData'
import { ApiPaths, ApiRoot, ApplicationPaths } from '../../../../../core/PathConstants'

const dummyUpdateObj = (objTarget, objSource) => {
    for (var prop in objSource) 
        objTarget[prop] = objSource[prop];
    return objTarget
}


export const AcceptFieldForm = ({
    groupings       ,
    samClassCode    ,
    rideGoNoGo      ,
    engineCosts     ,
    vehicleCondition,
    onSubmitSpecs,
    catId ,
    country ,
    vehicleId

}) => {

    const [sam, setSam] = useState(samClassCode);
    const [make, setMake] = useState('');                            //selected make 
    const [model, setModel] = useState('');                          //selected make
    const [makeListId, setMakeListId] = useState([]);                //list of make ids (for a category)
    const [makeListIdForSam, setMakeListIdForSam] = useState([]);    //list of spec ids for a sam class
    const [makeList, setMakeList] = useState([]);                    //list of Makes
    const [modelListId, setModelListId] = useState([]);              //list of model ids (for a category)
    const [modelList, setModelList] = useState([]);
    const [makeLoaded, setMakeLoaded] = useState(0);
    const [specId, setSpecId] = useState(0);

    const notRequiredSpecsIds = [253, 167]
    const [samCd, setSamCd] = useState(samClassCode);


    if (makeLoaded === 0) {
        for (let i = 0; i < groupings.length; i++) {
            let spec = groupings[i].fields.filter(x => x.standardSpecCode?.includes("MAKE"));
            let specModel = groupings[i].fields.filter(x => x.standardSpecCode?.includes("MODEL"));
            for (let j = 0; j < spec.length; j++) {
                makeListId.push(spec[j].specID);
                makeListIdForSam.push(spec[j].specID);
                makeList.push(spec[j]?.selectList);
            }
            for (let j = 0; j < specModel.length; j++) {
                modelListId.push(specModel[j]?.specID);
            }
        }
        setMakeLoaded(1);
    }

    const getGroupingsFiltered = (_samCd) => {
        let _groupings = []
        for (let index = 0; index < groupings.length; index++) {
            const _grp = groupings[index]
            const fields = _grp.fields.filter(x => x.samClasses == null || x.samClasses.includes(samCd)) || []
            if (fields.length > 0)
                _groupings.push({..._grp, fields})
        }
        return _groupings
    }
    
    const [filteredGrouping, setFilteredGrouping] = useState(() => getGroupingsFiltered(samClassCode));

    const getDefaultValues  = () => filteredGrouping.map(x => x.fields).flat()
            .map(x => ({ [`fld_${x.specID}`]: x.specVehicleValue || x.defaultValue || ''}))
            .reduce((a, b) => Object.assign(a, b), {})

    useEffect(() => { setFilteredGrouping(getGroupingsFiltered(samCd))}, [samCd])
    useEffect(() => { 
        const _existingValues   = getValues()
        const _defaultValues    = getDefaultValues()
        const _newDefault       = dummyUpdateObj(_defaultValues, _existingValues)
        reset(_newDefault); 
        setValue('fld_71', `${samCd}`, { shouldValidate: false })
    }, [filteredGrouping])

    const initialValues = getDefaultValues()

    const { handleSubmit, getValues, setValue, reset, control, watch } = useForm({ defaultValues: initialValues });

    const getOthersGroupSpec = (group, currentSpecID) => groupings.find(x => x.grouping == group)?.fields
        .filter(x => x.specID != currentSpecID)
        .map(x => `fld_${x.specID}`) ?? []
    
    const validateFieldList = (group, field, val) => { 
        const {specID, specName, specTypeDesc, specLength} = field;

        //--> Odometer Validation for Spec fields combination 117 & 189
        if (specID === 117) {
            const _refVal = field.specReferenceValue;
            if (val === "" && _refVal !== "")
                setValue('fld_117', _refVal, { shouldValidate: true })

            // if (    val !== "" 
            //     &&  /^-?\d+(\.\d{1,2})?$/.test(val)
            //     &&  /^-?\d+(\.\d{1,2})?$/.test(_refVal)
            //     ){
            //     const odomdiff = Math.abs((+_refVal) - (+val))
            //     if (odomdiff > 0 && odomdiff < 5000 ){
            //         setValue('fld_117', _refVal, { shouldValidate: true })
            //         return ("No mileage adjustments of less than 5,000 miles allowed.")
            //     }	
            // }

            if (    val !== "" 
                &&  /^-?\d+(\.\d{1,2})?$/.test(val))
            return true
        } 
        if (specID === 189){
            const _refVal = field.specReferenceValue; //=> from 117
            const _117Val = getValues('fld_117')
            
            if (val === "" && (+_refVal) !== (+_117Val))
                return "Provide the Odometer Override Reason";                
            
            if (val !== "" && _refVal === _117Val)
                return "Please clear the Odom Override Reason";
            
            return true
        }
        //--> Odometer Validation ends here

        //--> Reefer Validation for Spec fields combination 116 & 121 & 191 & 108
        if (specID === 116){
            if (val === ""){
                const _121Val = getValues('fld_121') //=> 121 is not always present .. ask Yamile
                if (_121Val === "Yes")
                    return true;
            }
            else {
                const _refVal = field.specReferenceValue;
                if (    /^-?\d+(\.\d{1,2})?$/.test(val)
                    &&  /^-?\d+(\.\d{1,2})?$/.test(_refVal) ){
                    const reefdiff = Math.abs((+_refVal) - (+val))
                    if (reefdiff > 0 && reefdiff < 250 ){
                        setValue('fld_116', _refVal, { shouldValidate: true })
                        return ("No Reefer Engine adjustments of less than 250 hours allowed.")
                    }
                }
            }
        }
        if (specID === 191){
            const _refVal = field.specReferenceValue; //=> from 116
            const _116Val = getValues('fld_116')
            if (val === "" && _refVal !== _116Val){
                const _108Val = getValues('fld_108')
                if (_108Val === "Yes")
                    return "Please enter Reefer Engine Hours Override Reason"
            }
            if (val !== "" && _refVal === _116Val)
                return "Please clear Reefer Engine Hours Override Reason"

            return true
        }     
        //--> Reefer Validation ends here   
        val = (specTypeDesc === "Date" && val instanceof Date) 
            ? val.toLocaleDateString('en-US')
            : (val ?? "").trim();
        if (val === "") {
            if (notRequiredSpecsIds.includes(specID) )
                return true
            if (group != ""){
                const others = getOthersGroupSpec(group,specID)
                if (others.length == 0)
                    return true;
                
                const anotherValueAlready = getValues(others).some(x => (x ?? '') != '');
                if (!anotherValueAlready) 
                    return true;
            }            
            return `${specName} required.`
        }
        if (specTypeDesc==="Text"){
            const strSize   = specLength > 25 ? 25 : (specLength + 2)
            if (val.length > strSize )
                return `${specName} invalid length value. No more than ${strSize} characters.`
        }
        if (specTypeDesc==="Value"){
            if (val.length > 14 )
                return `${specName} invalid length value. No more than 14 characters.`
            if (specID === 19 || specID === 78) {
                if (!(/^-?\d+?$/.test(val)))
                    return `${specName} invalid value`
            }
            else                 
                if (! (/^-?\d+(\.\d{1,2})?$/.test(val)))
                    return `${specName} invalid value`
            if (field.specFrom != "" && /^-?\d+(\.\d{1,2})?$/.test(field.specFrom) && +val < +(field.specFrom))
                return `${specName} can't be lower than ${field.specFrom}`
            if (field.specTo != "" && /^-?\d+(\.\d{1,2})?$/.test(field.specTo) && +val > +(field.specTo))
                return `${specName} can't be higher than ${field.specTo}`
        }
        //if (specTypeDesc==="Date"){}
        if (specTypeDesc==="Memo"){
            if (val.length > 1000 )
                return `${specName} invalid length value. No more than 1000 characters.`
        }
        //-> rest of the validations (custom specs)
        return true
    }

    const onChangeSam = (e) => {
        const val = e.target.value?.trim();
        if (val !== "" && /^\d+$/.test(val) && samCd !== +val )
            setSamCd(+val);
    } 

    const onGroupingClear = (grp) => 
        filteredGrouping.find(x => x.grouping == grp)?.fields.map(x => 
            x.specTypeDesc == "Date" 
            ?   setValue(`fld_${x.specID}`, null, { shouldValidate: false })
            :   setValue(`fld_${x.specID}`, '', { shouldValidate: false }));

    //----------
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name !== undefined) { 
                let idx = name.indexOf('_');
                setSpecId(name.substring(idx + 1));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const customOnChange = (data) => {

        if (data.target.name === 'Sam') {
            setSam(data.target.value)
            //LoadMakeList();
        }
        if (data.target.name === 'Make') {
            setMake(data.target.value)
            //setModel('');
            //LoadModelList();
        }
    }

    useEffect(() => {
        LoadMakeList();
    }, [sam]);

    const LoadMakeList = () => {
        if (sam > 0) {
            //let spec = (specId === 0) ? 7 : specId;
            let spec = 0;                                          

            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getMakes/${sam}/${vehicleId}/${spec}/${catId}`,
                onMakeReady
                //(err) => onError(err ?? "Error while getting the data.")
            );

            //Set SpecId and make - To load Make and Models 
            var tArrayList = [];
            var values = getValues();
            var tCount = 0;
            for (let i = 0; i < makeListId.length; i++) {
                let mlistKey = makeListId[i];
                for (let j = 0; j < Object.keys(values).length; j++) {
                    let idx = Object.keys(values)[j].indexOf('_');
                    let specid = parseInt(Object.keys(values)[j].substring(idx + 1));
                    if (mlistKey === specid && tCount===0) {
                        setSpecId(mlistKey);
                        setMake(Object.values(values)[j]);
                        tCount++;
                    }
                }
            }
            
        }
    }

    useEffect(() => {
        LoadModelList();
    }, [make]);

    const LoadModelList = () => {

        if (make !== "" && specId > 0) {              

            var tArrayList = [];
            var values = getValues();

            for (let i = 0; i < makeListId.length; i++) {
                let mlistKey = makeListId[i];
                for (let j = 0; j < Object.keys(values).length; j++) {
                    let idx = Object.keys(values)[j].indexOf('_');
                    let specid = parseInt(Object.keys(values)[j].substring(idx + 1));
                    if (mlistKey === specid) {
                        tArrayList.push({ "specId": LoadModelSpecId(specid), "Make": Object.values(values)[j] });
                    }
                }
            }

            //multiple
            let encodedObject = JSON.stringify(tArrayList);
            //fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make}/${vehicleId}/${LoadModelSpecId(specId)}/${encodedObject}`,
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make}/${vehicleId}/${0}/${encodedObject}`,
                onModelReady
                //(err) => onError(err ?? "Error while getting the data."),
            );
        }

    }

    const LoadModelsForMake = (make1, specId1) => {

        if (make1 !== "" && specId1 > 0) {

            //console.log(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make1}/${vehicleId}/${LoadModelSpecId(specId1)}}`);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/getModels/${catId}/${sam}/${make1}/${vehicleId}/${LoadModelSpecId(specId1)}`,
                onModelReady
                //(err) => onError(err ?? "Error while getting the data.")
            );
        }

    }

    const onMakeReady = (make) => {
        setMakeList(make);
    }

    const onModelReady = (model) => {
        setModelList(model);
    }

    const LoadModelSpecId = (SpecId) => {
        var makeModelDt = {
            86: 87, 7: 8, 88: 79, 44: 45, 98: 99, 84: 85, 10: 101, 81: 95
        };
        return makeModelDt[SpecId];
    }

    const onError = () => console.log("error while loading select list");

    //-----------


    const onSubmit = formdata => onSubmitSpecs(Object.keys(formdata).map((k) => ({key: k, text: formdata[k]})) || []);
    const labelStyle = {padding: "9px 0", display: "block", lineHeight: "1rem"}
    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={handleSubmit(onSubmit)}>
        { filteredGrouping.map(x => (
            <Grid key={x.grouping} container spacing={1}>
                { (x.grouping != "" ) && 
                    <Grid item key={x.grouping} xs={12} sx={{mt:"12px", mb:"6px"}}>
                        <Divider textAlign="left">
                            <label style={{ fontSize: '.9em', color: "gray", margin:"auto", textTransform:"uppercase"}}>{x.grouping}</label>
                            <Button variant="outlined" type='button' size='small' sx={{ml: 2, p: 0}} onClick={() => {onGroupingClear(x.grouping);}}>clear</Button>
                        </Divider>
                    </Grid> }
                { x.fields.map(f => { //-> ex 331701
                    //if (f.specID === 71)
                    //    //return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}><FieldList group={x.grouping} field={f} control={control} validate={validateFieldList} customOnChange={onChangeSam}/></FieldWrapper>
                    //    return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}><FieldMakeList group={x.grouping} field={f} control={control} validate={validateFieldList} Sam={sam} MakeListId={makeListId} MakeList={makeList} ModelListId={modelListId} ModelList={modelList} customOnChange={customOnChange} /></FieldWrapper>

                    switch(f.specTypeDesc){
                        //case "List" : return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldList   group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                        case "List": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldMakeList group={x.grouping} field={f} control={control} validate={validateFieldList} Sam={sam} MakeListId={makeListId} MakeList={makeList} ModelListId={modelListId} ModelList={modelList} customOnChange={customOnChange} /></FieldWrapper>

                        case "Text" : return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldText   group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                        case "Value": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldValue  group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                        case "Date" : return <FieldWrapper key={f.specID} id={f.specID} name={f.specName}           ><FieldDate   group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                        case "Memo": return <FieldWrapper key={f.specID} id={f.specID} name={f.specName} col={12}  ><FieldMemo group={x.grouping} field={f} control={control} validate={validateFieldList} /></FieldWrapper>
                        default: return null
                    }
                })}            
            </Grid>
        ))}        
        <Grid item key={'footer-div'} xs={12} sx={{mt:"12px", mb:"6px"}}>
                    <Divider textAlign="left"></Divider>
                </Grid>
                <Grid key={'footer'} container spacing={1} sx={{ mb:"8px"}}>
                    <FieldWrapper id={2003} name={"Ride Go | No Go | Maybe"}><b style={labelStyle}>{rideGoNoGo}</b></FieldWrapper>
                    <FieldWrapper id={2004} name={"Engine Repair Costs"}    ><b style={labelStyle}>{engineCosts}</b></FieldWrapper>
                    <FieldWrapper id={2005} col={12} name={"Condition"}     ><b style={labelStyle}>{vehicleCondition}</b></FieldWrapper>
                </Grid>
            <Stack alignItems="center" justifyContent="center" padding={2}>
                <Button variant="contained" type='submit' size='small'>continue</Button>
            </Stack>
           </form>                
        {/*<pre>{JSON.stringify(getDefaultValues(), null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(filteredGrouping, null, 2)}</pre> */}
        </LocalizationProvider>              
        </>
    )
}