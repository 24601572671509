import React, { useEffect, useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography,  Paper,  Select, MenuItem, Button, LinearProgress, Grid, TextField, Table, TableBody, TableRow, TableCell, TableContainer, Container } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import NextIcon from '@mui/icons-material/NavigateNext';
import { ApiPaths, ApiRoot } from '../../core/PathConstants';
import { fetchApiData, postApiData } from '../../core/FetchData';
import AlertMessage from '../../core/AlertMessage';
import { TableGrid } from '../../core/TableGrid';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import { SelectControl } from '../../core/useForm';


import { Fragment } from 'react';
import MemSingleVehicleSearch from '../admin/main/specs/SingleVehicleSearch';


export const DupVehicle = function ({country}) {
    const [isLoading, setIsLoading] = useState(false);

    const [isLoadingOdom, setIsLoadingOdom] = useState(false);
    const [isLoadingReefer, setIsLoadingReefer] = useState(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);

    const [showOdom, setShowOdom] = useState(false);


    const [samOdom, setSamOdom] = useState(0);
    const [odom, setOdom] = useState(0);

    const [electricStandby, setElectricStandby] = useState('');
    const [engineDrive, setEngineDrive] = useState('');



    const [fisReeferHrs, setFisReeferHrs] = useState(0);
    const [reeferHrs, setReeferHrs] = useState(0);
    const [odomReason, setOdomReason] = useState('');
    const [hrsReason, setHrsReason] = useState('');

    const [odomReasons, setOdomReasons] = useState([]);
    const [hrsReasons, setHrsReasons] = useState([]);

    //helper texts
    const [odomError, setOdomError] = useState('');
    const [odomReasonError, setOdomReasonError] = useState('');
    const [reeferError, setReeferError] = useState('');
    const [reeferReasonError, setReeferReasonError] = useState('');

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');

    const [vehicleId, setVehicleId] = useState(-1)
    const [toVehicle, setToVehicle] = useState(null)
    const onVehicleSelection = useCallback((prm) => {
        if (!isNaN(prm)) setVehicleId(+prm);
        clearData();       
        
    }, [])


   

    const clearData = () => {
        setShowOdom(false);
        setReeferHrs(0);
        setFisReeferHrs(0);
        setOdom(0);
        setSamOdom(0);

        setOdomReason('');
        setHrsReason('');

        setEngineDrive('');
        setElectricStandby('');

        setOdomReasonError('');
        setOdomError('');
        setReeferError('');
        setReeferReasonError('');

    }

    const isNumber = RegExp(/^\d+$/)


    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }

   
    const onError = (errMsg) => {
        showAlert(errMsg, 'error');
    };

    const onReeferError = (errMsg) => {
       
        setShowOdom(true);
    };


    const onReeferData = (d) => {
        setReeferHrs(d.reefer_Eng_Hrs);
        setFisReeferHrs(d.reefer_Eng_Hrs);
        setShowOdom(true);

    }

    const onOdomData = (d) => {

        setOdom(d.odoM_MILES);
        setSamOdom(d.odoM_MILES);
        setShowOdom(true);
       
    }

    const onOdomError = () => {
        setShowOdom(true);
    }

    const onVehicleDetails = (d) => {

        if (Array.isArray(d)) {
            //ElectricStandby specid 108
            let esb = d.find(ele => ele.specID === 108);
            if (esb)
                setElectricStandby(esb.vehicleSpecValue);
            else
                setElectricStandby('');
             //EngineDrive specid 121
            let ed = d.find(ele => ele.specID === 121);
            if (ed)
                setEngineDrive(ed.vehicleSpecValue);
            else
                setEngineDrive('');

            console.log(ed);
        }       
       


    }
   
    const onValidationSuccess = () => {

        setIsLoadingOdom(true);
        setIsLoadingReefer(true);
        //get Odometer
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${toVehicle}/odom`, onOdomData, setIsLoadingOdom, onOdomError);
        

        //get Reefer hrs
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${toVehicle}/reeferhrs`, onReeferData, setIsLoadingReefer, onReeferError);
        

        //get vehicle details value of the from Vehicle
        if (!engineDrive || !electricStandby) {
            setIsLoadingDetails(true);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}/details`, onVehicleDetails, setIsLoadingDetails );
        }


    }


    const onSave = () => {
        setOdomReasonError('');
        setOdomError('');
        setReeferError('');
        setReeferReasonError('');

        if (!odom) {
            setOdomError("Please enter an odometer reading for the Vehicle");
            return;
        }

        if (!isNumber.test(odom)) {
            setOdomError("Please enter a valid odometer reading for the Vehicle");
            return;
        }

        let odomdiff = Math.abs(samOdom - odom)

        if (odomdiff > 0 && odomdiff < 5000) {
            setOdomError("No mileage adjustments of less than 5,000 miles allowed.")
            setOdom(samOdom);
            return;
        }

        if (samOdom !== odom && odomReason === "" && samOdom) {
            setOdomReasonError("Please enter Odom Override Reason");
            return;
        }

        if (samOdom === odom && odomReason !== "") {
            setOdomReasonError("Please clear the Odom Override Reason");            
            return;
        }


        let reefdiff = Math.abs(fisReeferHrs - reeferHrs)

        if (reefdiff > 0 && reefdiff < 250) {
            setReeferError("No Reefer Engine adjustments of less than 250 hours allowed.");
            setReeferHrs(fisReeferHrs);            
            return;
        }

        //engine drive reefer and g108
        if (fisReeferHrs !== reeferHrs && hrsReason === "" && samOdom && (electricStandby.toUpperCase() !== "YES" || engineDrive.toUpperCase() !== "YES")) {
            setReeferReasonError("Please enter Reefer Engine Hours Override Reason");
            return;
        }

        if (fisReeferHrs === reeferHrs && hrsReason !== "") {
            setReeferReasonError("Please clear Reefer Engine Hours Override Reason");            
            return;
        }

        //save data
        postApiData(`${ApiRoot}/${country}/${ApiPaths.DuplicateSpecs}`, 'POST',
            { vehicleFrom: vehicleId, vehicleTo: toVehicle, odometer: odom, odomReason, reeferOdometer: reeferHrs, reeferReason: hrsReason },
            onSaveComplete, onError);

    }

    //clear info
    const onSaveComplete = () => {
        setShowOdom(false);
        setReeferHrs(fisReeferHrs);
        setOdom(samOdom);
        setHrsReason('');
        setOdomReason('');

        setOdomReasonError('');
        setOdomError('');
        setReeferError('');
        setReeferReasonError('');

        setToVehicle(0);
        showAlert('Vehicle specs successfully duplicated', 'success');

    }
   
    const handleToVehicleChange = (e) => {
        const value = e.target.value;        
        setToVehicle((!isNaN(value) && +value > 0) ? +value : null);
        if (showOdom) {
            clearData();

        }
    }


    const handleOdomChange = (e) => {
        const value = e.target.value;
        setOdom((!isNaN(value)) ? +value : 0);
    }

    const handleReeferHrsChange = (e) => {
        const value = e.target.value;
        setReeferHrs((!isNaN(value)) ? +value : 0);
    }

    const validateVehicles = () => {
        setOdomReasonError('');
        setOdomError('');
        setReeferError('');
        setReeferReasonError('');
        
        //check if required field has value
        if (!vehicleId) {
            onError('Please enter a From Vehicle #');
            return;
        }
            
        if (!toVehicle) {
            onError('Please enter a To Vehicle #');
            return;
        }


        //check if from vehicle exists in accepted vehicles, if not is error
        setIsLoading(true);
        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleSpecs}/${vehicleId}/can-dup/${toVehicle}`, onValidationSuccess, setIsLoading, onError);
      
       
    }

    const onOdomReason = (d) => {

        if (Array.isArray(d))
            d.unshift({ specID: 189, specListValue: '', specListSortOrder: 0})

        setOdomReasons(d);
    }

    const onReeferReason = (d) => {
        if (Array.isArray(d))
            d.unshift({ specID: 191, specListValue: '', specListSortOrder: 0 })

        setHrsReasons(d);
    }

    //fetch data
    const fetchData = () => {
        setIsLoading(true);

        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/189/list`, onOdomReason, setIsLoading, onError);

        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.Specs}/191/list`, onReeferReason, setIsLoading, onError);

    }

    useEffect(() => {
        fetchData();
    }, []);

    const onReasonChange = e => {
        //handleChange(e);

        setOdomReason( e.target.value);       
    }

    const onHrsReasonChange = e => {
        //handleChange(e);

        setHrsReason(e.target.value);
    }

    return (
        <Container>
            <Typography variant="h5" style={{ margin: 3 }}>Duplicate Vehicle Specs</Typography>            
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={4}>
                    <Grid item xs={2} align="right">
                        <Typography variant="h6">From Vehicle:</Typography> 
                    </Grid>
                    <Grid item xs={3}>
                        <MemSingleVehicleSearch country={country} onChange={onVehicleSelection} label=""/>                       
                    </Grid>
                    <Grid item xs={2} align="right">
                        <Typography variant="h6">To Vehicle:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                         <TextField                            
                            hiddenLabel
                            variant="outlined"
                            size="small"
                            name="toVehicle"
                            fullWidth
                            value={toVehicle || ''}                            
                            onChange={handleToVehicleChange}
                        />
                    </Grid>
                    <Grid item>
                        <Button onClick={validateVehicles} color="primary" variant="contained" endIcon={<NextIcon />}>Go</Button>
                    </Grid>
                </Grid>
                </Paper>
            {(isLoading || isLoadingOdom || isLoadingDetails || isLoadingReefer) ?
                <LinearProgress />:
                    (showOdom &&
                    <Paper sx={{ p: 2, mt: 1 }}>

                        <Grid container spacing={1} mt={2}>
                            <Grid item xs={4}>
                                <Typography variant="h6">SAM Miles</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">Odometer</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">Odometer Override Reason</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    hiddenLabel
                                    variant="outlined"
                                    size="small"
                                    name="samOdom"
                                    fullWidth
                                    value={samOdom || ''}
                                    disabled

                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    hiddenLabel
                                    variant="outlined"
                                    size="small"
                                    name="odom"
                                    fullWidth
                                    value={odom || ''}
                                    helperText={odomError}
                                    onChange={handleOdomChange}
                                    error={!!odomError}

                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SelectControl
                                    id="specListValue"
                                    options={odomReasons}
                                    textValue="specListValue"
                                    value={odomReason || ''}
                                    name="odomReasons"
                                    handleChange={onReasonChange}
                                    helperText={odomReasonError}

                                />

                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">FIS Reefer Engine Hrs</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">Reefer Engine Hours</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">Reefer Eng Hrs Override</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    hiddenLabel
                                    variant="outlined"
                                    size="small"
                                    name="fisReeferHrs"
                                    fullWidth
                                    value={fisReeferHrs || ''}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    hiddenLabel
                                    variant="outlined"
                                    size="small"
                                    name="reeferHrs"
                                    fullWidth
                                    value={reeferHrs || ''}
                                    helperText={reeferError}
                                    error={!!reeferError}
                                    onChange={handleReeferHrsChange}

                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SelectControl
                                    id="specListValue"
                                    options={hrsReasons}
                                    textValue="specListValue"
                                    value={hrsReason || ''}
                                    name="hrsReasons"
                                    handleChange={onHrsReasonChange}
                                    helperText={reeferReasonError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button sx={{ mt: 1 }} onClick={onSave} color="primary" variant="contained" endIcon={<SaveIcon />}>Save</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    )
                }
           
               
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </Container> 
    );
 
}
