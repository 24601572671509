import { TextField } from '@mui/material';
import React from 'react';

const isText = RegExp(/^[A-Z ]+$/i);
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
const isPhone = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/);// us
const isZip = RegExp(/^[0-9]{5}([- /]?[0-9]{4})?$/);// us
const isNumber = RegExp(/^\d+$/);
const isWebSIte = RegExp(/(:?(?:https?:\/\/)?(?:www\.)?)?[-a-z0-9]+\.(?:com|gov|org|net|edu|biz)/g);

 function validate(value, schema) {
     const { validate, minLength, maxLength } = schema;
    let error = ""

    if (minLength && value.length < minLength) error = `Minimum of ${minLength} characters are required.`
    else if (maxLength && value.length > maxLength) error = `Maximum length of ${maxLength} exceeded!`
    if (!validate) return error

    switch (validate) {
        case "text":
            if (!isText.test(value)) error = "This field accepts text only."
            break;

        case "number":
            if (!isNumber.test(value)) error = "This field accepts numbers only."
            break;

        case "email":
            if (!isEmail.test(value)) error = "Please enter a valid email address."
            break;

        case "phone":
            if (!isPhone.test(value)) error = "Please enter a valid phone number. i.e: xxx-xxx-xxxx"
            break;

        case "zip":
            if (!isZip.test(value)) error = "Please enter a valid zip code."
            break;

        case "checkbox":
            if (!value) error = "Please select a value."
            break;

        case "website":
            if (!isWebSIte.test(value)) error = "Please enter a valid website URL"
            break;

        default:
            break;
    }

     return error;
}
//validations
//compareTo: other field to compare to
//maxLength
//minLength
//exp validate to a regular expression
//required if minLength > 0
//validateTo: text, website, zip, phone, email, number


export const TextFieldValidation = (props) => {   
    const [error, setError] = React.useState('');

      const handleChange = e => {

          const name = e.target.name;
          const value = e.target.type === "checkbox" ? e.target.checked : e.target.value

          // Set values        
          props.setData?.({
              ...props.data,
              [name]: value
          });

          //if (name in fieldsValidation) {            // Set errors
          let err = validate(value, { validate: props.validateTo, minLength: props.minLength, maxLength: props.maxLength }) || ""

          if (err === '' && props.compareTo) {
              //check compare
              if (value !== props.data?.[props.compareTo]) {
                  err = name + " and " + props.compareTo + " fields should have the same value.";
              }
          }


          if (err !== error) {
              setError(err);
              props.setIsValidForm?.({ ...props?.isValidForm, [name]: (err === '') });
          }

    }


    return (
        <div>
            <TextField
                {...props} onChange={handleChange}
                value={props.data?.[props.name] || ''}                
                error={!!error}
                required={props.minLength && props.minLength > 0}
                helperText={error}
            />
        </div>
    );
};

