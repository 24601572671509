import download from "downloadjs";

export const bearerToken = {
    token: 'Bearer ',
};

const getEnvKey =(apiPath) => {
    if (apiPath.startsWith('https://apidev.'))
        return '764acbb677c44f8cb29e0b173d997541';
    if (apiPath.startsWith('https://apiqa.'))
        return '34c541467c284dbc8fc3c4fe3378841f';
    if (apiPath.startsWith('https://api.'))
        return '27b18df440644a778ec376c13241bc08';

    return '764acbb677c44f8cb29e0b173d997541';

}

/**
 * Calls an API using the GET method
 * @param {string} apiPath The path to the API
 * @param {any} setDataFunc The function ref that will be called with the returned data as a parameter 
 * @param {any} setLoadingFunc The function ref that will be called once the execution finishes with a parameter value of true
 * @param {any} setErrorFunc The function ref that will be called if there is an error, response status is passed as parameter or 0 if exception.
*/
export async function fetchApiData(apiPath, setDataFunc, setLoadingFunc, setErrorFunc) {

   
    try {

        const response = await fetch(apiPath, {
            cache: "no-store",                    
            headers: {
                'Authorization': 'Bearer ' + bearerToken.token,
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': getEnvKey(apiPath),
                Credentials: true
            }
        });
         //check if successfull
        if (response.ok) {

            //if redirected then go to login page
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                window.location.href = '/';
            }
            else {
                try {
                    const data = await response.json();

                    //console.log(data);

                    if (setDataFunc)
                        setDataFunc(data);

                    if (setLoadingFunc)
                        setLoadingFunc(false);
                }
                catch (ex) {
                    console.log("json error", ex);                   

                    if (setLoadingFunc)
                        setLoadingFunc(false)
                    if (setErrorFunc)
                        setErrorFunc(ex);

                }
            }
            
        }
        else {

            console.log("Response not OK", response);

            if (setLoadingFunc)
                setLoadingFunc(false);
            if (setErrorFunc) {
                if (response.status === 403 || response.status === 401) {
                    setErrorFunc("Unauthorize. You don't have access to this resource.");
                }
                else {
                    try {
                        const text = await response.text();
                        //if valid json send
                        setErrorFunc(text);

                    } catch (err) {
                        setErrorFunc("Error saving data");
                    }
                }
                
            }


        }

    }
    catch (ex) {
        console.log(ex);

        if (setLoadingFunc)
            setLoadingFunc(false)
        if (setErrorFunc)
            setErrorFunc('Fetching data error');

    }


};


/**
 * Calls an API passing the method POST, PUT or DELETE for GET call fetchApiData instead
 * @param {string} apiPath The path to the API
 * @param {string} method Method value POST, PUT or DELETE
 * @param {any} data The json object that will be send to the server, if none then pass null
 * @param {any} onSuccess The function ref that will be called ones the execution finishes if response contains json type it will pass the data as parameter
 * @param {any} onError The function ref that will be called if there is an error, error message is passed as parameter.
 */
export async function postApiData(apiPath, method, data, onSuccess, onError) {

    try {

        let postMethod = 'POST';
        if (method === 'DELETE' || method === 'PUT') {
            method = '/' + method;
        }
        else {
            method = '';
        }

        const requestOptions = {
            method: postMethod,
            headers: {
                'Authorization': 'Bearer ' + bearerToken.token,
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': getEnvKey(apiPath)
            }
        };

        if (data !== null)
            requestOptions.body = JSON.stringify(data);

        const response = await fetch(apiPath + method, requestOptions);

        //check if successfull
        if (response.ok) {

            if (onSuccess) {
                try {
                    const text = await response.text();
                    const resdata = JSON.parse(text);
                    //if valid json send
                    onSuccess(resdata);

                } catch (err) {
                    onSuccess();
                }
            }

        }
        else {
            
            if (onError) {
                try {
                    const text = await response.text();
                     //if valid json send
                    onError(text);

                } catch (err) {
                    onError("Error saving data");
                }
            }

        }

    }
    catch (ex) {
        console.log(ex);        

        if (onError)
            onError("Error saving data");
    }


};

/**
 * Calls an API using the GET method to get a pdf file
 * @param {string} apiPath The path to the API
 * @param {any} setLoadingFunc The function ref that will be called ones the execution finishes with a parameter value of true
 * @param {any} setErrorFunc The function ref that will be called if there is an error, response status is passed as parameter or 0 if exception.
*/
export async function fetchApiPdf(apiPath, setLoadingFunc, setErrorFunc) {
    try {
        const response = await fetch(apiPath, {
            cache: "no-store",                    
            headers: {
                'Authorization': 'Bearer ' + bearerToken.token,
                'Content-Type': 'application/pdf',
                'Ocp-Apim-Subscription-Key': getEnvKey(apiPath),
                Credentials: true
            }
        });
        
        if (response.ok) {
            try {
                const _blobData = await response.blob();
                download(_blobData)
                if (setLoadingFunc)
                    setLoadingFunc(false);
            }
            catch (ex) {
                console.log("blob to pdf ERROR", ex);                   
                if (setLoadingFunc)
                    setLoadingFunc(false)
                if (setErrorFunc)
                    setErrorFunc(ex);
            }
        } else {
            console.log("Response not OK", response);
            if (setLoadingFunc)
                setLoadingFunc(false);
            if (setErrorFunc) 
                setErrorFunc((response.status === 403 || response.status === 401)
                ?   "Unauthorize. You don't have access to this resource."
                :   "Error getting pdf");
        } 
    }
    catch (ex) {
        console.log(ex);
        if (setLoadingFunc)
            setLoadingFunc(false)
        if (setErrorFunc)
            setErrorFunc('Fetching data error');
    }
};